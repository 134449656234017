import BigNumber from "bignumber.js";
import { makeAutoObservable, runInAction } from "mobx";
var TransferRecipientFormStore = /** @class */ (function () {
    function TransferRecipientFormStore(balances) {
        this.balances = balances;
        this.loading = false;
        this.from = undefined;
        this.to = undefined;
        this.amount = undefined;
        this.message = undefined;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    TransferRecipientFormStore.prototype.submit = function () {
        var _this = this;
        runInAction(function () {
            _this.loading = true;
        });
        try {
            // TODO: Impl
        }
        catch (e) {
            console.error(e);
        }
        runInAction(function () {
            _this.loading = false;
        });
    };
    Object.defineProperty(TransferRecipientFormStore.prototype, "balance", {
        get: function () {
            return this.from ? this.balances.data[this.from] : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferRecipientFormStore.prototype, "enough", {
        get: function () {
            return this.amount && this.balance
                ? new BigNumber(this.amount).lte(this.balance.balance)
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    return TransferRecipientFormStore;
}());
export { TransferRecipientFormStore };
