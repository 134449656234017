import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { IdDocSubType, IdDocType } from "@/api/registration/types";
import { CompanyDocument } from "@/pages/KYBPage/components/Document/CompanyDocument";
import { IndividualDocs } from "@/pages/KYBPage/components/Form6/IndividualDocs";
import { FormLayout } from "@/pages/KYBPage/components/FormLayout";
import { IndividualListStore } from "@/pages/KYBPage/stores/IndividualListStore";
import { ROUTES } from "@/routes";
import { Box } from "@/shared/components";
import { Loader } from "@/shared/components/Loader";
import { Typography } from "@/shared/components/Typography";
import { useStoreContext } from "@/shared/hooks/useStore";
export var Form6 = observer(function () {
    var navigate = useNavigate();
    var individualList = useStoreContext(IndividualListStore);
    var submit = function () {
        navigate(generatePath(ROUTES.kybForm));
    };
    return (React.createElement(FormLayout, { disabled: false, loading: !individualList.ready || individualList.loading, onSubmit: submit, onBack: function () { return navigate(generatePath(ROUTES.kybFormId, { id: 5 })); }, index: 6, title: "Documents upload", desc: React.createElement(React.Fragment, null,
            "Please, make sure that you upload relevant company documents. Allowed formats: pdf, doc, docx, jpeg, png and tiff.",
            " ",
            React.createElement(Typography, { type: "text16", color: "black-1200" }, "The total weight of files uploaded must not exceed 20 MB.")) },
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px" },
            React.createElement(Typography, { type: "text20", fontWeight: 500 }, "Company documents"),
            React.createElement(CompanyDocument, { label: "Articles of incorporation", type: IdDocType.COMPANY_DOC, subType: IdDocSubType.INCORPORATION_ARTICLES }),
            React.createElement(CompanyDocument, { label: "Incorporation documents", type: IdDocType.COMPANY_DOC, subType: IdDocSubType.INCORPORATION_CERT }),
            React.createElement(CompanyDocument, { label: "Beneficial ownership certificate", type: IdDocType.COMPANY_DOC, subType: IdDocSubType.OTHER }),
            React.createElement(CompanyDocument, { label: "Account Agreement", type: IdDocType.AGREEMENT }),
            React.createElement(CompanyDocument, { label: "Layer2 Account Agreement", type: IdDocType.AGREEMENT, subType: IdDocSubType.OTHER }),
            React.createElement(CompanyDocument, { label: "Proof of Address", type: IdDocType.COMPANY_DOC, subType: IdDocSubType.PROOF_OF_ADDRESS })),
        individualList.ready
            ? (individualList.list.map(function (item) { return React.createElement(IndividualDocs, { data: item, key: item.id }); }))
            : (React.createElement(Box, { bgColor: "black-50", height: "80px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "22px" },
                React.createElement(Loader, { size: 24, containerSize: 24 })))));
});
