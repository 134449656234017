import { styled } from "@mui/material/styles";
import { Box } from "@/shared/components";
export var Card = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        transition: "all 0.3s ease",
        "&:hover": {
            backgroundColor: theme.colors["black-50"],
        },
    });
});
