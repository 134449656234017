import { ProfileType, ROUTES } from "@/routes";
import { generateQueryPath } from "@/shared/utils/path";
export var HelperLinks = [
    {
        label: "About us",
        link: ROUTES.about,
    },
    {
        label: "Terms & Conditions",
        link: ROUTES.terms,
    },
    {
        label: "Privacy Policy",
        link: ROUTES.privacy,
    },
];
export var ProfileLinks = [
    {
        label: "Profile Settings",
        link: ROUTES.profile,
    },
];
export var HeaderLinks = [
    {
        label: "Wallet",
        link: ROUTES.root,
        active: "",
        icon: "Wallet",
    },
    {
        label: "Trade",
        link: generateQueryPath(ROUTES.exchange, {}, {}),
        active: "exchange",
        icon: "Trade",
    },
    {
        label: "Transactions",
        link: ROUTES.transactions,
        active: "transactions",
        icon: "Transactions",
    },
    {
        label: "Account",
        link: generateQueryPath(ROUTES.profile, {}, {
            type: ProfileType.Settings,
        }),
        active: "profile",
        icon: "Account",
    },
];
export var HeaderMobileLinks = [
    {
        label: "Wallet",
        link: ROUTES.root,
        active: "wallet",
        icon: "Wallet",
    },
    {
        label: "Trade",
        link: generateQueryPath(ROUTES.exchange, {}, {}),
        active: "exchange",
        icon: "Trade",
    },
    {
        label: "Transactions",
        link: ROUTES.transactions,
        active: "transactions",
        icon: "Transactions",
    },
    {
        label: "Settings",
        link: generateQueryPath(ROUTES.profile, {}, {
            type: ProfileType.Settings,
        }),
        active: "settings",
        icon: "Settings",
    },
    {
        label: "Verification",
        link: generateQueryPath(ROUTES.profile, {}, {
            type: ProfileType.Verification,
        }),
        active: "verification",
        icon: "Verification",
    },
];
export var SettingsMenu = [
    {
        label: "Settings",
        to: ProfileType.Settings,
        icon: "Settings",
    },
    {
        label: "Verification",
        to: ProfileType.Verification,
        icon: "Verification",
    },
    // {
    //     label: "Addresses",
    //     to: ProfileType.Addresses,
    //     icon: "Banks",
    // },
];
export var MenuLinks = [
    {
        label: "Dashboard",
        link: ROUTES.root,
        icon: "MenuDashboard",
    },
    {
        label: "Company info",
        link: ROUTES.company,
        icon: "MenuCompany",
    },
    {
        label: "Bank accounts",
        link: ROUTES.bankAccountsList,
        icon: "Bank",
    },
    {
        label: "Crypto wallets",
        link: ROUTES.walletList,
        icon: "Crypto",
    },
    {
        label: "Recipients",
        link: ROUTES.recipients,
        icon: "Recipient",
    },
    {
        label: "Transactions history",
        link: ROUTES.transactions,
        icon: "MenuTransactions",
    },
];
