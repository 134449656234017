import { observer } from "mobx-react-lite";
import * as React from "react";
import { Box, Typography } from "@/shared/components";
import { Button } from "@/shared/components/Button";
import { Popup } from "@/shared/components/Popup";
import { CloseBtn } from "@/shared/components/Popup/CloseBtn";
export var ExitPopup = observer(function (_a) {
    var onClose = _a.onClose, onBack = _a.onBack;
    return (React.createElement(Popup, { width: "450px", onClose: onClose },
        React.createElement(Box, { padding: "70px 50px 50px" },
            React.createElement(CloseBtn, { position: "absolute", top: "25px", right: "25px", onClick: onClose }),
            React.createElement(Typography, { type: "text28", fontWeight: 500, marginBottom: "10px", variant: "h3", textAlign: "center" }, "Already leaving?"),
            React.createElement(Typography, { type: "text14", color: "black-500", variant: "body1", marginBottom: "30px", textAlign: "center" }, "All data provided will be saved. You will be able to continue at another time."),
            React.createElement(Button, { onClick: onBack, width: "100%" }, "Finish verification"))));
});
