var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { styled } from "@mui/material/styles";
export var ContainerIcon = styled("span", {
    shouldForwardProp: function (prop) {
        return !prop.startsWith("$") &&
            !prop.startsWith("border");
    },
})(function (_a) {
    var theme = _a.theme, $bgColor = _a.$bgColor, borderColor = _a.borderColor, $borderRadius = _a.$borderRadius, $rotate = _a.$rotate, $color = _a.$color, $opacity = _a.$opacity, size = _a.size, height = _a.height, width = _a.width, $hoverColor = _a.$hoverColor, $hoverOpacity = _a.$hoverOpacity, $hoverBgColor = _a.$hoverBgColor;
    return ({
        position: "relative",
        width: "".concat(width || size, "px"),
        height: "".concat(height || size, "px"),
        background: $bgColor ? theme.colors[$bgColor] : "transparent",
        border: borderColor ? "1px solid ".concat(theme.colors[borderColor]) : "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: $borderRadius,
        opacity: $opacity,
        transform: $rotate ? "rotate(".concat($rotate, "deg)") : undefined,
        padding: "0px",
        transition: "opacity 0.2s ease, transform 0.2s ease, background 0.2s ease",
        color: $color ? theme.colors[$color] : undefined,
        boxSizing: "border-box",
        "& svg": {
            currentColor: $color ? theme.colors[$color] : undefined,
            fill: $color ? theme.colors[$color] : undefined,
        },
        "&[role=button]:not(.Icon-disabled)": __assign(__assign({ cursor: "pointer" }, (($hoverColor || $hoverOpacity) && {
            "&:hover": {
                color: $hoverColor
                    ? theme.colors[$hoverColor]
                    : $color
                        ? theme.colors[$color]
                        : undefined,
                opacity: $hoverOpacity || $opacity,
            },
        })), ($hoverBgColor && {
            "&:hover": {
                background: theme.colors[$hoverBgColor],
            },
        })),
        "&.Icon-disabled": {
            cursor: "not-allowed",
        },
    });
});
