var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { QRCodeSVG } from "qrcode.react";
import { Box } from "@/shared/components/Box";
import { Popup } from "@/shared/components/Popup";
import { Typography } from "@/shared/components/Typography";
import { Button } from "@/shared/components";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { useCopy } from "@/shared/hooks/useCopy";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { walletApi } from "@/api/wallet/api";
import { AccountListStore } from "@/shared/stores/AccountListStore";
export var DepositPopup = observer(function (_a) {
    var onClose = _a.onClose, onError = _a.onError, selectedToken = _a.selectedToken;
    var form = useForm();
    var currencyList = useStoreContext(CurrencyListStore);
    var accounts = useStoreContext(AccountListStore);
    var watchForm = form.watch();
    var _b = React.useState(""), depositAdd = _b[0], setDepositAdd = _b[1];
    var _c = React.useState(false), isLoading = _c[0], setLoading = _c[1];
    var _d = React.useState(""), depositToken = _d[0], setTokenDeposit = _d[1];
    var copy = useCopy(depositAdd !== null && depositAdd !== void 0 ? depositAdd : "");
    var loadBlockchain = React.useCallback(function (bcName) { return __awaiter(void 0, void 0, void 0, function () {
        var tokenData, bcData, resp, e_1, respData;
        var _a, _b, _c, _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    tokenData = currencyList.list.find(function (_) { return _.slug === selectedToken; });
                    bcData = (_a = tokenData === null || tokenData === void 0 ? void 0 : tokenData.tokens) === null || _a === void 0 ? void 0 : _a.find(function (_) {
                        var _a, _b;
                        return ((_b = (_a = _.blockchain) === null || _a === void 0 ? void 0 : _a.slug) === null || _b === void 0 ? void 0 : _b.toLowerCase()) ===
                            bcName.toLowerCase();
                    });
                    if (((_b = accounts.crypto) === null || _b === void 0 ? void 0 : _b.length) === 0) {
                        onError("Crypto account not created for user yet");
                        return [2 /*return*/];
                    }
                    if (!tokenData || !bcData) {
                        onError("No such token");
                        return [2 /*return*/];
                    }
                    resp = null;
                    _h.label = 1;
                case 1:
                    _h.trys.push([1, 3, , 4]);
                    setLoading(true);
                    return [4 /*yield*/, walletApi.v2.getAddress({
                            accountId: accounts.crypto[0].account,
                            blockchain: (_c = bcData.blockchain) === null || _c === void 0 ? void 0 : _c.slug,
                            currency: tokenData.shortName,
                            addressIndex: 0,
                        })];
                case 2:
                    resp = _h.sent();
                    setTokenDeposit((_d = tokenData === null || tokenData === void 0 ? void 0 : tokenData.shortName) !== null && _d !== void 0 ? _d : "");
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _h.sent();
                    setLoading(false);
                    onError((_g = (_f = (_e = e_1 === null || e_1 === void 0 ? void 0 : e_1.response) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.message) !== null && _g !== void 0 ? _g : "Error: Address loading");
                    return [3 /*break*/, 4];
                case 4:
                    respData = resp === null || resp === void 0 ? void 0 : resp.data;
                    if (!respData)
                        return [2 /*return*/];
                    setDepositAdd(respData.address);
                    return [2 /*return*/];
            }
        });
    }); }, [currencyList.list, accounts.crypto, selectedToken, onError]);
    var ChainOptions = React.useMemo(function () {
        console.log("Selected token ".concat(selectedToken));
        var tokenData = currencyList.list.find(function (_) { return _.slug === selectedToken; });
        console.log("tokenData ".concat(tokenData === null || tokenData === void 0 ? void 0 : tokenData.slug));
        if (!tokenData)
            return [];
        if (!(tokenData === null || tokenData === void 0 ? void 0 : tokenData.tokens))
            return [];
        var list = tokenData.tokens
            .filter(function (_) {
            var _a;
            if (!_.id || !_.name)
                return false;
            if (!((_a = _.settings) === null || _a === void 0 ? void 0 : _a.depositEnabled))
                return false;
            return true;
        })
            .map(function (_) {
            var _a, _b, _c, _d;
            return ({
                key: ((_a = _.blockchain) === null || _a === void 0 ? void 0 : _a.slug)
                    ? (_b = _.blockchain) === null || _b === void 0 ? void 0 : _b.slug.toString().toUpperCase()
                    : "",
                label: (_d = (_c = _.blockchain) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : "",
            });
        });
        return list;
    }, [currencyList, selectedToken]);
    React.useEffect(function () {
        if (!watchForm.blockchain)
            return;
        loadBlockchain(watchForm.blockchain);
    }, [
        watchForm.blockchain,
        loadBlockchain,
        selectedToken,
        currencyList.list,
    ]);
    var DepositCurrencies = React.useMemo(function () {
        var _a, _b, _c, _d;
        var tokenData = currencyList.list.find(function (_) { return _.slug === selectedToken; });
        if (!tokenData)
            return [];
        return [
            {
                key: (_b = (_a = tokenData === null || tokenData === void 0 ? void 0 : tokenData.id) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : "",
                label: (_c = tokenData === null || tokenData === void 0 ? void 0 : tokenData.shortName) !== null && _c !== void 0 ? _c : "",
                customIcon: (_d = tokenData === null || tokenData === void 0 ? void 0 : tokenData.slug) === null || _d === void 0 ? void 0 : _d.toUpperCase(),
            },
        ];
    }, [selectedToken, currencyList.list]);
    return (React.createElement(Popup, { onClose: onClose, width: "406px" },
        React.createElement(Box, { display: "flex", justifyContent: "center", marginBottom: "16px" },
            React.createElement(Typography, { type: "text20", fontWeight: 600 }, "Deposit")),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "16px" },
            selectedToken && (React.createElement(FormControlSelect, { value: DepositCurrencies[0].key, customIcon: selectedToken.toUpperCase(), options: DepositCurrencies, label: "Select asset" })),
            (ChainOptions === null || ChainOptions === void 0 ? void 0 : ChainOptions.length) > 0 && (React.createElement(Controller, { control: form.control, name: "blockchain", render: function (_a) {
                    var _b;
                    var field = _a.field;
                    return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, options: ChainOptions, label: "Blockchain", value: field.name, customIcon: (_b = field.value) === null || _b === void 0 ? void 0 : _b.toUpperCase(), errorPosition: "relative" }));
                } })),
            depositAdd && (React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "center", gap: "24px", bgColor: "neutral-1", padding: "16px", borderRadius: "8px" },
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: 2, maxWidth: 200 },
                    React.createElement(Typography, { type: "text12Semibold" },
                        "Deposit ",
                        depositToken,
                        " to this address"),
                    React.createElement(Typography, { wordBreak: "break-all", type: "text14" }, depositAdd),
                    React.createElement(Button, { width: 120, size: "s", onClick: copy }, "Copy")),
                React.createElement(QRCodeSVG, { value: depositAdd }))),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "8px" },
                React.createElement(Button, { loading: isLoading, size: "s", variant: "LightBlue", onClick: onClose }, "Back")))));
});
