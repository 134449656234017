import React from "react";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
export var Settings2FA = function (_a) {
    var onClick = _a.onClick;
    return (React.createElement(Box, { display: "flex", justifyContent: "start", alignItems: "start", gap: "8px", padding: "12px", bgColor: "neutral-1", borderRadius: "6px" },
        React.createElement(BaseIcon, { icon: "Info", padding: "0", color: "deep-blue-500", size: 20 }),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "4px", alignItems: "start" },
            React.createElement(Typography, { type: "text12", color: "text" }, "Google Authentication is disabled on your account"),
            React.createElement(Typography, { type: "text12", color: "text-secondary" },
                "Changing your password will put your account on a",
                " ",
                React.createElement("i", null, "5 day security/hold review.")),
            React.createElement(Button, { variant: "White", padding: "0", size: "xxs", onClick: onClick },
                React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "4px", px: "10px", py: "5px", bgColor: "deep-blue-500-transparent-10" },
                    React.createElement(Typography, { type: "text12", fontWeight: 600, color: "deep-blue-500" }, "Activate authentication"))))));
};
