import { observer } from "mobx-react-lite";
import * as React from "react";
import { Box, Typography } from "@/shared/components";
export var SectionAmount = observer(function (_a) {
    var amount = _a.amount, currency = _a.currency;
    return (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "5px" },
        React.createElement(Typography, { type: "text14", color: "black-500" }, "Total + Fees"),
        React.createElement(Typography, { type: "text32Bold", fontWeight: 500, color: "general-white" },
            amount,
            " ",
            React.createElement(Typography, { fontWeight: 500, type: "text32Bold", color: "black-500" }, currency))));
});
