import React, { useMemo } from "react";
import { Box, Typography } from "@/shared/components";
export var VerificationStatusComponent = function (_a) {
    var status = _a.status;
    var getColor = function (status) {
        switch (status) {
            case "PENDING":
                return {
                    text: "secondary-blue",
                    bg: "secondary-blue-light",
                };
            case "INIT":
                return {
                    text: "negative",
                    bg: "general-red-transparent-10",
                };
            default:
                return {
                    text: "general-green",
                    bg: "light-green",
                };
        }
    };
    var statusColors = getColor(status);
    var statusText = useMemo(function () {
        return status === "GREEN"
            ? "VERIFIED"
            : status === "PENDING"
                ? "UNDER APPROVING"
                : "UNVERIFIED";
    }, [status]);
    return (React.createElement(Box, { paddingY: "4px", paddingX: "10px", borderRadius: "27px", display: "flex", justifyContent: "center", alignContent: "center", bgColor: statusColors.bg },
        React.createElement(Typography, { type: "text12", fontWeight: "500", color: statusColors.text }, statusText)));
};
