import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath } from "react-router-dom";
import { ListItem } from "@/pages/KYBPage/components/FormList/Item";
import { KybFormStore } from "@/pages/KYBPage/stores/KybFormStore";
import { PaymentInfoPopup } from "@/pages/PaymentPage/components/PaymentInfoPopup";
import { PaymentParam, PaymentTarget, ROUTES } from "@/routes";
import { BaseIcon, Box } from "@/shared/components";
import { useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { generateQueryPath } from "@/shared/utils/path";
import { preventDefault } from "@/shared/utils/prevent";
export var FormList = observer(function () {
    var _a;
    var infoPopup = useToggler();
    var kybForm = useStoreContext(KybFormStore);
    return (React.createElement(React.Fragment, null,
        infoPopup.enabled && React.createElement(PaymentInfoPopup, { onClose: infoPopup.off }),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "15px", marginBottom: "100px" },
            React.createElement(ListItem, { loading: !kybForm.ready, success: kybForm.form1Success, name: "Step 1. Personal details", link: generatePath(ROUTES.kybFormId, {
                    id: 1,
                }) }),
            React.createElement(ListItem, { loading: !kybForm.ready, success: kybForm.form2Success, name: "Step 2. Company information", link: generatePath(ROUTES.kybFormId, {
                    id: 2,
                }) }),
            React.createElement(ListItem, { loading: !kybForm.ready, success: kybForm.form3Success, name: "Step 3. Company details", link: generatePath(ROUTES.kybFormId, {
                    id: 3,
                }) }),
            React.createElement(ListItem, { loading: !kybForm.ready, success: kybForm.form4Success, name: "Step 4. Investment information", link: generatePath(ROUTES.kybFormId, {
                    id: 4,
                }) }),
            React.createElement(ListItem, { loading: !kybForm.ready, success: kybForm.form5Success, name: "Step 5. KYC information", link: generatePath(ROUTES.kybFormId, {
                    id: 5,
                }) }),
            React.createElement(ListItem, { loading: !kybForm.ready, success: kybForm.form6Success, name: "Step 6. Documents upload", link: generatePath(ROUTES.kybFormId, {
                    id: 6,
                }) }),
            React.createElement(ListItem, { loading: !kybForm.ready, disabled: !kybForm.success || !kybForm.agree, link: generateQueryPath(ROUTES.payment, {}, (_a = {},
                    _a[PaymentParam.Target] = PaymentTarget.Kyb,
                    _a)), name: React.createElement(Box, { display: "flex", gap: "5px" },
                    "Payment",
                    React.createElement(BaseIcon, { icon: "Info", size: 14, containerSize: 24, color: "deep-blue-500", onClick: preventDefault(infoPopup.on) })) }))));
});
