import React from "react";
import { CompanyInformation } from "./components/CompanyInformation";
import { useGetVerificationInfo } from "@/api/registration";
import { VerificationStatus } from "@/api/registration/types";
import { CompanyVerificationBanner } from "@/entities/Verification/components/CompayInfoBanner";
import { Box, Typography } from "@/shared/components";
export var CompanyInfoPage = function () {
    var data = useGetVerificationInfo().data;
    if ((data === null || data === void 0 ? void 0 : data.status) === VerificationStatus.INIT) {
        return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px", mt: "40px", marginBottom: "40px" },
            React.createElement(Typography, { type: "text32Bold" }, "Company information"),
            React.createElement(CompanyVerificationBanner, null)));
    }
    return React.createElement(CompanyInformation, null);
};
