import * as React from "react";
import { generatePath, Link } from "react-router-dom";
import { StyledImg } from "./styled";
import { useGetVerificationInfo } from "@/api/registration";
import { VerificationStatus } from "@/api/registration/types";
import { ROUTES } from "@/routes";
import { Box, Typography } from "@/shared/components";
import { Button } from "@/shared/components/Button";
import { useMedia } from "@/shared/hooks/useMedia";
import verification from "@/shared/img/verification.png";
export var CompanyVerificationBanner = function () {
    var data = useGetVerificationInfo().data;
    var status = data === null || data === void 0 ? void 0 : data.status;
    var media = useMedia();
    var title = "";
    var description = "";
    var button = "";
    var buttonLink = "";
    if (status === VerificationStatus.INIT) {
        button = "Verify";
        buttonLink = generatePath(ROUTES.subscription);
        title = "Pass verification and get full access";
        description = "Gain full access to Vault Connect services after completing company and identity verification";
    }
    if (status === VerificationStatus.PENDING) {
        title = "Verification is under review";
        description = "We’ve successfully received your request. Our team will contact you";
    }
    if (status === VerificationStatus.REJECT || status === VerificationStatus.RETRY) {
        button = "Resubmit";
        title = "Action needed";
        description = "Some of the documents don't meet our requirements";
        buttonLink = generatePath(ROUTES.kyb);
    }
    return (React.createElement(Box, { display: "flex", overflow: "hidden", padding: media.phone ? "30px" : "0 80px", alignItems: "center", bgColor: "black-1200", borderRadius: "22px", justifyContent: "space-between", flexDirection: media.phone ? "column" : "row", gap: media.phone ? "30px" : "0" },
        React.createElement(Box, { maxWidth: "440px" },
            React.createElement(Typography, { type: "text28", variant: "h2", color: "general-white", marginBottom: "10px", fontWeight: 700 }, title),
            React.createElement(Typography, { type: "text16", variant: "body1", color: "black-500" }, description),
            (button && buttonLink)
                ? (React.createElement(Button, { variant: "White", mt: media.phone ? "30px" : "40px", width: media.phone ? "100%" : "150px", LinkComponent: Link, to: generatePath(ROUTES.kyb) }, buttonLink))
                : null),
        React.createElement(StyledImg, { src: verification, width: 400, height: 400 })));
};
