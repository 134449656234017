var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { formattedTokenAmount } from "@broxus/js-utils";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { Amount } from "../Amount";
import { DepositType, ROUTES } from "@/routes";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { Button } from "@/shared/components/Button";
import { Loader } from "@/shared/components/Loader";
import { PopupItem } from "@/shared/components/Popup/Item";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { Typography } from "@/shared/components/Typography";
import { sliceAddress } from "@/shared/utils/slice-address";
export var TokenItem = function (_a) {
    var id = _a.id, selected = _a.selected, amount = _a.amount, currency = _a.currency, symbol = _a.symbol, depositBtnVisible = _a.depositBtnVisible, address = _a.address, onSelect = _a.onSelect;
    var navigate = useNavigate();
    return (React.createElement(PopupItem, { onClick: onSelect },
        React.createElement(TokenIcon, { size: 36, symbol: symbol === null || symbol === void 0 ? void 0 : symbol.toLowerCase() }),
        React.createElement(Box, { display: "flex", flexDirection: "column", flexGrow: "1" },
            React.createElement(Title, { type: "text14" },
                amount ? ("".concat(formattedTokenAmount(amount), " ").concat(currency === null || currency === void 0 ? void 0 : currency.toUpperCase())) : (React.createElement(Loader, { size: 16 })),
                selected && React.createElement(BaseIcon, { size: 16, icon: "Success" })),
            React.createElement(Typography, { type: "text14", color: "black-400" }, amount ? (React.createElement(Amount, { from: symbol, amount: amount })) : ("\u200B")),
            address && (React.createElement(Typography, { type: "text14", color: "black-400" }, sliceAddress(address)))),
        depositBtnVisible && (React.createElement(Button, { size: "s", variant: "Bordered", onClick: function (e) {
                e.preventDefault();
                e.stopPropagation();
                navigate(generatePath(ROUTES.deposit, {
                    type: DepositType.Crypto,
                    id: id,
                }));
            } }, "Add funds"))));
};
var Title = styled(Typography)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    gap: 5px;\n"], ["\n    display: flex;\n    align-items: center;\n    gap: 5px;\n"])));
var templateObject_1;
