// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iw901XOd7SmK9hx{display:flex;align-items:center;gap:8px;font-size:12px}`, "",{"version":3,"sources":["webpack://./src/pages/SignupPage/components/SignupForm/index.module.scss"],"names":[],"mappings":"AAAA,iBACI,YAAA,CACA,kBAAA,CACA,OAAA,CACA,cAAA","sourcesContent":[".row {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `iw901XOd7SmK9hx`
};
export default ___CSS_LOADER_EXPORT___;
