import * as React from "react";
import { IdDocSubType, IdDocType } from "@/api/registration/types";
import { IndividualDocument } from "@/pages/KYBPage/components/Document/IndividualDocument";
import { IndividualVerificationStore } from "@/pages/KYBPage/stores/IndividualVerificationStore";
import { Box } from "@/shared/components";
import { Typography } from "@/shared/components/Typography";
import { IndividualTypes } from "@/shared/constants/dict";
import { useProvider, useStore } from "@/shared/hooks/useStore";
export var IndividualDocs = function (_a) {
    var _b, _c;
    var data = _a.data;
    var individualVerification = useStore(IndividualVerificationStore, data.id, (_b = data.verificationIds) === null || _b === void 0 ? void 0 : _b[0]);
    var IndividualVerificationProvider = useProvider(IndividualVerificationStore);
    return (React.createElement(IndividualVerificationProvider, { value: individualVerification },
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px" },
            React.createElement(Typography, { type: "text20", fontWeight: 500 },
                data.firstName,
                " ",
                data.lastName,
                " ",
                React.createElement(Typography, { color: "black-400", type: "text20" }, (_c = IndividualTypes.find(function (i) { return i.key === data.individualType; })) === null || _c === void 0 ? void 0 : _c.label)),
            React.createElement(IndividualDocument, { label: "Passport or ID Card", type: IdDocType.PASSPORT }),
            React.createElement(IndividualDocument, { label: "Selfie with Passport or ID Card", type: IdDocType.SELFIE }),
            React.createElement(IndividualDocument, { label: "Proof od Address", type: IdDocType.OTHER, subType: IdDocSubType.PROOF_OF_ADDRESS }))));
};
