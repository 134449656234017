import { useQuery } from "@tanstack/react-query";
import { registrationApi } from "./api";
export var useGetVerificationInfo = function (enabled) {
    if (enabled === void 0) { enabled = true; }
    return useQuery({
        queryKey: ["registrationApi.verification.getVerificationStatus"],
        select: function (data) { return data.data; },
        queryFn: function () {
            return registrationApi.verification.getVerificationStatus();
        },
        retry: 0,
        enabled: enabled,
    });
};
