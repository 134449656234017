var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { styled } from "@mui/material/styles";
import { Dialog } from "../Dialog";
export var StyledDialog = styled(Dialog, {
    shouldForwardProp: function (prop) { return !prop.startsWith("$"); },
})(function (_a) {
    var _b;
    var $bgColor = _a.$bgColor, $disablePadding = _a.$disablePadding, $isMobileFull = _a.$isMobileFull, theme = _a.theme;
    return ({
        "& .MuiDialog-paper": (_b = {
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: $disablePadding ? "0" : "16px",
                boxSizing: "border-box",
                boxShadow: "none",
                borderRadius: "12px",
                background: theme.colors[$bgColor],
                width: "100%",
                maxWidth: "450px"
            },
            _b[theme.breakpoints.down("md")] = __assign({}, ($isMobileFull && {
                borderRadius: "0",
                margin: "0",
                width: "100vw",
                maxHeight: "100vh",
                height: "100vh",
                maxWidth: "100vw",
                padding: "50px 20px",
            })),
            _b),
    });
});
