var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import BN from "bignumber.js";
import { DepositPopup } from "./components/DepositPopup";
import { WithdrawPopup } from "./components/WithdrawPopup";
import { ErrorPopup } from "./components/ErrorPopup";
import { SuccessPopup } from "./components/SuccessPopup";
import { CreateWalletPopup } from "@/features/create-wallet";
import { WalletItem } from "@/pages/WalletsPage/components/Item";
import { ProfileType, ROUTES } from "@/routes";
import { Box, Button, Typography } from "@/shared/components";
import { EmptyTable } from "@/shared/components/EmptyTable";
import { useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { WalletsTable } from "@/shared/components/WalletsTable";
import { useGetVerificationInfo } from "@/api/registration";
import { VerificationStatus } from "@/api/registration/types";
import { generateQueryPath } from "@/shared/utils/path";
import { AuthStore } from "@/shared/stores/AuthStore";
export var WalletsPage = observer(function () {
    var navigate = useNavigate();
    var popup = useToggler();
    var popupDeposit = useToggler();
    var popupWithdraw = useToggler();
    var errorPopup = useToggler();
    var successPopup = useToggler();
    var data = useGetVerificationInfo().data;
    var accounts = useStoreContext(AccountListStore);
    var balanceList = useStoreContext(BalanceListStore);
    var addressList = useStoreContext(AddressListStore);
    var currencyList = useStoreContext(CurrencyListStore);
    var authStore = useStoreContext(AuthStore);
    var _a = React.useState(""), popupSymbol = _a[0], setPopupSymbol = _a[1];
    var _b = React.useState(""), errorText = _b[0], setErrorText = _b[1];
    var _c = React.useState(undefined), cryptoWithdraw = _c[0], setCryptoWith = _c[1];
    var status = data === null || data === void 0 ? void 0 : data.status;
    var balancesData = React.useMemo(function () {
        if (!authStore.userInfo)
            return [];
        return currencyList.list
            .filter(function (_) { var _a; return !!((_a = _.settings) === null || _a === void 0 ? void 0 : _a.walletEnabled); })
            .map(function (currency) {
            var itemsList = Object.values(balanceList.dataAll).map(function (balanceData) {
                var balFound = balanceData === null || balanceData === void 0 ? void 0 : balanceData.find(function (_) { var _a; return _.currency === ((_a = currency.slug) === null || _a === void 0 ? void 0 : _a.toUpperCase()); });
                if (balFound) {
                    return {
                        balance: balFound.balance,
                        account: balFound.account,
                    };
                }
                return false;
            });
            var filteredItems = itemsList.filter(Boolean);
            var resultBalance = filteredItems.reduce(function (acc, curr) {
                if (!curr)
                    return acc;
                return {
                    balance: new BN(curr.balance)
                        .plus(acc.balance)
                        .toFixed(2),
                    account: curr.account,
                };
            }, {
                balance: "0",
                account: "",
            });
            if (currency.slug === "n901") {
                return __assign(__assign(__assign({}, currency), resultBalance), {
                    shortName: "Spectrum"
                });
            }
            return __assign(__assign({}, currency), resultBalance);
        })
            .sort(function (a, b) {
            var _a, _b;
            var totalA = new BN(a.balance).times((_a = a.eurPrice) !== null && _a !== void 0 ? _a : "0");
            var totalB = new BN(b.balance).times((_b = b.eurPrice) !== null && _b !== void 0 ? _b : "0");
            return totalA.gt(totalB) ? -1 : 1;
        });
    }, [balanceList.dataAll, currencyList.list, authStore.userInfo]);
    var totalBalance = React.useMemo(function () {
        var totalEur = Object.values(balanceList.dataAll)
            .reduce(function (accOuter, currOuter) {
            var totalBal = currOuter === null || currOuter === void 0 ? void 0 : currOuter.reduce(function (acc, curr) {
                var _a, _b, _c;
                var tokenData = currencyList.list.find(function (_) { var _a; return ((_a = _.slug) === null || _a === void 0 ? void 0 : _a.toUpperCase()) === (curr === null || curr === void 0 ? void 0 : curr.currency); });
                if (!((_a = tokenData === null || tokenData === void 0 ? void 0 : tokenData.settings) === null || _a === void 0 ? void 0 : _a.enabled))
                    return acc;
                if (!tokenData || (curr && new BN(curr === null || curr === void 0 ? void 0 : curr.balance).eq(0))) {
                    return acc;
                }
                return acc.plus(new BN((_b = curr === null || curr === void 0 ? void 0 : curr.balance) !== null && _b !== void 0 ? _b : 0).times((_c = tokenData.eurPrice) !== null && _c !== void 0 ? _c : 0));
            }, new BN(0));
            return accOuter.plus(totalBal !== null && totalBal !== void 0 ? totalBal : "0");
        }, new BN(0))
            .toFixed(2);
        return totalEur;
    }, [balanceList.dataAll, currencyList.list]);
    React.useEffect(function () {
        balanceList.sync.apply(balanceList, accounts.crypto.map(function (acc) { return acc.account; }));
    }, [balanceList, accounts.crypto, currencyList]);
    React.useEffect(function () {
        addressList.sync.apply(addressList, accounts.crypto);
    }, [accounts.crypto, addressList]);
    React.useEffect(function () {
        if (!authStore.userInfo)
            return;
        currencyList.syncAll();
    }, [authStore.userInfo, currencyList]);
    var openDeposit = function (val, symbol) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            popupDeposit.toggle(val);
            setPopupSymbol(symbol);
            return [2 /*return*/];
        });
    }); };
    var openWithdraw = function (val, symbol) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            popupWithdraw.toggle(val);
            setPopupSymbol(symbol);
            return [2 /*return*/];
        });
    }); };
    var showErr = function (val) {
        setErrorText(val);
        popupDeposit.toggle(false);
        popupWithdraw.toggle(false);
        errorPopup.toggle(true);
    };
    var withSuccess = function (data) {
        popupWithdraw.toggle(false);
        successPopup.toggle(true);
        setCryptoWith(data);
    };
    var closeSuccess = function () {
        successPopup.toggle(false);
        setCryptoWith(undefined);
    };
    return (React.createElement(React.Fragment, null,
        popupDeposit.enabled && (React.createElement(DepositPopup, { selectedToken: popupSymbol, onClose: popupDeposit.off, onError: showErr })),
        popupWithdraw.enabled && (React.createElement(WithdrawPopup, { selectedToken: popupSymbol, onClose: popupWithdraw.off, onError: showErr, onSuccess: withSuccess })),
        popup.enabled && (React.createElement(CreateWalletPopup, { onClose: popup.off, onSuccess: function (id) {
                navigate(generatePath(ROUTES.walletItem, { id: id }));
                accounts.sync();
            } })),
        errorPopup.enabled && (React.createElement(ErrorPopup, { errorText: errorText, onClose: errorPopup.off })),
        successPopup.enabled && (React.createElement(SuccessPopup, { withdrawData: cryptoWithdraw, onClose: closeSuccess })),
        status && status !== VerificationStatus.GREEN && (React.createElement(Banner, { px: "16px", py: "12px", borderRadius: "8px", bgColor: "warning-bg", maxWidth: 500, margin: "32px auto 0 auto" },
            React.createElement(Typography, { type: "text14Bold", color: "warning-text-primary" }, "Complete KYC to access features."),
            React.createElement(Button, { size: "xs", onClick: function () {
                    return navigate(generateQueryPath(ROUTES.profile, {}, {
                        type: ProfileType.Verification,
                    }));
                } }, "Complete KYC"))),
        React.createElement(Box, { display: "flex", flexDirection: "column", my: "32px", gap: "24px" },
            React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                React.createElement(Typography, { type: "title26Neo" }, "Crypto wallets")),
            balancesData.length ? (React.createElement(WalletsTable, { balanceTotal: totalBalance.toString(), loaded: true }, balancesData.map(function (account) {
                var _a, _b, _c, _d;
                return (React.createElement(WalletItem, { key: account.id, amount: account === null || account === void 0 ? void 0 : account.balance, id: (_b = (_a = account === null || account === void 0 ? void 0 : account.account) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : "", symbol: (_c = account.slug) !== null && _c !== void 0 ? _c : "", shortName: (_d = account.shortName) !== null && _d !== void 0 ? _d : "", depositOpen: openDeposit, withdrawOpen: openWithdraw }));
            }))) : (React.createElement(EmptyTable, { message: "You do not have any crypto wallets yet" })))));
});
var Banner = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    z-index: 3;\n    &::before {\n        position: absolute;\n        left: 8px;\n        right: 8px;\n        bottom: -6px;\n        z-index: 2;\n        content: \"\";\n        background-color: #f3f4b4;\n        border-radius: 8px;\n        height: 16px;\n        opacity: 0.5;\n    }\n"], ["\n    position: relative;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    z-index: 3;\n    &::before {\n        position: absolute;\n        left: 8px;\n        right: 8px;\n        bottom: -6px;\n        z-index: 2;\n        content: \"\";\n        background-color: #f3f4b4;\n        border-radius: 8px;\n        height: 16px;\n        opacity: 0.5;\n    }\n"])));
var templateObject_1;
