import BigNumber from "bignumber.js";
import { makeAutoObservable } from "mobx";
var ExchangeFormStore = /** @class */ (function () {
    function ExchangeFormStore(balances) {
        this.balances = balances;
        this.fiatId = undefined;
        this.cryptoId = undefined;
        this.baseAmount = undefined;
        this.quoteAmount = undefined;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    Object.defineProperty(ExchangeFormStore.prototype, "cryptoBalance", {
        get: function () {
            return this.cryptoId ? this.balances.data[this.cryptoId] : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeFormStore.prototype, "fiatBalance", {
        get: function () {
            return this.fiatId ? this.balances.data[this.fiatId] : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeFormStore.prototype, "cryptoEnough", {
        get: function () {
            return this.quoteAmount && this.cryptoBalance
                ? new BigNumber(this.quoteAmount).lte(this.cryptoBalance.balance)
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeFormStore.prototype, "fiatEnough", {
        get: function () {
            return this.baseAmount && this.fiatBalance
                ? new BigNumber(this.baseAmount).lte(this.fiatBalance.balance)
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    ExchangeFormStore.prototype.reset = function () {
        this.baseAmount = undefined;
        this.quoteAmount = undefined;
    };
    return ExchangeFormStore;
}());
export { ExchangeFormStore };
