var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import { compose, spacing } from "@mui/system";
import React, { memo } from "react";
import { FormAlert } from "../FormAlert";
import { Select } from "../Select/Select";
import { Typography } from "../Typography";
import { StyledContainer } from "./styled";
// @ts-ignore
export var ImplFormControlSelect = React.forwardRef(function (_a, ref) {
    var error = _a.error, _b = _a.errorPosition, errorPosition = _b === void 0 ? "relative" : _b, _c = _a.variantError, variantError = _c === void 0 ? "text" : _c, props = __rest(_a, ["error", "errorPosition", "variantError"]);
    return (React.createElement(StyledContainer, { "$errorPosittion": errorPosition },
        React.createElement(Select, __assign({ ref: ref }, props, { FormControlProps: { error: error, errorPosition: errorPosition, variantError: variantError } })),
        error &&
            typeof error === "string" &&
            variantError === "alert" && React.createElement(FormAlert, { text: error }),
        error &&
            typeof error === "string" &&
            variantError === "text" && (React.createElement(Typography, { type: "text14", color: "general-red" }, error))));
});
export var FormControlSelect = memo(styled(ImplFormControlSelect)(compose(spacing)));
