/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import axios from "axios";
export var ContentType;
(function (ContentType) {
    ContentType["Json"] = "application/json";
    ContentType["FormData"] = "multipart/form-data";
    ContentType["UrlEncoded"] = "application/x-www-form-urlencoded";
    ContentType["Text"] = "text/plain";
})(ContentType || (ContentType = {}));
var HttpClient = /** @class */ (function () {
    function HttpClient(_a) {
        if (_a === void 0) { _a = {}; }
        var _this = this;
        var securityWorker = _a.securityWorker, secure = _a.secure, format = _a.format, axiosConfig = __rest(_a, ["securityWorker", "secure", "format"]);
        this.securityData = null;
        this.setSecurityData = function (data) {
            _this.securityData = data;
        };
        this.request = function (_a) { return __awaiter(_this, void 0, Promise, function () {
            var secureParams, _b, requestParams, responseFormat;
            var secure = _a.secure, path = _a.path, type = _a.type, query = _a.query, format = _a.format, body = _a.body, params = __rest(_a, ["secure", "path", "type", "query", "format", "body"]);
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (typeof secure === "boolean" ? secure : this.secure)
                            && this.securityWorker;
                        if (!_b) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.securityWorker(this.securityData)];
                    case 1:
                        _b = (_c.sent());
                        _c.label = 2;
                    case 2:
                        secureParams = (_b)
                            || {};
                        requestParams = this.mergeRequestParams(params, secureParams);
                        responseFormat = format || this.format || undefined;
                        if (type === ContentType.FormData && body && body !== null
                            && typeof body === "object") {
                            body = this.createFormData(body);
                        }
                        if (type === ContentType.Text && body && body !== null
                            && typeof body !== "string") {
                            body = JSON.stringify(body);
                        }
                        return [2 /*return*/, this.instance.request(__assign(__assign({}, requestParams), { headers: __assign(__assign({}, (requestParams.headers || {})), (type && type !== ContentType.FormData
                                    ? { "Content-Type": type }
                                    : {})), params: query, responseType: responseFormat, data: body, url: path }))];
                }
            });
        }); };
        this.instance = axios.create(__assign(__assign({}, axiosConfig), { baseURL: axiosConfig.baseURL
                || "https://gw.api-stg.evercash.eu/currency" }));
        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker;
    }
    HttpClient.prototype.mergeRequestParams = function (params1, params2) {
        var method = params1.method || (params2 && params2.method);
        return __assign(__assign(__assign(__assign({}, this.instance.defaults), params1), (params2 || {})), { headers: __assign(__assign(__assign({}, ((method
                && this.instance.defaults
                    .headers[method.toLowerCase()])
                || {})), (params1.headers || {})), ((params2 && params2.headers) || {})) });
    };
    HttpClient.prototype.stringifyFormItem = function (formItem) {
        if (typeof formItem === "object" && formItem !== null) {
            return JSON.stringify(formItem);
        }
        else {
            return "".concat(formItem);
        }
    };
    HttpClient.prototype.createFormData = function (input) {
        var _this = this;
        return Object.keys(input || {}).reduce(function (formData, key) {
            var property = input[key];
            var propertyContent = property instanceof Array
                ? property
                : [property];
            for (var _i = 0, propertyContent_1 = propertyContent; _i < propertyContent_1.length; _i++) {
                var formItem = propertyContent_1[_i];
                var isFileType = formItem instanceof Blob
                    || formItem instanceof File;
                formData.append(key, isFileType ? formItem : _this.stringifyFormItem(formItem));
            }
            return formData;
        }, new FormData());
    };
    return HttpClient;
}());
export { HttpClient };
/**
 * @title Currency
 * @version 0.0.1-3f0f388a
 * @baseUrl https://gw.api-stg.evercash.eu/currency
 *
 * API for getting currencies, tokens, blockchains and settings.
 */
var CurrencyApi = /** @class */ (function (_super) {
    __extends(CurrencyApi, _super);
    function CurrencyApi() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.admin = {
            /**
             * @description Update token, or create new one if not exist. Must past complete token object. All fields will be overridden.
             *
             * @tags Admin
             * @name AdminUpdateToken
             * @summary Update token
             * @request PUT:/admin/token/{tokenId}
             * @secure
             */
            adminUpdateToken: function (tokenId, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/token/".concat(tokenId), method: "PUT", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminDeleteToken
             * @summary Delete token settings
             * @request DELETE:/admin/token/{tokenId}
             * @secure
             */
            adminDeleteToken: function (tokenId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/token/".concat(tokenId), method: "DELETE", secure: true }, params));
            },
            /**
             * @description Update token settings, or create new one if not exist. Must past complete token settings object. All fields will be overridden.
             *
             * @tags Admin
             * @name AdminUpdateTokenSettings
             * @summary Update token settings
             * @request PUT:/admin/token/{tokenId}/settings/{partner}/{group}
             * @secure
             */
            adminUpdateTokenSettings: function (tokenId, partner, group, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/token/".concat(tokenId, "/settings/").concat(partner, "/").concat(group), method: "PUT", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminDeleteTokenSettings
             * @summary Delete token settings
             * @request DELETE:/admin/token/{tokenId}/settings/{partner}/{group}
             * @secure
             */
            adminDeleteTokenSettings: function (tokenId, partner, group, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/token/".concat(tokenId, "/settings/").concat(partner, "/").concat(group), method: "DELETE", secure: true }, params));
            },
            /**
             * @description Update exchange pair settings, or create new one if not exist. Must past complete exchange pair settings object. All fields will be overridden.
             *
             * @tags Admin
             * @name AdminUpdateExchangePairSettings
             * @summary Update exchange pair
             * @request PUT:/admin/exchange_pair/{exchangePairId}
             * @secure
             */
            adminUpdateExchangePairSettings: function (exchangePairId, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/exchange_pair/".concat(exchangePairId), method: "PUT", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminDeleteTokenSettings1
             * @summary Delete exchange pair settings
             * @request DELETE:/admin/exchange_pair/{exchangePairId}
             * @secure
             */
            adminDeleteTokenSettings1: function (exchangePairId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/exchange_pair/".concat(exchangePairId), method: "DELETE", secure: true }, params));
            },
            /**
             * @description Update currency, or create new one if not exist. Must past complete currency object.  All fields will be overridden.
             *
             * @tags Admin
             * @name AdminUpdateCurrency
             * @summary Update currency
             * @request PUT:/admin/currency/{slug}
             * @secure
             */
            adminUpdateCurrency: function (slug, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/currency/".concat(slug), method: "PUT", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminDeleteCurrency
             * @summary Delete currency
             * @request DELETE:/admin/currency/{slug}
             * @secure
             */
            adminDeleteCurrency: function (slug, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/currency/".concat(slug), method: "DELETE", secure: true }, params));
            },
            /**
             * @description Update currency settings, or create new one if not exist. Must past complete currency settings object. All fields will be overridden.
             *
             * @tags Admin
             * @name AdminUpdateCurrencySettings
             * @summary Update currency settings
             * @request PUT:/admin/currency/{slug}/settings/{partner}/{group}
             * @secure
             */
            adminUpdateCurrencySettings: function (slug, partner, group, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/currency/".concat(slug, "/settings/").concat(partner, "/").concat(group), method: "PUT", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminDeleteCurrencySettings
             * @summary Delete currency settings
             * @request DELETE:/admin/currency/{slug}/settings/{partner}/{group}
             * @secure
             */
            adminDeleteCurrencySettings: function (slug, partner, group, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/currency/".concat(slug, "/settings/").concat(partner, "/").concat(group), method: "DELETE", secure: true }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminListAllToken
             * @summary List all tokens
             * @request GET:/admin/token
             * @secure
             */
            adminListAllToken: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/token", method: "GET", query: query, secure: true, format: "json" }, params));
            },
            /**
             * @description List all exchange pairs settings. Settings are partner and group related. Null currency means match all currency.
             *
             * @tags Admin
             * @name AdminGetAllExchangePairConfigs
             * @summary List all exchange pair
             * @request GET:/admin/exchange_pair
             * @secure
             */
            adminGetAllExchangePairConfigs: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/exchange_pair", method: "GET", query: query, secure: true, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminListAllCurrency
             * @summary List all currencies
             * @request GET:/admin/currency
             * @secure
             */
            adminListAllCurrency: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/currency", method: "GET", query: query, secure: true, format: "json" }, params));
            },
        };
        _this.token = {
            /**
             * No description
             *
             * @tags Tokens
             * @name GetAllTokens
             * @request GET:/token
             */
            getAllTokens: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/token", method: "GET", query: query, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Tokens
             * @name CreateToken
             * @request POST:/token
             */
            createToken: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/token", method: "POST", body: data, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Tokens
             * @name GetAllTokensShort
             * @request GET:/token/short
             */
            getAllTokensShort: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/token/short", method: "GET", format: "json" }, params));
            },
        };
        _this.preferred = {
            /**
             * @description Returns ordered list of currency slugs
             *
             * @tags preferences-controller
             * @name GetPreferredCurrencies
             * @summary Get preferred currencies for account
             * @request GET:/preferred/{accountId}
             * @secure
             */
            getPreferredCurrencies: function (accountId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/preferred/".concat(accountId), method: "GET", secure: true }, params));
            },
            /**
             * @description Sets new list of preferred currencies, returns ordered list of currency slugs
             *
             * @tags preferences-controller
             * @name SetPreferredCurrenciesSlug
             * @summary Sets new preferred currencies
             * @request POST:/preferred/{accountId}
             * @secure
             */
            setPreferredCurrenciesSlug: function (accountId, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/preferred/".concat(accountId), method: "POST", body: data, secure: true, type: ContentType.Json }, params));
            },
        };
        _this.blockchain = {
            /**
             * No description
             *
             * @tags Blockchains
             * @name GetAllBlockchains
             * @request GET:/blockchain
             */
            getAllBlockchains: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/blockchain", method: "GET", format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Blockchains
             * @name CreateBlockchain
             * @request POST:/blockchain
             */
            createBlockchain: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/blockchain", method: "POST", body: data, type: ContentType.Json, format: "json" }, params));
            },
        };
        _this.rate = {
            /**
             * @description Get rate for exchange pair and amount. Rate is singed and available only specific time. To receive special rate pass to method account or partner code.
             *
             * @tags Rates
             * @name GetRate
             * @summary Get rate for exchange pair
             * @request GET:/rate
             */
            getRate: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/rate", method: "GET", query: query, format: "json" }, params));
            },
        };
        _this.instruments = {
            /**
             * No description
             *
             * @tags Rate instruments
             * @name GetPriceByInstrument
             * @request GET:/instruments/{instrument}
             */
            getPriceByInstrument: function (instrument, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/instruments/".concat(instrument), method: "GET", format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Rate instruments
             * @name GetInstrumentList
             * @request GET:/instruments/
             */
            getInstrumentList: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/instruments/", method: "GET", format: "json" }, params));
            },
        };
        _this.currency = {
            /**
             * No description
             *
             * @tags Currencies
             * @name GetAllCurrencies
             * @request GET:/currency
             */
            getAllCurrencies: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/currency", method: "GET", format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Currencies
             * @name GetCurrencyBySlug
             * @request GET:/currency/slug/{slug}
             */
            getCurrencyBySlug: function (slug, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/currency/slug/".concat(slug), method: "GET", format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Currencies
             * @name GetAllCurrenciesShort
             * @request GET:/currency/short
             */
            getAllCurrenciesShort: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/currency/short", method: "GET", format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Currencies
             * @name GetCurrencyByName
             * @request GET:/currency/name/{name}
             */
            getCurrencyByName: function (name, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/currency/name/".concat(name), method: "GET", format: "json" }, params));
            },
        };
        return _this;
    }
    return CurrencyApi;
}(HttpClient));
export { CurrencyApi };
