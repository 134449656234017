var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { AccountFormStore } from "@/features/create-account/stores/AccountFormStore";
import { Box, Button, FormControlInput, Typography } from "@/shared/components";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { Popup } from "@/shared/components/Popup";
import { PopupHead } from "@/shared/components/Popup/Head";
import { useMedia } from "@/shared/hooks/useMedia";
import { useProvider, useStore, useStoreContext, } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { EventsStore } from "@/shared/stores/EventsStore";
import { onChange } from "@/shared/utils/input";
import { preventDefault } from "@/shared/utils/prevent";
// TODO: Show errors
export var CreateAccountPopup = observer(function (_a) {
    var onClose = _a.onClose, onSuccess = _a.onSuccess;
    var media = useMedia();
    var events = useStoreContext(EventsStore);
    var form = useStore(AccountFormStore, events);
    var AccountFormProvider = useProvider(AccountFormStore);
    var currencyList = useStoreContext(CurrencyListStore);
    var list = currencyList.list.filter(function (item) { var _a; return item.shortName && ((_a = item.type) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "fiat"; });
    var onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var id;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, form.submit()];
                case 1:
                    id = _a.sent();
                    if (id)
                        onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(id);
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(AccountFormProvider, { value: form },
        React.createElement(Popup, { width: "450px", onClose: onClose },
            React.createElement(PopupHead, { onClose: onClose }, "\u200b"),
            React.createElement("form", { onSubmit: preventDefault(onSubmit) },
                React.createElement(Box, { minHeight: "505px", boxSizing: "border-box", padding: media.phone ? "0 25px 25px" : "0 50px 50px", display: "flex", flexDirection: "column", gap: "30px" },
                    React.createElement(Typography, { type: "text28", fontWeight: 500, textAlign: "center", variant: "h2" }, "Open bank account"),
                    React.createElement(Box, { display: "flex", flexDirection: "column", flexGrow: "1", gap: "20px" },
                        React.createElement(FormControlInput, { outLabel: "Name", placeholder: "Account name", disabled: form.loading || !!form.accountId, value: form.name, onChange: onChange(action(function (value) {
                                form.name = value;
                            })), InputProps: {
                                endAdornment: (React.createElement(Typography, { type: "text12", color: "black-500" }, "Optional")),
                            } }),
                        React.createElement(FormControlSelect, { label: "Currency", placeholder: "Select currency", disabled: form.loading || !!form.accountId, value: form.currency, onChange: function (_, option) {
                                runInAction(function () {
                                    form.currency = option === null || option === void 0 ? void 0 : option.key;
                                });
                            }, options: list.map(function (item) { return ({
                                key: item.shortName,
                                label: item.description
                                    ? "".concat(item.shortName, " (").concat(item.description, ")")
                                    : item.shortName,
                            }); }) })),
                    React.createElement(Button, { type: "submit", disabled: form.loading ||
                            !form.valid ||
                            !!form.accountId }, "Open"))))));
});
