import React from "react";
import { generatePath, Link } from "react-router-dom";
import { useGetVerificationInfo } from "@/api/registration";
import { VerificationStatus } from "@/api/registration/types";
import { ROUTES } from "@/routes";
import { Box, Button, Typography } from "@/shared/components";
var getTextByStatus = function (status) {
    if (status === VerificationStatus.REJECT || status === VerificationStatus.RETRY) {
        return (React.createElement(Typography, { color: "general-red" }, "Something went wrong with your verification. Please, try again."));
    }
    if (status === VerificationStatus.PENDING) {
        return (React.createElement(Typography, { color: "black-500" }, "Your verification in progress. We need up to 48 hours to verify the information provided."));
    }
    if (status === VerificationStatus.GREEN) {
        return React.createElement(Typography, { color: "black-400" }, "You are verified");
    }
    return null;
};
export var InformationTitle = function (_a) {
    var title = _a.title;
    var verificationInfo = useGetVerificationInfo().data;
    var status = verificationInfo === null || verificationInfo === void 0 ? void 0 : verificationInfo.status;
    return (React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
        React.createElement(Box, { gap: "20px", display: "flex", alignItems: "center" },
            React.createElement(Box, { bgColor: "black-1200", borderRadius: "100%", width: "60px", height: "60px", display: "flex", alignItems: "center", justifyContent: "center" },
                React.createElement(Typography, { color: "general-white", type: "text24" }, title.slice(0, 1))),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
                React.createElement(Typography, { type: "text28Bold" }, title),
                getTextByStatus(status))),
        status === VerificationStatus.RETRY || status === VerificationStatus.REJECT
            ? (React.createElement(Button, { LinkComponent: Link, to: generatePath(ROUTES.kybForm) }, "Update info"))
            : status === VerificationStatus.PENDING
                ? React.createElement(Button, { disabled: true }, "In progress")
                : null));
};
