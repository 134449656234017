var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { formattedTokenAmount, sliceAddress } from "@broxus/js-utils";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCreateTransfer, useGetRecipients } from "@/api/wallet";
import { Header } from "@/pages/TransferPage/components/Header";
import { TransferRecipientFormStore } from "@/pages/TransferPage/stores/TransferRecipientFormStore";
import { ROUTES, TransferType } from "@/routes";
import { Box, Button, FormControlInput, Typography } from "@/shared/components";
import { AccountSelect } from "@/shared/components/AccountSelect";
import { BankAccountPopup } from "@/shared/components/BankAccountPopup";
import { BankIcon } from "@/shared/components/BankIcon";
import { ExchangeField } from "@/shared/components/ExchangeField";
import { ExchangeInput } from "@/shared/components/ExchangeInput";
import { Info } from "@/shared/components/Info";
import { OperationLayout } from "@/shared/components/OperationLayout";
import { RecipientPopup } from "@/shared/components/RecipientPopup";
import { SectionAmount } from "@/shared/components/SectionAmount";
import { UserPick } from "@/shared/components/UserPick";
import { useMedia } from "@/shared/hooks/useMedia";
import { useProvider, useStore, useStoreContext, } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { generateQueryPath } from "@/shared/utils/path";
export var Recipient = observer(function () {
    var media = useMedia();
    var fromPopup = useToggler();
    var navigate = useNavigate();
    var recipientPopup = useToggler();
    var search = useSearchParams()[0];
    var from = search.get("from");
    var to = search.get("to");
    var accounts = useStoreContext(AccountListStore);
    var fromAcc = from ? accounts.fiatById[from] : undefined;
    var FormProvider = useProvider(TransferRecipientFormStore);
    var balances = useStoreContext(BalanceListStore);
    var form = useStore(TransferRecipientFormStore, balances);
    var data = useGetRecipients().data;
    var toAcc = to ? data === null || data === void 0 ? void 0 : data.find(function (item) { return item.id === to; }) : undefined;
    var error = form.enough === false ? "Enter a smaller amount" : undefined;
    React.useEffect(function () {
        runInAction(function () {
            form.from = from !== null && from !== void 0 ? from : undefined;
            form.to = to !== null && to !== void 0 ? to : undefined;
        });
    }, [from, to, form]);
    React.useEffect(function () {
        if (from) {
            balances.sync(from);
        }
    }, [from, balances]);
    var _a = useCreateTransfer({}), mutateAsync = _a.mutateAsync, datePreTransfer = _a.data;
    var preTransfer = datePreTransfer === null || datePreTransfer === void 0 ? void 0 : datePreTransfer.data;
    var createTransfer = function () {
        mutateAsync({
            amount: form.amount,
            fromAccount: fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.account,
            toAccount: toAcc.accountNumber,
            currency: fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency,
        });
    };
    var info = [
        {
            label: "Amount",
            value: (preTransfer === null || preTransfer === void 0 ? void 0 : preTransfer.amount)
                ? "".concat(formattedTokenAmount(preTransfer === null || preTransfer === void 0 ? void 0 : preTransfer.amount), " ").concat(preTransfer === null || preTransfer === void 0 ? void 0 : preTransfer.currency)
                : "–",
        },
        {
            label: "Transaction fee",
            value: "–",
        },
        {
            label: "Operation time",
            value: "Instantly",
        },
    ];
    return (React.createElement(FormProvider, { value: form },
        fromPopup.enabled && (React.createElement(BankAccountPopup, { selectedId: fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.account, onClose: fromPopup.off, onSelect: function (id) {
                fromPopup.off();
                navigate(generateQueryPath(ROUTES.transfer, {
                    type: TransferType.Recipient,
                }, {
                    from: id,
                    to: to,
                }), {
                    replace: true,
                });
            } })),
        recipientPopup.enabled && (React.createElement(RecipientPopup, { selectedId: to, onClose: recipientPopup.off, onSelect: function (id) {
                recipientPopup.off();
                navigate(generateQueryPath(ROUTES.transfer, {
                    type: TransferType.Recipient,
                }, {
                    from: from,
                    to: id,
                }), {
                    replace: true,
                });
            } })),
        React.createElement(OperationLayout, { main: React.createElement(Box, null,
                React.createElement(Header, { active: TransferType.Recipient }),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
                    React.createElement(ExchangeField, { error: error, input: React.createElement(ExchangeInput, { invalid: !!error, postFix: (fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency.toUpperCase()) ||
                                "", value: form.amount, onChange: action(function (value) {
                                form.amount = value;
                            }) }), select: React.createElement(AccountSelect, { name: fromAcc
                                ? sliceAddress(fromAcc.account)
                                : undefined, descLoading: !form.balance, desc: form.balance
                                ? "".concat(formattedTokenAmount(form.balance.balance), " ").concat(form.balance.currency.toUpperCase())
                                : undefined, iconUri: fromAcc
                                ? fromAcc.currency
                                : undefined, onClick: fromPopup.on, icon: fromAcc ? (React.createElement(BankIcon, { symbol: fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency, size: 24 })) : undefined }) }),
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
                        React.createElement(ExchangeField, { value: "Bank account", select: React.createElement(AccountSelect, { icon: toAcc ? (React.createElement(UserPick, { name: toAcc === null || toAcc === void 0 ? void 0 : toAcc.profile.name, size: 24 })) : undefined, name: toAcc === null || toAcc === void 0 ? void 0 : toAcc.profile.name, onClick: recipientPopup.on }) }),
                        React.createElement(FormControlInput, { placeholder: "Purpose of payment", InputProps: {
                                endAdornment: (React.createElement(Typography, { type: "text12", color: "black-500" }, "Required")),
                            } })))), card: media.desktop && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "50px" },
                React.createElement(Info, { data: info }),
                React.createElement(Box, { height: "1px", bgColor: "black-1000", width: "100%" }),
                React.createElement(SectionAmount, { amount: "0.00", currency: "EUR" }),
                React.createElement(Button, { width: "100%", onClick: createTransfer }, "Send"))), side: !media.desktop && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "30px", width: "100%" },
                React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }),
                React.createElement(Info, { dark: false, data: __spreadArray(__spreadArray([], info, true), [
                        {
                            label: "Total + Fees",
                            value: "0.00 EUR",
                        },
                    ], false) }),
                React.createElement(Button, { width: "100%", onClick: createTransfer }, "Send"))) })));
});
