var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { CreateAccountPopup } from "@/features/create-account/components/CreateAccountPopup";
import { AccountItem } from "@/shared/components/BankAccountPopup/AccountItem";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { Loader } from "@/shared/components/Loader";
import { Popup } from "@/shared/components/Popup";
import { PopupItem } from "@/shared/components/Popup/Item";
import { PopupHead } from "@/shared/components/Popup/Head";
import { Typography } from "@/shared/components/Typography";
import { useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
export var BankAccountPopup = observer(function (_a) {
    var selectedId = _a.selectedId, onClose = _a.onClose, onSelect = _a.onSelect;
    var popup = useToggler();
    var accounts = useStoreContext(AccountListStore);
    var balanceList = useStoreContext(BalanceListStore);
    var addressList = useStoreContext(AddressListStore);
    var loaded = true;
    React.useEffect(function () {
        balanceList.sync.apply(balanceList, accounts.fiat.map(function (item) { return item.account; }));
    }, [balanceList, accounts.fiat]);
    React.useEffect(function () {
        addressList.sync.apply(addressList, accounts.fiat);
    }, [addressList, accounts.fiat]);
    return popup.enabled ? (React.createElement(CreateAccountPopup, { onClose: popup.off, onSuccess: popup.off })) : (React.createElement(Popup, { onClose: onClose },
        React.createElement(PopupHead, { onClose: onClose }, "Select bank account"),
        React.createElement(Box, { height: "1px", bgColor: "black-50" }),
        React.createElement(Box, { position: "relative" },
            !loaded && (React.createElement(Loader, { size: 24, position: "absolute", top: "50%", left: "50%", margin: "-12px 0 0 -12px", zIndex: "100" })),
            React.createElement(Wrapper, { loaded: loaded },
                React.createElement(Box, { overflow: "hidden", borderRadius: "0 0 22px 22px" },
                    React.createElement(List, null,
                        React.createElement(PopupItem, { onClick: popup.on },
                            React.createElement(BaseIcon, { icon: "Plus", size: 14, containerSize: 36, borderRadius: "36px", bgColor: "light-blue", color: "deep-blue-500" }),
                            React.createElement(Typography, { type: "text14", color: "deep-blue-500" }, "Open new bank account")),
                        accounts.fiat.map(function (item) {
                            console.log(item, "___item");
                            return (React.createElement(AccountItem, { key: item.account, id: item.account, symbol: "", selected: item.account === selectedId, amount: "", accNum: "", desc: item.shortName, onSelect: function () {
                                    onSelect(item.account);
                                } }));
                        })))))));
});
var Wrapper = styled("div")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n    opacity: ", ";\n    transition: opacity 0.2s ease;\n    pointer-events: ", ";\n"], ["\n    position: relative;\n    opacity: ", ";\n    transition: opacity 0.2s ease;\n    pointer-events: ", ";\n"])), function (props) { return (props.loaded ? 1 : 0); }, function (props) { return (props.loaded ? "unset" : "none"); });
var List = styled("div")(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    overflow: auto;\n    max-height: 505px;\n    scrollbar-width: none;\n\n    &::-webkit-scrollbar {\n        display: none;\n    }\n"], ["\n    overflow: auto;\n    max-height: 505px;\n    scrollbar-width: none;\n\n    &::-webkit-scrollbar {\n        display: none;\n    }\n"])));
var templateObject_1, templateObject_2;
