var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable react-hooks/exhaustive-deps */
import { formattedTokenAmount, formattedAmount } from "@broxus/js-utils";
import BN from "bignumber.js";
import debounce from "lodash.debounce";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";
import { ExchangeModal } from "../Modal";
import { useGetTokens } from "@/api/currency";
import { useCreateDirectExchange } from "@/api/wallet";
import { ExchangeFormStore } from "@/pages/ExchangePage/stores/ExchangeFormStore";
import { ROUTES } from "@/routes";
import { BaseIcon, Box, Button } from "@/shared/components";
import { AccountSelect } from "@/shared/components/AccountSelect";
import { ExchangeField } from "@/shared/components/ExchangeField";
import { ExchangeInput } from "@/shared/components/ExchangeInput";
import { HintBtn } from "@/shared/components/HintBtn";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { TokenPopup } from "@/shared/components/TokenPopup";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { generateQueryPath } from "@/shared/utils/path";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
// export const MAIN_CRYPTO = "N901";
export var Exchange = observer(function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var tokenPopup = useToggler();
    var tokenPopup2 = useToggler();
    var navigate = useNavigate();
    var accounts = useStoreContext(AccountListStore);
    var balances = useStoreContext(BalanceListStore);
    var currencies = useStoreContext(CurrencyListStore);
    var form = useStore(ExchangeFormStore, balances);
    var search = useSearchParams()[0];
    var from = search.get("from");
    var to = search.get("to");
    var _k = React.useState(false), isOpenModal = _k[0], setOpenModal = _k[1];
    var tokens = useGetTokens().data;
    var fiatId = to;
    var cryptoId = from;
    var fiatAcc = accounts.crypto[0] ? accounts.crypto[0] : null;
    var cryptoAcc = accounts.crypto[0] ? accounts.crypto[0] : null;
    var fromAcc = fiatAcc;
    var toAcc = fiatAcc;
    var fiatError = form.fiatEnough === false
        ? "Enter a smaller amount"
        : undefined;
    var cryptoError = form.cryptoEnough === false
        ? "Enter a smaller amount"
        : undefined;
    React.useEffect(function () {
        if (from || to)
            balances.sync(fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.account);
    }, [balances, fromAcc, from, to]);
    React.useEffect(function () {
        runInAction(function () {
            form.cryptoId = cryptoId !== null && cryptoId !== void 0 ? cryptoId : undefined;
            form.fiatId = fiatId !== null && fiatId !== void 0 ? fiatId : undefined;
        });
    }, [cryptoId, fiatId, form]);
    var _l = useCreateDirectExchange({}), mutateAsync = _l.mutateAsync, data = _l.data, isPendingPreExchange = _l.isPending, reset = _l.reset;
    var preExchangeInfo = data === null || data === void 0 ? void 0 : data.data;
    var preExchange = React.useCallback(function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var field, amount, data_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    field = "baseAmount";
                    amount = "";
                    if (!(from &&
                        to &&
                        (fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.account) &&
                        (toAcc === null || toAcc === void 0 ? void 0 : toAcc.account) &&
                        !!Number(value))) return [3 /*break*/, 2];
                    return [4 /*yield*/, mutateAsync({
                            dryRun: true,
                            data: {
                                fromAccount: fromAcc.account,
                                toAccount: toAcc.account,
                                fromCurrency: from,
                                toCurrency: to,
                                fromAmount: value,
                            },
                        })];
                case 1:
                    data_1 = _a.sent();
                    console.log(data_1, "___data");
                    amount = data_1.data.toAmount;
                    _a.label = 2;
                case 2:
                    runInAction(function () {
                        form[field] = amount;
                    });
                    return [2 /*return*/];
            }
        });
    }); }, [from, to, fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.account, toAcc === null || toAcc === void 0 ? void 0 : toAcc.account, mutateAsync]);
    var onSuccess = function () {
        reset();
        form.reset();
        if (from || to)
            balances.sync(from, to);
    };
    var debounceExchange = React.useCallback(debounce(preExchange, 400), [
        preExchange,
    ]);
    var defaultAmount = form.quoteAmount;
    React.useEffect(function () {
        debounceExchange(defaultAmount);
    }, [from, defaultAmount]);
    var baseCryptoData = React.useMemo(function () {
        var tokenSymbol = to;
        var tokenData = currencies.list.find(function (_) { var _a; return ((_a = _.slug) === null || _a === void 0 ? void 0 : _a.toUpperCase()) === tokenSymbol; });
        var itemBalFound = {
            balance: "0",
            account: "",
            currency: "",
        };
        Object.values(balances.dataAll).forEach(function (balanceData) {
            var balFound = balanceData === null || balanceData === void 0 ? void 0 : balanceData.find(function (_) { var _a; return _.currency === ((_a = tokenData === null || tokenData === void 0 ? void 0 : tokenData.slug) === null || _a === void 0 ? void 0 : _a.toUpperCase()); });
            if (balFound)
                itemBalFound = balFound;
        });
        return __assign(__assign({}, tokenData), { balance: itemBalFound ? itemBalFound.balance : "0" });
    }, [currencies.list, balances.data]);
    var quoteCryptoData = React.useMemo(function () {
        var tokenSymbol = from;
        var tokenData = currencies.list.find(function (_) { var _a; return ((_a = _.slug) === null || _a === void 0 ? void 0 : _a.toUpperCase()) === tokenSymbol; });
        var itemBalFound = {
            balance: "0",
            account: "",
            currency: "",
        };
        Object.values(balances.dataAll).forEach(function (balanceData) {
            var balFound = balanceData === null || balanceData === void 0 ? void 0 : balanceData.find(function (_) { var _a; return _.currency === ((_a = tokenData === null || tokenData === void 0 ? void 0 : tokenData.slug) === null || _a === void 0 ? void 0 : _a.toUpperCase()); });
            if (balFound)
                itemBalFound = balFound;
        });
        return __assign(__assign({}, tokenData), { balance: itemBalFound ? itemBalFound.balance : "0" });
    }, [currencies.list, balances.data]);
    var mainInEur = React.useMemo(function () {
        var _a;
        return form.baseAmount
            ? new BN(form.baseAmount)
                .times((_a = baseCryptoData.eurPrice) !== null && _a !== void 0 ? _a : 0)
                .toFixed(2)
            : "0";
    }, [form.baseAmount, baseCryptoData]);
    var basicInEur = React.useMemo(function () {
        var _a;
        return form.quoteAmount
            ? new BN(form.quoteAmount)
                .times((_a = quoteCryptoData.eurPrice) !== null && _a !== void 0 ? _a : 0)
                .toFixed(2)
            : "0";
    }, [form.quoteAmount, baseCryptoData]);
    var isDisabledExchange = !!fiatError ||
        !!cryptoError ||
        !Number(form.baseAmount) ||
        !Number(form.quoteAmount);
    var baseField = (React.createElement(ExchangeField, { error: fiatError, input: React.createElement(ExchangeInput, { decimals: baseCryptoData ? baseCryptoData === null || baseCryptoData === void 0 ? void 0 : baseCryptoData.decimals : undefined, invalid: !!fiatError, disabled: true, 
            // postFix={cryptoAcc?.currency.toUpperCase()}
            value: form.baseAmount, onChange: action(function (value) {
                form.baseAmount = value;
            }) }), amount: (preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount) ? "".concat(mainInEur, " $") : "$ 0", select: React.createElement(AccountSelect, { name: baseCryptoData ? baseCryptoData.shortName : undefined, from: true, desc: baseCryptoData
                ? "".concat(formattedTokenAmount(baseCryptoData.balance), " ").concat((_a = baseCryptoData === null || baseCryptoData === void 0 ? void 0 : baseCryptoData.shortName) === null || _a === void 0 ? void 0 : _a.toUpperCase())
                : undefined, icon: cryptoAcc ? (React.createElement(TokenIcon, { symbol: baseCryptoData === null || baseCryptoData === void 0 ? void 0 : baseCryptoData.shortName, size: 24 })) : undefined, handleMax: function () {
                if (quoteCryptoData.balance) {
                    form.quoteAmount = formattedAmount(quoteCryptoData.balance);
                }
            }, onClick: tokenPopup2.on }) }));
    var quoteField = (React.createElement(ExchangeField, { error: cryptoError, input: React.createElement(ExchangeInput, { decimals: quoteCryptoData ? quoteCryptoData === null || quoteCryptoData === void 0 ? void 0 : quoteCryptoData.decimals : undefined, invalid: !!cryptoError, 
            // postFix={cryptoAcc?.currency.toUpperCase()}
            value: form.quoteAmount, onChange: action(function (value) {
                form.quoteAmount = value;
            }) }), amount: (preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.toAmount) ? "".concat(basicInEur, " $") : "$ 0", select: React.createElement(AccountSelect, { name: quoteCryptoData ? quoteCryptoData.shortName : undefined, from: true, desc: quoteCryptoData
                ? "".concat(formattedTokenAmount(quoteCryptoData.balance), " ").concat((_b = quoteCryptoData === null || quoteCryptoData === void 0 ? void 0 : quoteCryptoData.shortName) === null || _b === void 0 ? void 0 : _b.toUpperCase())
                : undefined, icon: cryptoAcc ? (React.createElement(TokenIcon, { symbol: quoteCryptoData.shortName, size: 24 })) : undefined, handleMax: function () {
                if (quoteCryptoData.balance) {
                    form.quoteAmount = formattedAmount(quoteCryptoData.balance);
                }
            }, onClick: tokenPopup.on }) }));
    var fromDecimals = (_c = tokens === null || tokens === void 0 ? void 0 : tokens[preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromCurrency]) === null || _c === void 0 ? void 0 : _c.decimals;
    var rate = (preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.toAmount) && (preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount)
        ? BN(preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.toAmount)
            .div(preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount)
            .toFixed(fromDecimals || 6)
        : undefined;
    // const info = [
    //     {
    //         label: "Exchange fee",
    //         value: "0 USDT",
    //     },
    // ];
    return (React.createElement(React.Fragment, null,
        tokenPopup.enabled && (React.createElement(TokenPopup, { selectedId: cryptoAcc === null || cryptoAcc === void 0 ? void 0 : cryptoAcc.account, onClose: tokenPopup.off, onSelect: function (id) {
                var _a;
                tokenPopup.off();
                form.reset();
                navigate(generateQueryPath(ROUTES.exchange, {}, {
                    from: id.toUpperCase(),
                    to: (_a = baseCryptoData.slug) === null || _a === void 0 ? void 0 : _a.toUpperCase(),
                }), {
                    replace: true,
                });
            } })),
        tokenPopup2.enabled && (React.createElement(TokenPopup, { selectedId: cryptoAcc === null || cryptoAcc === void 0 ? void 0 : cryptoAcc.account, onClose: tokenPopup2.off, onSelect: function (id) {
                var _a;
                tokenPopup2.off();
                form.reset();
                navigate(generateQueryPath(ROUTES.exchange, {}, {
                    from: (_a = quoteCryptoData.slug) === null || _a === void 0 ? void 0 : _a.toUpperCase(),
                    to: id.toUpperCase(),
                }), {
                    replace: true,
                });
            } })),
        React.createElement(Box, { maxWidth: "360px" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
                React.createElement(Box, { position: "relative", display: "flex", flexDirection: "column", gap: "4px" },
                    quoteField,
                    React.createElement(BoxCentred, null,
                        React.createElement(Button, { replace: true, variant: "White", size: "xxs", width: "36px", alignSelf: "center", LinkComponent: Link, tabIndex: -1, to: generateQueryPath(ROUTES.exchange, {}, {
                                from: to,
                                to: from,
                            }) },
                            React.createElement(BaseIcon, { icon: "SwapArrow", size: 20 }))),
                    baseField),
                false && (React.createElement(Box, { display: "grid", gridAutoFlow: "column", gridAutoColumns: "minmax(0,1fr)", gap: "5px" },
                    React.createElement(HintBtn, { value: "0.0000105", label: "Min EUR" }),
                    React.createElement(HintBtn, { value: "All funds", disabled: !Number((_d = form.cryptoBalance) === null || _d === void 0 ? void 0 : _d.balance), onClick: function () {
                            runInAction(function () {
                                var _a;
                                form.quoteAmount =
                                    (_a = form.cryptoBalance) === null || _a === void 0 ? void 0 : _a.balance;
                            });
                        } })))),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "16px", mt: "16px" },
                React.createElement(Button, { width: "100%", size: "m", onClick: function () { return setOpenModal(true); }, disabled: isPendingPreExchange || isDisabledExchange, loading: isPendingPreExchange }, "Exchange"))),
        React.createElement(ExchangeModal, { onSuccess: onSuccess, isOpen: isOpenModal, onClose: function () { return setOpenModal(false); }, data: {
                fromAccount: (_e = fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.account) !== null && _e !== void 0 ? _e : "",
                toAccount: (_f = toAcc === null || toAcc === void 0 ? void 0 : toAcc.account) !== null && _f !== void 0 ? _f : "",
                fromCurrency: from !== null && from !== void 0 ? from : "",
                toCurrency: to !== null && to !== void 0 ? to : "",
                fromAmount: form.quoteAmount,
                id: preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.id,
            }, rateInfo: {
                label: "".concat((_g = currencies.list.find(function (_) { var _a; return ((_a = _.slug) === null || _a === void 0 ? void 0 : _a.toUpperCase()) === (from === null || from === void 0 ? void 0 : from.toUpperCase()); })) === null || _g === void 0 ? void 0 : _g.shortName, "/").concat((_h = currencies.list.find(function (_) { var _a; return ((_a = _.slug) === null || _a === void 0 ? void 0 : _a.toUpperCase()) === (to === null || to === void 0 ? void 0 : to.toUpperCase()); })) === null || _h === void 0 ? void 0 : _h.shortName, " rate"),
                value: rate ? "".concat(rate, " ").concat((_j = currencies.list.find(function (_) { var _a; return ((_a = _.slug) === null || _a === void 0 ? void 0 : _a.toUpperCase()) === (to === null || to === void 0 ? void 0 : to.toUpperCase()); })) === null || _j === void 0 ? void 0 : _j.shortName) : "–",
            }, toAmount: formattedTokenAmount((preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.toAmount) || 0) })));
});
var BoxCentred = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n"], ["\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n"])));
var templateObject_1;
