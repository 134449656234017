var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { VerificationStatus } from "@/api/registration/types";
import { TokenForm } from "@/pages/PaymentPage/components/PaymentFormCrypto/TokenForm";
import { TrxForm } from "@/pages/PaymentPage/components/PaymentFormCrypto/TrxForm";
import { PaymentCryptoFormStore } from "@/pages/PaymentPage/stores/PaymentCryptoFormStore";
import { PaymentParam, ROUTES } from "@/routes";
import { Button } from "@/shared/components";
import { useProvider, useStore, useStoreContext } from "@/shared/hooks/useStore";
import { CompanyInfoStore } from "@/shared/stores/CompanyInfoStore";
import { generateQueryPath } from "@/shared/utils/path";
import { UnderReviewPopup } from "@/widgets/UnderReviewPopup";
export var PaymentFormCrypto = observer(function () {
    var _a;
    var navigate = useNavigate();
    var search = useSearchParams()[0];
    var target = search.get(PaymentParam.Target);
    var plan = search.get(PaymentParam.Plan);
    var companyInfo = useStoreContext(CompanyInfoStore);
    var form = useStore(PaymentCryptoFormStore, companyInfo, target);
    var FormProvider = useProvider(PaymentCryptoFormStore);
    return (React.createElement(FormProvider, { value: form },
        (form.success || ((_a = form.verification) === null || _a === void 0 ? void 0 : _a.status) === VerificationStatus.PENDING) && React.createElement(UnderReviewPopup, null),
        React.createElement(Wrapper, null,
            React.createElement(Button, { variant: "Gray", icon: "Back", width: "auto", paddingRight: "25px", marginBottom: "60px", disabled: form.success, onClick: function () {
                    var _a;
                    return navigate(generateQueryPath(ROUTES.payment, {}, (_a = {},
                        _a[PaymentParam.Target] = target,
                        _a[PaymentParam.Plan] = plan,
                        _a)), {
                        replace: true,
                    });
                } }, "Back"),
            React.createElement(TokenForm, null)),
        React.createElement(Sidebar, null,
            React.createElement(TrxForm, null))));
});
var Wrapper = styled("div")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    max-width: 560px;\n\n    ", " {\n        max-width: unset;\n    }\n"], ["\n    max-width: 560px;\n\n    ", " {\n        max-width: unset;\n    }\n"])), function (props) { return props.theme.breakpoints.down("md"); });
var Sidebar = styled("div")(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: max(calc(((100% - 1240px) / 2) + 560px + min(160px, ((100% - 1240px) / 2))), 600px);\n    right: 0;\n    z-index: -1;\n    padding: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: ", ";\n\n    ", " {\n        position: static;\n        padding: 0;\n        margin: 40px 0 0;\n        background: none;\n    }\n"], ["\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: max(calc(((100% - 1240px) / 2) + 560px + min(160px, ((100% - 1240px) / 2))), 600px);\n    right: 0;\n    z-index: -1;\n    padding: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: ", ";\n\n    ", " {\n        position: static;\n        padding: 0;\n        margin: 40px 0 0;\n        background: none;\n    }\n"])), function (props) { return props.theme.colors["black-1200"]; }, function (props) { return props.theme.breakpoints.down("md"); });
var templateObject_1, templateObject_2;
