import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { SigninPageStore } from "@/pages/SigninPage/stores/SigninPageStore";
import { Button, FormControlInput } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { useStoreContext } from "@/shared/hooks/useStore";
import { onChange } from "@/shared/utils/input";
export var VerificationForm = observer(function () {
    var _a;
    var page = useStoreContext(SigninPageStore);
    return (React.createElement(AuthForm, { onBack: page.reset, onSubmit: page.submitCode, title: "Enter your 2FA code", content: React.createElement(FormControlInput, { type: "text", outLabel: "Google Authenticator Code", placeholder: "Check your 2FA app", value: page.code, onChange: onChange(action(function (value) {
                page.code = value;
            })), disabled: page.loading, variantError: "alert", error: (_a = page.errors[0]) === null || _a === void 0 ? void 0 : _a.description }), footer: React.createElement(Button, { type: "submit", width: "100%", disabled: page.loading || !page.code }, "Log in") }));
});
