import { formattedTokenAmount } from "@broxus/js-utils";
import BigNumber from "bignumber.js";
import React from "react";
import { useGetTokens } from "@/api/currency";
import { BaseIcon, Typography } from "@/shared/components";
var COLOR = {
    PENDING: "general-orange",
    REJECTED: "general-red",
};
export var TransactionAmount = function (_a) {
    var _b;
    var status = _a.status, amount = _a.amount, currency = _a.currency, text = _a.text;
    var tokens = useGetTokens().data;
    var getColor = function (status) {
        var textColor = COLOR === null || COLOR === void 0 ? void 0 : COLOR[status];
        if (textColor)
            return textColor;
        return BigNumber(amount).gt(0)
            ? "general-green"
            : "black-1200";
    };
    var textColor = getColor(status);
    return (React.createElement(Typography, { color: textColor, display: "flex", alignItems: "center", gap: "10px", type: text },
        formattedTokenAmount(amount, undefined, { precision: (_b = tokens === null || tokens === void 0 ? void 0 : tokens[currency]) === null || _b === void 0 ? void 0 : _b.decimals }),
        textColor === "general-red" && React.createElement(BaseIcon, { icon: "Failed" }),
        textColor === "general-orange" && React.createElement(BaseIcon, { icon: "Pending" })));
};
