import React, { useState } from "react";
import { styled } from "@mui/material";
import { cryptoColumns, featColumns } from "../../utils";
import AddressesTable from "./AddressesTable";
import { AddAddress } from "./AddAddress";
import { Box, Button, Typography } from "@/shared/components";
import { useMedia } from "@/shared/hooks/useMedia";
import { useGetRecipients } from "@/api/wallet";
var UserAddresses = function () {
    var desktop = useMedia().desktop;
    var data = useGetRecipients().data;
    console.log("🚀 ~ data:", data);
    var _a = useState("Feat"), activeTab = _a[0], setActiveTab = _a[1];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _b = useState(), tableData = _b[0], setTableData = _b[1];
    var _c = useState(false), addAddressOpen = _c[0], setAddAddress = _c[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(AddAddress, { open: addAddressOpen, isFeat: activeTab === "Feat", onClose: function () { return setAddAddress(false); } }),
        React.createElement(Box, { mb: "20px", bgColor: "general-white", width: desktop ? "835px" : "auto", display: "flex", flexDirection: "column", gap: "16px" },
            React.createElement(Typography, { type: "title26Neo" }, "Withdrawal addresses"),
            React.createElement(Typography, { type: "text14", color: "text-secondary" }, "The white list of addresses where you can withdraw your tokens."),
            React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                React.createElement(CustomTabs, null,
                    React.createElement(CustomTab, { bgColor: activeTab === "Feat"
                            ? "deep-blue-500-transparent-10"
                            : "general-white", onClick: function () { return setActiveTab("Feat"); } },
                        React.createElement(Typography, { type: "text14", fontWeight: 600, color: activeTab === "Feat"
                                ? "deep-blue-500"
                                : "black-400" }, "Feat")),
                    React.createElement(CustomTab, { bgColor: activeTab === "Crypto"
                            ? "deep-blue-500-transparent-10"
                            : "general-white", onClick: function () { return setActiveTab("Crypto"); } },
                        React.createElement(Typography, { type: "text14", fontWeight: 600, color: activeTab === "Crypto"
                                ? "deep-blue-500"
                                : "black-400" }, "Crypto"))),
                React.createElement(Button, { size: "xs", onClick: function () { return setAddAddress(true); } }, activeTab === "Feat"
                    ? "Add Bank Details"
                    : "Add address")),
            React.createElement(AddressesTable, { rows: tableData !== null && tableData !== void 0 ? tableData : [], columns: activeTab === "Feat" ? featColumns : cryptoColumns }))));
};
var CustomTabs = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        display: "flex",
        padding: "2px",
        borderRadius: "100px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.colors["neutral-2"],
        transition: "color 0.2s ease",
        cursor: "pointer",
    });
});
var CustomTab = styled(Box)(function () { return ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
    fontWeight: 600,
    padding: "4px 12px",
    borderRadius: "22px",
    transition: "color 0.2s ease",
    cursor: "pointer",
}); });
export default UserAddresses;
