import React from "react";
import { styled } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { ROUTES } from "@/routes";
import { generateQueryPath } from "@/shared/utils/path";
import { SettingsMenu } from "@/pages/UserPage/constants";
var ProfileSidebar = function (_a) {
    var handleLogout = _a.handleLogout;
    var search = useSearchParams()[0];
    var type = search.get("type");
    return (React.createElement(SidebarContainer, null,
        SettingsMenu.map(function (link) { return (React.createElement(SidebarLink, { key: link.label, to: generateQueryPath(ROUTES.profile, {}, { type: link.to }), className: type === link.to ? "active" : "" },
            React.createElement(BaseIcon, { icon: link.icon, color: "neutral-3", size: 20 }),
            React.createElement(Typography, { type: "text14", color: "neutral-4", fontWeight: 600 }, link.label))); }),
        React.createElement(SidebarLogout, { onClick: handleLogout },
            React.createElement(BaseIcon, { icon: "Logout", color: "neutral-3", size: 20 }),
            React.createElement(Typography, { type: "text14", color: "neutral-4", fontWeight: 600 }, "Log out"))));
};
var SidebarContainer = styled(Box)(function () { return ({
    borderRadius: "8px",
    width: "191px",
    padding: "8px",
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.06), 0 4px 10px 0 rgba(0, 0, 0, 0.06), 0 2px 14px 0 rgba(0, 0, 0, 0.06), 0 6px 34px 0 rgba(0, 0, 0, 0.04)",
    background: "var(--white)",
}); });
var SidebarLink = styled(Link)(function (_a) {
    var theme = _a.theme;
    return ({
        display: "flex",
        gap: 12,
        fontSize: 14,
        padding: "12px 16px",
        borderRadius: 4,
        color: theme.colors["neutral-4"],
        transition: "color 0.2s ease",
        position: "relative",
        overflow: "hidden",
        "&:hover": {
            color: theme.colors.black,
            backgroundColor: theme.colors["black-50"],
        },
        "&.active": {
            backgroundColor: theme.colors["deep-blue-500-transparent-15"],
            "&::before": {
                position: "absolute",
                left: 0,
                top: 0,
                content: "\"\"",
                height: "100%",
                width: "2px",
                backgroundColor: theme.colors["deep-blue-500"],
            },
            "& > *": {
                color: theme.colors["deep-blue-500"],
                "& > svg": {
                    fill: theme.colors["deep-blue-500"],
                },
            },
        },
    });
});
var SidebarLogout = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        display: "flex",
        gap: 12,
        fontSize: 14,
        padding: "12px 16px",
        marginTop: 12,
        borderRadius: 4,
        color: theme.colors["neutral-4"],
        transition: "color 0.2s ease",
        cursor: "pointer",
        "&:hover": {
            color: theme.colors.black,
            backgroundColor: theme.colors["black-50"],
        },
    });
});
export default ProfileSidebar;
