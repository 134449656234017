var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material/styles";
import * as React from "react";
import { Button } from "../Button";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { Loader } from "@/shared/components/Loader";
import { Typography } from "@/shared/components/Typography";
export var AccountSelect = function (_a) {
    var _b = _a.disabled, disabled = _b === void 0 ? false : _b, icon = _a.icon, iconUri = _a.iconUri, name = _a.name, desc = _a.desc, from = _a.from, descLoading = _a.descLoading, onClick = _a.onClick, handleMax = _a.handleMax;
    return (React.createElement(Box, null, name ? (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "flex-end", gap: "6px" },
        React.createElement(Box, { display: "flex", alignItems: "center", gap: "6px" }, icon !== null && icon !== void 0 ? icon : (iconUri && React.createElement("img", { src: iconUri, width: 24, alt: "" })),
            React.createElement(Typography, { type: "text20", fontWeight: 600, color: "text" }, name),
            React.createElement(Button, { variant: "White", padding: "0", size: "xxs", disabled: disabled, onClick: onClick },
                React.createElement(BaseIcon, { icon: "DropDown", size: 20, color: "black-500" }))),
        descLoading ? (React.createElement(Loader, { size: 13, containerSize: 18 })) : desc ? (React.createElement(Box, { display: "flex", gap: "6px", alignItems: "center" },
            from && (React.createElement(Button, { variant: "White", padding: "0", size: "xxs", disabled: disabled, onClick: handleMax },
                React.createElement(Box, { borderRadius: "4px", px: "8px", bgColor: "deep-blue-500-transparent-10" },
                    React.createElement(Typography, { type: "text12", fontWeight: 500, color: "deep-blue-500" }, "MAX")))),
            React.createElement(Desc, { type: "text14", fontWeight: 500, color: "black-400", lineHeight: "18px" },
                "Balance: ",
                desc))) : null)) : (React.createElement(Button, { variant: "White", padding: "0", size: "xxs", disabled: disabled, onClick: onClick },
        React.createElement(BaseIcon, { icon: "DropDown", size: 20, color: "black-500" })))));
};
var Desc = styled(Typography)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    line-height: 18px;\n"], ["\n    line-height: 18px;\n"])));
var templateObject_1;
