var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material";
import * as React from "react";
import { Box, Typography } from "@/shared/components";
var InputWrapper = styled("div")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    gap: 0px;\n    flex-grow: 1;\n    transition: transform 0.2s ease;\n    transform: ", ";\n"], ["\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    gap: 0px;\n    flex-grow: 1;\n    transition: transform 0.2s ease;\n    transform: ", ";\n"])), function (props) { return (props.withError ? "translateY(-10px)" : "none"); });
var ErrorMsg = styled(Typography)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    opacity: ", ";\n    transition: opacity 0.2s ease;\n"], ["\n    opacity: ", ";\n    transition: opacity 0.2s ease;\n"])), function (props) { return (props.$visible ? 1 : 0); });
export var ExchangeField = function (_a) {
    var input = _a.input, value = _a.value, amount = _a.amount, select = _a.select, error = _a.error;
    return (React.createElement(Box, { display: "grid", gridTemplateColumns: "minmax(0,1fr) minmax(0,max-content)", alignItems: "center", bgColor: "general-white", borderRadius: "8px", borderWidth: "1px", borderStyle: "solid", borderColor: "neutral-2", padding: "12px 16px", gap: "20px" },
        React.createElement(InputWrapper, { withError: !!error },
            React.createElement(Box, null, input !== null && input !== void 0 ? input : (React.createElement(Typography, { type: "text32" }, value || "\u200B"))),
            React.createElement(Typography, { type: "text14", color: "neutral-3" }, amount),
            React.createElement(ErrorMsg, { "$visible": !!error, type: "text12", color: "general-red", fontWeight: 500, position: "absolute", top: "100%" }, error)),
        React.createElement(Box, null, select)));
};
var templateObject_1, templateObject_2;
