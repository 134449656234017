import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Outlet, generatePath, useNavigate } from "react-router-dom";
import { Header } from "@/pages/UserPage/components/Header";
import { SideMenu } from "@/pages/UserPage/components/SideMenu";
import { UserPageStore } from "@/pages/UserPage/stores/UserPageStore";
import { Box } from "@/shared/components";
import { useProvider, useStore, useStoreContext, } from "@/shared/hooks/useStore";
import { MenuStore } from "@/shared/stores/MenuStore";
import { ROUTES } from "@/routes";
import { AuthStore } from "@/shared/stores/AuthStore";
export var UserPage = observer(function () {
    var page = useStore(UserPageStore);
    var menu = useStoreContext(MenuStore);
    var Provider = useProvider(UserPageStore);
    var auth = useStoreContext(AuthStore);
    var navigate = useNavigate();
    return (React.createElement(Provider, { value: page },
        React.createElement(Box, { maxWidth: "1440px", margin: "0 auto" },
            React.createElement(Container, null,
                React.createElement(Header, null),
                React.createElement(Outlet, null))),
        menu.visible && (React.createElement(SideMenu, { handleLogout: function () {
                var _a;
                auth.logout();
                navigate(generatePath(ROUTES.signin));
                (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.reload();
            } }))));
});
var Container = styled("div")(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            position: "relative",
            boxSizing: "border-box",
            minHeight: "100vh",
            width: "100%",
            padding: "0 20px",
            margin: "0 auto"
        },
        _b[theme.breakpoints.down("sm")] = {
            paddingLeft: 20,
            paddingRight: 20,
        },
        _b);
});
