import * as React from "react";
import { Box } from "@/shared/components/Box";
import { Typography } from "@/shared/components/Typography";
export var Info = function (_a) {
    var data = _a.data, _b = _a.dark, dark = _b === void 0 ? true : _b;
    return (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "20px", width: "100%" }, data.map(function (item) { return (React.createElement(Box, { key: item.label, display: "flex", justifyContent: "space-between", width: "100%" },
        React.createElement(Typography, { type: "text14", color: "neutral-5" },
            item.label,
            ":"),
        React.createElement(Typography, { type: "text14", color: dark ? "general-white" : "neutral-5" }, item.value))); })));
};
