import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Header } from "@/pages/DepositPage/components/Header";
import { DepositType, ROUTES } from "@/routes";
import { Box, Button, Typography } from "@/shared/components";
import { BankAccountPopup } from "@/shared/components/BankAccountPopup";
import { BankIcon } from "@/shared/components/BankIcon";
import { DropdownInput } from "@/shared/components/DropdownInput";
import { OperationLayout } from "@/shared/components/OperationLayout";
import { useCopy } from "@/shared/hooks/useCopy";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { isBankAddress, isFedwireAddress, isSwiftAddress, } from "@/shared/utils/address";
import { dataToText, saveToPDF } from "@/shared/utils/save-to-pdf";
export var DepositFiat = observer(function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var params = useParams();
    var media = useMedia();
    var popup = useToggler();
    var navigate = useNavigate();
    var accountList = useStoreContext(AccountListStore);
    var addressList = useStoreContext(AddressListStore);
    var account = params.id ? accountList.fiatById[params.id] : undefined;
    var address = account
        ? (_a = addressList.data[account === null || account === void 0 ? void 0 : account.account]) === null || _a === void 0 ? void 0 : _a[account === null || account === void 0 ? void 0 : account.currency]
        : undefined;
    var bankData = isBankAddress(address) ? address : undefined;
    var fedwireData = isFedwireAddress(address) ? address : undefined;
    var swiftData = isSwiftAddress(address) ? address : undefined;
    var loading = params.id ? !address || !account : false;
    var info = [
        ["Beneficiary", (_b = bankData === null || bankData === void 0 ? void 0 : bankData.accountHolderName) !== null && _b !== void 0 ? _b : "—"],
        ["Bank account number", (_c = bankData === null || bankData === void 0 ? void 0 : bankData.accountNumber) !== null && _c !== void 0 ? _c : "—"],
        ["Beneficiary address", (_d = fedwireData === null || fedwireData === void 0 ? void 0 : fedwireData.bankAddress) !== null && _d !== void 0 ? _d : "—"],
        ["Bank name", (_e = bankData === null || bankData === void 0 ? void 0 : bankData.bankName) !== null && _e !== void 0 ? _e : "—"],
        [
            "Bank address",
            (_g = (_f = fedwireData === null || fedwireData === void 0 ? void 0 : fedwireData.bankAddress) !== null && _f !== void 0 ? _f : swiftData === null || swiftData === void 0 ? void 0 : swiftData.bankAddress) !== null && _g !== void 0 ? _g : "—",
        ],
    ];
    var copy = useCopy(dataToText(info));
    var savePDF = function () {
        saveToPDF(dataToText(info, 60), "austin_kimm.pdf");
    };
    React.useEffect(function () {
        addressList.sync(account);
    }, [account, addressList]);
    return (React.createElement(React.Fragment, null,
        popup.enabled && (React.createElement(BankAccountPopup, { selectedId: account === null || account === void 0 ? void 0 : account.account, onClose: popup.off, onSelect: function (id) {
                popup.off();
                navigate(generatePath(ROUTES.deposit, {
                    id: id,
                    type: DepositType.Fiat,
                }), {
                    replace: true,
                });
            } })),
        React.createElement(OperationLayout, { loading: loading, main: React.createElement(React.Fragment, null,
                React.createElement(Header, { active: DepositType.Fiat }),
                React.createElement(DropdownInput, { loading: loading, onClick: popup.on }, account && (React.createElement(React.Fragment, null,
                    React.createElement(BankIcon, { symbol: account.currency, size: 36 }),
                    React.createElement(Typography, { type: "text16" }, account.shortName),
                    React.createElement(Typography, { type: "text16" }, (_h = bankData === null || bankData === void 0 ? void 0 : bankData.accountNumber) !== null && _h !== void 0 ? _h : "\u200b"))))), card: account && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "40px" },
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px", width: "100%" },
                    React.createElement(Box, { display: "flex", flexDirection: "column" },
                        React.createElement(Typography, { type: "text14", color: "black-400" }, "Beneficiary"),
                        React.createElement(Typography, { type: "text16", color: media.desktop
                                ? "general-white"
                                : undefined }, (_j = bankData === null || bankData === void 0 ? void 0 : bankData.accountHolderName) !== null && _j !== void 0 ? _j : "\u200b")),
                    React.createElement(Box, { display: "flex", flexDirection: "column" },
                        React.createElement(Typography, { type: "text14", color: "black-400" }, "Bank account number"),
                        React.createElement(Typography, { type: "text16", color: media.desktop
                                ? "general-white"
                                : undefined }, (_k = bankData === null || bankData === void 0 ? void 0 : bankData.accountNumber) !== null && _k !== void 0 ? _k : "\u200b")),
                    (fedwireData || swiftData) && (React.createElement(Box, { display: "flex", flexDirection: "column" },
                        React.createElement(Typography, { type: "text14", color: "black-400" }, "Beneficiary address"),
                        React.createElement(Typography, { type: "text16", color: media.desktop
                                ? "general-white"
                                : undefined }, (_m = (_l = swiftData === null || swiftData === void 0 ? void 0 : swiftData.bankAddress) !== null && _l !== void 0 ? _l : fedwireData === null || fedwireData === void 0 ? void 0 : fedwireData.bankAddress) !== null && _m !== void 0 ? _m : "\u200b"))),
                    React.createElement(Box, { display: "flex", flexDirection: "column" },
                        React.createElement(Typography, { type: "text14", color: "black-400" }, "Bank name"),
                        React.createElement(Typography, { type: "text16", color: media.desktop
                                ? "general-white"
                                : undefined }, (_o = bankData === null || bankData === void 0 ? void 0 : bankData.bankName) !== null && _o !== void 0 ? _o : "\u200b"))),
                React.createElement(Box, { width: "100%", display: media.desktop ? "grid" : "flex", flexDirection: media.desktop ? undefined : "column", alignItems: media.desktop ? "center" : "stretch", gridAutoFlow: "column", gridAutoColumns: "minmax(0, 1fr)", gap: media.desktop ? "30px" : "10px", whiteSpace: "nowrap" },
                    React.createElement(Button, { variant: "LightBlue", size: "s", onClick: copy }, "Copy details"),
                    React.createElement(Button, { variant: "LightBlue", size: "s", onClick: savePDF }, "Save details in PDF")))) })));
});
