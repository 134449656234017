var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { observer } from "mobx-react-lite";
import * as React from "react";
import { action, runInAction } from "mobx";
import { Box } from "@/shared/components/Box";
import { Popup } from "@/shared/components/Popup";
import { Typography } from "@/shared/components/Typography";
import { Button, FormControlInput } from "@/shared/components";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { TransferCryptoFormStore } from "@/pages/TransferPage/stores/TransferCryptoFormStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { AuthStore } from "@/shared/stores/AuthStore";
import { onChange } from "@/shared/utils/input";
export var WithdrawPopup = observer(function (_a) {
    var _b, _c;
    var onClose = _a.onClose, onError = _a.onError, onSuccess = _a.onSuccess, selectedToken = _a.selectedToken;
    var currencyList = useStoreContext(CurrencyListStore);
    var accountList = useStoreContext(AccountListStore);
    var balanceList = useStoreContext(BalanceListStore);
    var auth = useStoreContext(AuthStore);
    var cryptoFormStore = useStore(TransferCryptoFormStore, balanceList, accountList, auth, (_c = (_b = currencyList.list.find(function (_) { var _a; return selectedToken.toUpperCase() === ((_a = _.shortName) === null || _a === void 0 ? void 0 : _a.toUpperCase()); })) === null || _b === void 0 ? void 0 : _b.slug) !== null && _c !== void 0 ? _c : "");
    var chainOptions = React.useMemo(function () {
        var tokenData = currencyList.list.find(function (_) { return _.slug === selectedToken; });
        if (!tokenData)
            return [];
        if (!(tokenData === null || tokenData === void 0 ? void 0 : tokenData.tokens))
            return [];
        var list = tokenData.tokens
            .filter(function (_) {
            var _a;
            if (!_.id || !_.name)
                return false;
            if (!((_a = _.settings) === null || _a === void 0 ? void 0 : _a.withdrawEnabled))
                return false;
            return true;
        })
            .map(function (_) {
            var _a, _b, _c, _d;
            return ({
                key: ((_a = _.blockchain) === null || _a === void 0 ? void 0 : _a.slug) ? (_b = _.blockchain) === null || _b === void 0 ? void 0 : _b.slug : "",
                label: (_d = (_c = _.blockchain) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : "",
            });
        });
        return list;
    }, [currencyList, selectedToken]);
    var WithdrawCurrencies = React.useMemo(function () {
        var _a, _b, _c, _d;
        var tokenData = currencyList.list.find(function (_) { return _.slug === selectedToken; });
        if (!tokenData)
            return [];
        return [
            {
                key: (_b = (_a = tokenData === null || tokenData === void 0 ? void 0 : tokenData.id) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : "",
                label: (_c = tokenData === null || tokenData === void 0 ? void 0 : tokenData.shortName) !== null && _c !== void 0 ? _c : "",
                customIcon: (_d = tokenData === null || tokenData === void 0 ? void 0 : tokenData.slug) === null || _d === void 0 ? void 0 : _d.toUpperCase(),
            },
        ];
    }, [selectedToken, currencyList.list]);
    console.log(chainOptions, "__chainOptions");
    // const selectedChain = React.useMemo(() => {
    //     if (!cryptoFormStore.blockchain || !chainOptions.length) return;
    //     const currChain = chainOptions.find(
    //         (_) => _.key === cryptoFormStore.blockchain,
    //     );
    //     runInAction(() => {
    //         cryptoFormStore.from = currChain?.label;
    //         cryptoFormStore.currency = currChain?.key;
    //     });
    //     // eslint-disable-next-line consistent-return
    //     return currChain;
    // }, [cryptoFormStore, chainOptions]);
    var submitWith = function () { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, cryptoFormStore.submit()];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    onError("Withdraw error");
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        if (!cryptoFormStore.withdrawData)
            return;
        onSuccess(cryptoFormStore.withdrawData);
    }, [cryptoFormStore.withdrawData, onSuccess]);
    return (React.createElement(Popup, { onClose: onClose, width: "406px" },
        React.createElement(Box, { display: "flex", justifyContent: "center", marginBottom: "24px" },
            React.createElement(Typography, { type: "text20", fontWeight: 600 }, "Withdrawal")),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "24px" },
            React.createElement(FormControlSelect, { value: cryptoFormStore.blockchain, onChange: function (_, option) {
                    runInAction(function () {
                        cryptoFormStore.blockchain = option === null || option === void 0 ? void 0 : option.key;
                    });
                }, customIcon: cryptoFormStore.blockchain, errorPosition: "relative", options: chainOptions, label: "Blockchain", placeholder: "Select blockchain" }),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "2px" },
                React.createElement(Box, { display: "flex", alignItems: "end", gap: "4px" },
                    React.createElement(FormControlInput, { type: "number", hiddenLabel: true, width: "100%", outLabel: "Amount", placeholder: "0", value: cryptoFormStore.amount, onChange: onChange(action(function (value) {
                            cryptoFormStore.amount = value;
                        })), 
                        // error={settingsStore.errorsByKey.authenticator?.[0]}
                        variantError: "alert" }),
                    React.createElement(Box, { width: "70%" },
                        React.createElement(FormControlSelect, { value: WithdrawCurrencies[0].key, customIcon: selectedToken.toUpperCase(), options: WithdrawCurrencies })))),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "8px" },
                React.createElement(FormControlInput, { type: "text", hiddenLabel: true, outLabel: "Crypto address", placeholder: "Your address", width: "100%", value: cryptoFormStore.to, onChange: onChange(action(function (value) {
                        cryptoFormStore.to = value;
                    })), 
                    // error={settingsStore.errorsByKey.authenticator?.[0]}
                    variantError: "alert" })),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "8px" },
                React.createElement(Button, { size: "s", disabled: cryptoFormStore.loading, loading: cryptoFormStore.loading, onClick: submitWith }, "Withdrawal"),
                React.createElement(Button, { size: "s", variant: "LightBlue", onClick: onClose }, "Back")))));
});
