import React from "react";
import { Box, Typography } from "@/shared/components";
export var TransactionStatus = function (_a) {
    var status = _a.status;
    var getColor = function (status) {
        switch (status) {
            case "pending":
                return {
                    text: "secondary-blue",
                    bg: "secondary-blue-light",
                };
            case "declined":
                return {
                    text: "negative",
                    bg: "general-red-transparent-10",
                };
            case "canceled":
                return {
                    text: "neutral-5",
                    bg: "black-100",
                };
            default:
                return {
                    text: "general-green",
                    bg: "light-green",
                };
        }
    };
    var statusColors = getColor(status);
    if (!status) {
        return (React.createElement(Box, { width: "50%", display: "flex", justifyContent: "center", alignContent: "center" }, "-"));
    }
    return (React.createElement(Box, { paddingY: "4px", paddingX: "10px", borderRadius: "27px", display: "flex", justifyContent: "center", alignContent: "center", bgColor: statusColors.bg },
        React.createElement(Typography, { type: "text12", fontWeight: "500", color: statusColors.text }, status.toUpperCase())));
};
