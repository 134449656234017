var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { keyframes, styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, Link, useLocation, useNavigate, useSearchParams, } from "react-router-dom";
import { HeaderMobileLinks } from "../../constants";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { Overlay } from "@/shared/components/Overlay";
import { useStoreContext } from "@/shared/hooks/useStore";
import { MenuStore } from "@/shared/stores/MenuStore";
import { ROUTES } from "@/routes";
export var SideMenu = observer(function (_a) {
    var handleLogout = _a.handleLogout;
    var menu = useStoreContext(MenuStore);
    var location = useLocation();
    var navigate = useNavigate();
    var search = useSearchParams()[0];
    var path = location.pathname;
    var type = search.get("type");
    // TODO: rework
    var isActiveLink = function (link) {
        if (link === "wallet" && path === "/") {
            return true;
        }
        return path.includes(link) || (type === null || type === void 0 ? void 0 : type.includes(link));
    };
    React.useEffect(function () {
        menu.open();
    }, [menu, location.pathname]);
    return (React.createElement(Overlay, null,
        React.createElement(Wrapper, null,
            React.createElement(Box, { display: "flex", flexDirection: "column" },
                React.createElement(Box, { height: 72, display: "flex", alignItems: "center", justifyContent: "space-between", mb: "16px" },
                    React.createElement(Logo, { to: generatePath(ROUTES.root) },
                        React.createElement(BaseIcon, { icon: "Logo", size: 60 })),
                    React.createElement(BaseIcon, { icon: "Cross", size: 20, containerSize: 26, onClick: menu.close, alignSelf: "flex-center" })),
                React.createElement(List, null, HeaderMobileLinks.map(function (props) { return (React.createElement(LinkItem, { onClick: function () {
                        navigate(generatePath(props.link));
                        menu.close();
                    }, key: props.label, className: isActiveLink(props.active) ? "active" : "" },
                    React.createElement(BaseIcon, { icon: props.icon, size: 24 }),
                    props.label)); })),
                React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "1px", bgColor: "black-200", my: 6 }),
                React.createElement(SidebarLogout, { onClick: handleLogout },
                    React.createElement(BaseIcon, { icon: "Logout", color: "neutral-3", size: 24 }),
                    React.createElement(Typography, { type: "text14", color: "neutral-4" }, "Log out"))))));
});
var slide = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    from {\n        opacity: 0;\n        transform: translateX(50px);\n    }\n    to {\n        opacity: 1;\n        transform: none;\n    }\n"], ["\n    from {\n        opacity: 0;\n        transform: translateX(50px);\n    }\n    to {\n        opacity: 1;\n        transform: none;\n    }\n"])));
var Wrapper = styled("div")(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    gap: 60px;\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n    box-sizing: border-box;\n    padding: 0 20px;\n    position: relative;\n    background: ", ";\n    min-height: 100vh;\n    animation: ", " 0.25s;\n    flex-grow: 1;\n"], ["\n    gap: 60px;\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n    box-sizing: border-box;\n    padding: 0 20px;\n    position: relative;\n    background: ", ";\n    min-height: 100vh;\n    animation: ", " 0.25s;\n    flex-grow: 1;\n"])), function (props) { return props.theme.colors["black-50"]; }, slide);
var List = styled("div")(function () { return ({
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: 24,
}); });
var Logo = styled(Link)(function () { return ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}); });
var LinkItem = styled("div")(function (_a) {
    var theme = _a.theme;
    return ({
        display: "flex",
        gap: 6,
        fontSize: 14,
        color: theme.colors["neutral-4"],
        alignItems: "center",
        justifyContent: "center",
        transition: "color 0.2s ease",
        cursor: "pointer",
        "&:hover": {
            color: theme.colors.black,
        },
        "&.active": {
            color: theme.colors.black,
        },
    });
});
var SidebarLogout = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        display: "flex",
        gap: 6,
        fontSize: 14,
        color: theme.colors["neutral-4"],
        transition: "color 0.2s ease",
        cursor: "pointer",
        "&:hover": {
            color: theme.colors.black,
        },
    });
});
var templateObject_1, templateObject_2;
