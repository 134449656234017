import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link, NavLink, generatePath } from "react-router-dom";
import { styled } from "@mui/material";
import { HeaderLinks } from "../../constants";
import { BaseIcon, Box } from "@/shared/components";
import { ROUTES } from "@/routes";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { MenuStore } from "@/shared/stores/MenuStore";
export var Header = observer(function () {
    var phone = useMedia().phone;
    var menu = useStoreContext(MenuStore);
    return (React.createElement(Box, { height: 72, display: "flex", alignItems: "center", justifyContent: "space-between", gap: "22px", borders: ["bottom"], borderColor: "input-border", borderWidth: "1px", borderStyle: "solid", bgColor: "general-white", position: "sticky", top: "0", left: "0", zIndex: 100 },
        React.createElement(Logo, { to: generatePath(ROUTES.root) },
            React.createElement(BaseIcon, { icon: "Logo", size: 160 })),
        phone ? (React.createElement(Box, { display: "inline-flex", alignItems: "center", justifyContent: "center" },
            React.createElement(BaseIcon, { icon: "Burger", size: 22, onClick: menu.open }))) : (React.createElement(List, null, HeaderLinks.map(function (props) { return (React.createElement(LinkItem, { to: generatePath(props.link), key: props.label },
            React.createElement(BaseIcon, { icon: props.icon, size: 24 }),
            props.label)); })))));
});
var List = styled("div")(function () { return ({
    display: "flex",
    alignItems: "center",
    gap: 32,
}); });
var Logo = styled(Link)(function () { return ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}); });
var LinkItem = styled(NavLink)(function (_a) {
    var theme = _a.theme;
    return ({
        display: "flex",
        gap: 6,
        fontSize: 14,
        color: theme.colors["neutral-4"],
        alignItems: "center",
        justifyContent: "center",
        transition: "color 0.2s ease",
        "&:hover": {
            color: theme.colors.black,
        },
        "&.active": {
            color: theme.colors.black,
        },
    });
});
