var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { TransactionAmount } from "../TransactionAmount";
import { TransactionInfo } from "../TransactionInfo";
import { TransactionStatus } from "../TransactionStatus";
import { useGetHistoryQuery } from "@/api/wallet";
import { Table, Typography } from "@/shared/components";
import { DATE_FORMAT, formatDate } from "@/shared/lib";
import { useMedia } from "@/shared/hooks/useMedia";
import { sliceAddress } from "@/shared/utils/slice-address";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
export var Transactions = function (_a) {
    var _b = _a.accountIds, accountIds = _b === void 0 ? [] : _b, tableProps = _a.tableProps;
    var currencyList = useStoreContext(CurrencyListStore);
    var desktop = useMedia().desktop;
    var _c = useState(null), transaction = _c[0], setTransaction = _c[1];
    var _d = useState(0), page = _d[0], setPage = _d[1];
    var _e = useGetHistoryQuery({
        accountIds: accountIds,
        page: page,
    }), transfers = _e.data, isLoading = _e.isLoading;
    console.log("🚀 ~ data:", transfers);
    // const getBenicifiaryInfo = (type: BalanceLogReasonType, source: string) => {
    //     if (
    //         type === BalanceLogReasonType.FIAT_WITHDRAWAL_REJECT ||
    //         type === BalanceLogReasonType.FIAT_WITHDRAWAL_REQUEST
    //     ) {
    //         const info = JSON.parse(source).state.details;
    //         if (info.wType === "BANK_TRANSFER") {
    //             return (
    //                 <Typography
    //                     type="text14"
    //                     whiteSpace="normal"
    //                     color="black-400"
    //                 >
    //                     {info.beneficiaryName}/{info.beneficiaryAccountNumber}
    //                 </Typography>
    //             );
    //         }
    //     }
    //     return null;
    // };
    function parseStatus(str) {
        var _a, _b;
        var source = JSON.parse(str);
        return (_b = (_a = source === null || source === void 0 ? void 0 : source.value) === null || _a === void 0 ? void 0 : _a.status) === null || _b === void 0 ? void 0 : _b.toLowerCase();
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, __assign({ pagination: true, rows: (transfers === null || transfers === void 0 ? void 0 : transfers.data) || [], loading: isLoading, columns: desktop
                ? [
                    {
                        justifyContent: "end",
                        field: "addressee",
                        headerName: "Transaction ID",
                        resizable: false,
                        sortable: false,
                        flex: 1,
                        renderCell: function (_a) {
                            var row = _a.row;
                            return (React.createElement(Typography, { type: "text14" }, sliceAddress(row.id)));
                        },
                    },
                    {
                        field: "direction",
                        headerName: "Direction",
                        resizable: false,
                        sortable: true,
                        flex: 1,
                        renderCell: function (_a) {
                            var row = _a.row;
                            return (React.createElement(Typography, { type: "text14" }, row.operation));
                        },
                    },
                    {
                        field: "amount",
                        headerName: "Amount",
                        resizable: false,
                        sortable: true,
                        flex: 1,
                        renderCell: function (_a) {
                            var row = _a.row;
                            return (React.createElement(TransactionAmount, { status: row.status, amount: row.change, currency: row.currency, text: "text14" }));
                        },
                    },
                    {
                        field: "currency",
                        headerName: "Currency",
                        resizable: false,
                        sortable: true,
                        flex: 1,
                        renderCell: function (_a) {
                            var _b;
                            var row = _a.row;
                            return (React.createElement(Typography, { type: "text14" }, (_b = currencyList.list.find(function (_) { var _a; return ((_a = _.slug) === null || _a === void 0 ? void 0 : _a.toUpperCase()) === row.currency; })) === null || _b === void 0 ? void 0 : _b.shortName));
                        },
                    },
                    {
                        field: "status",
                        headerName: "Status",
                        sortable: true,
                        resizable: false,
                        flex: 1,
                        renderCell: function (_a) {
                            var row = _a.row;
                            return (React.createElement(TransactionStatus, { status: parseStatus(row.source) }));
                        },
                    },
                    {
                        field: "createdAt",
                        headerName: "Date",
                        sortable: true,
                        resizable: false,
                        flex: 1,
                        renderCell: function (_a) {
                            var value = _a.value;
                            return (React.createElement(Typography, { type: "text14" }, formatDate(value, DATE_FORMAT.date_with_time)));
                        },
                    },
                ]
                : [
                    {
                        justifyContent: "end",
                        field: "addressee",
                        headerName: "Transaction ID",
                        resizable: false,
                        sortable: false,
                        flex: 1,
                        renderCell: function (_a) {
                            var row = _a.row;
                            return (React.createElement(Typography, { type: "text14" }, sliceAddress(row.id)));
                        },
                    },
                    {
                        field: "direction",
                        headerName: "Direction",
                        resizable: false,
                        sortable: true,
                        flex: 1,
                        renderCell: function (_a) {
                            var row = _a.row;
                            return (React.createElement(Typography, { type: "text14" }, row.operation));
                        },
                    },
                    {
                        field: "amount",
                        headerName: "Amount",
                        resizable: false,
                        sortable: true,
                        flex: 1,
                        renderCell: function (_a) {
                            var row = _a.row;
                            return (React.createElement(TransactionAmount, { status: row.status, amount: row.change, currency: row.currency, text: "text14" }));
                        },
                    },
                ], getRowId: function (row) { return row.id; }, onRowClick: function (_a) {
                var row = _a.row;
                return setTransaction(row);
            }, initialState: {
                pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                },
                sorting: {
                    sortModel: [{ field: "createdAt", sort: "desc" }],
                },
            }, page: page, paginationMode: "server", totalPages: transfers === null || transfers === void 0 ? void 0 : transfers.totalPages, totalItems: transfers === null || transfers === void 0 ? void 0 : transfers.totalItems, onPageChange: function (page) { return setPage(page); } }, tableProps)),
        React.createElement(TransactionInfo, { transaction: transaction, onClose: function () { return setTransaction(null); } })));
};
