import { makeAutoObservable } from "mobx";
import { IndividualType } from "@/api/registration/types";
var KybFormStore = /** @class */ (function () {
    function KybFormStore(companyInfo, individualList, companyVerification, individualVerificationList) {
        this.companyInfo = companyInfo;
        this.individualList = individualList;
        this.companyVerification = companyVerification;
        this.individualVerificationList = individualVerificationList;
        this.loading = false;
        this.agree = false;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    Object.defineProperty(KybFormStore.prototype, "form1Success", {
        get: function () {
            var _a, _b, _c, _d, _e, _f;
            return !!((_b = (_a = this.companyInfo.companyInfo) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.firstName)
                && !!((_d = (_c = this.companyInfo.companyInfo) === null || _c === void 0 ? void 0 : _c.meta) === null || _d === void 0 ? void 0 : _d.lastName)
                && !!((_e = this.companyInfo.companyInfo) === null || _e === void 0 ? void 0 : _e.phone)
                && !!((_f = this.companyInfo.companyInfo) === null || _f === void 0 ? void 0 : _f.email);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(KybFormStore.prototype, "form2Success", {
        get: function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            return !!((_a = this.companyInfo.companyInfo) === null || _a === void 0 ? void 0 : _a.companyName)
                && !!((_c = (_b = this.companyInfo.companyInfo) === null || _b === void 0 ? void 0 : _b.meta) === null || _c === void 0 ? void 0 : _c.entityType)
                && !!((_e = (_d = this.companyInfo.companyInfo) === null || _d === void 0 ? void 0 : _d.meta) === null || _e === void 0 ? void 0 : _e.naics)
                && !!((_g = (_f = this.companyInfo.companyInfo) === null || _f === void 0 ? void 0 : _f.meta) === null || _g === void 0 ? void 0 : _g.naicsDescription)
                && !!((_j = (_h = this.companyInfo.companyInfo) === null || _h === void 0 ? void 0 : _h.meta) === null || _j === void 0 ? void 0 : _j.descriptionOfBusinessNature);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(KybFormStore.prototype, "form3Success", {
        get: function () {
            var _a, _b, _c;
            return !!((_a = this.companyInfo.companyInfo) === null || _a === void 0 ? void 0 : _a.registrationNumber)
                && !!((_c = (_b = this.companyInfo.companyInfo) === null || _b === void 0 ? void 0 : _b.meta) === null || _c === void 0 ? void 0 : _c.registeredCountryCode);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(KybFormStore.prototype, "form4Success", {
        get: function () {
            var _a, _b;
            return !!((_b = (_a = this.companyInfo.companyInfo) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.primarySourceOfFunds);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(KybFormStore.prototype, "form5Success", {
        get: function () {
            return this.individualList.list.length > 0
                && !!this.individualList.list.find(function (item) {
                    return item.individualType === IndividualType.BENEFICIAL_OWNER
                        || item.individualType === IndividualType.CONTROL_PERSON;
                });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(KybFormStore.prototype, "form6Success", {
        get: function () {
            return this.form5Success
                && this.companyVerification.success
                && this.individualVerificationList.success;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(KybFormStore.prototype, "success", {
        get: function () {
            return this.form1Success
                && this.form2Success
                && this.form3Success
                && this.form4Success
                && this.form5Success
                && this.form6Success;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(KybFormStore.prototype, "ready", {
        get: function () {
            return this.companyInfo.ready
                && this.individualList.ready
                && this.companyVerification.ready
                && this.individualVerificationList.ready;
        },
        enumerable: false,
        configurable: true
    });
    return KybFormStore;
}());
export { KybFormStore };
