var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material/styles";
import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useGetRecipients } from "@/api/wallet";
import { ROUTES } from "@/routes";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { Popup } from "@/shared/components/Popup";
import { PopupItem } from "@/shared/components/Popup/Item";
import { PopupHead } from "@/shared/components/Popup/Head";
import { RecipientItem } from "@/shared/components/RecipientPopup/RecipientItem";
import { Typography } from "@/shared/components/Typography";
export var RecipientPopup = function (_a) {
    var onClose = _a.onClose, selectedId = _a.selectedId, onSelect = _a.onSelect;
    var navigate = useNavigate();
    var data = useGetRecipients().data;
    return (React.createElement(Popup, { onClose: onClose },
        React.createElement(PopupHead, { onClose: onClose }, "Select recipient"),
        React.createElement(Box, { height: "1px", bgColor: "black-50" }),
        React.createElement(Box, { overflow: "hidden", borderRadius: "0 0 22px 22px" },
            React.createElement(List, null,
                React.createElement(PopupItem, { onClick: function () { return navigate(generatePath(ROUTES.recipientCreate)); } },
                    React.createElement(BaseIcon, { icon: "Plus", size: 14, containerSize: 36, borderRadius: "36px", bgColor: "light-blue", color: "deep-blue-500" }),
                    React.createElement(Typography, { type: "text14", color: "deep-blue-500" }, "Add new recipient")), data === null || data === void 0 ? void 0 :
                data.map(function (item) { return (React.createElement(RecipientItem, { selected: item.id === selectedId, name: item.profile.name, address: item.accountNumber, onSelect: function () { return onSelect(item.id); } })); })))));
};
var List = styled("div")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    overflow: auto;\n    max-height: 505px;\n    scrollbar-width: none;\n\n    &::-webkit-scrollbar {\n        display: none;\n    }\n"], ["\n    overflow: auto;\n    max-height: 505px;\n    scrollbar-width: none;\n\n    &::-webkit-scrollbar {\n        display: none;\n    }\n"])));
var templateObject_1;
