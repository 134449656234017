var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { FORM_CONFIG, OTHER_CONFIG } from "../../constants/feilds";
import { CreateRecipientStore } from "../../store/CreateRecipientStore";
import { useGetCurrency } from "@/api/currency";
import { CounterpartyType } from "@/api/wallet/types";
import { useCreateRecipient } from "@/api/wallet/useCreateRecipient";
import { Box, Button, FormControlInput, Typography } from "@/shared/components";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { Tab, Tabs, TabsList } from "@/shared/components/Tabs";
import { countries } from "@/shared/constants/countries";
import { useStoreContext } from "@/shared/hooks/useStore";
import { RequiredFieldMessage } from "@/shared/utils/error";
export var CreateRecipientForm = function () {
    var setIsOpen = useStoreContext(CreateRecipientStore).setIsOpen;
    var _a = useForm(), control = _a.control, handleSubmit = _a.handleSubmit;
    var _b = useState("SWIFT"), tab = _b[0], setTab = _b[1];
    var data = useGetCurrency().data;
    var currencies = data === null || data === void 0 ? void 0 : data.filter(function (item) { return item.type === "FIAT"; });
    var queryClient = useQueryClient();
    var mutateAsync = useCreateRecipient({
        onSuccess: function () {
            setIsOpen(false);
            queryClient.invalidateQueries({ queryKey: ["walletApi.counterparty.getCounterparties"] });
        },
    }).mutateAsync;
    var country = useWatch({ control: control, name: "institutionAddress.country" }) || "USA";
    var config = FORM_CONFIG[country] || OTHER_CONFIG;
    var typeNumber = tab ? config.findIndex(function (item) { return item.type === tab; }) : 0;
    var recipientFields = config[typeNumber].recepient;
    var bankAccountFields = config[typeNumber].bank;
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            mutateAsync(__assign(__assign({}, data), { type: CounterpartyType.FIAT, rail: config[typeNumber].type, disabled: false }));
            return [2 /*return*/];
        });
    }); };
    return (React.createElement(Box, { width: "100%", mb: "100px" },
        React.createElement(Box, { display: "grid", gridTemplateColumns: "1fr 1fr", gap: "40px" },
            React.createElement(Typography, { type: "text32Bold", gridColumn: "1/-1" }, "Enter recipient's details"),
            recipientFields.map(function (item) {
                var field = null;
                if (item.type === "input") {
                    field = (React.createElement(Controller, { rules: {
                            required: {
                                value: true,
                                message: RequiredFieldMessage.description,
                            },
                        }, control: control, name: item.field, render: function (_a) {
                            var field = _a.field, error = _a.fieldState.error;
                            return (React.createElement(FormControlInput, __assign({ error: error === null || error === void 0 ? void 0 : error.message, label: item.label }, field)));
                        } }));
                }
                if (item.type === "select") {
                    field = (React.createElement(Controller, { rules: {
                            required: {
                                value: true,
                                message: RequiredFieldMessage.description,
                            },
                        }, control: control, name: item.field, render: function (_a) {
                            var field = _a.field, error = _a.fieldState.error;
                            return (React.createElement(FormControlSelect, __assign({}, field, { error: error === null || error === void 0 ? void 0 : error.message, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, options: item.options, label: item.label })));
                        } }));
                }
                return React.createElement(Box, { gridColumn: item.grid }, field);
            })),
        React.createElement(Box, { mt: "60px", mb: "60px", width: "100%", height: "1px", bgColor: "black-100" }),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px" },
            React.createElement(Typography, { type: "text32Bold", gridColumn: "1/-1" }, "Select bank account country"),
            React.createElement(Controller, { control: control, rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, name: "institutionAddress.country", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlSelect, __assign({}, field, { options: countries.map(function (item) { return ({
                            key: item.codeAlpha3,
                            label: "".concat(item.flag, " ").concat(item.name),
                        }); }), error: error === null || error === void 0 ? void 0 : error.message, onChange: function (_, v) {
                            field.onChange(v === null || v === void 0 ? void 0 : v.key);
                            setTab(FORM_CONFIG[v === null || v === void 0 ? void 0 : v.key][0].type);
                        }, label: "Country" })));
                } }),
            React.createElement(Controller, { rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, control: control, name: "currency", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlSelect, __assign({}, field, { error: error === null || error === void 0 ? void 0 : error.message, options: (currencies === null || currencies === void 0 ? void 0 : currencies.map(function (item) {
                            var _a;
                            return ({
                                key: item.slug,
                                label: (_a = item.slug) === null || _a === void 0 ? void 0 : _a.toUpperCase(),
                            });
                        })) || [], onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, label: "Currency" })));
                } })),
        React.createElement(Box, { mt: "60px", mb: "60px", width: "100%", height: "1px", bgColor: "black-100" }),
        React.createElement(Box, { display: "grid", gridTemplateColumns: "1fr 1fr", gap: "40px" },
            React.createElement(Typography, { type: "text32Bold", gridColumn: "1/-1" }, "Enter bank account details"),
            React.createElement(Box, null,
                React.createElement(Tabs, { value: tab, onChange: function (_, value) { return setTab(value); } },
                    React.createElement(TabsList, null, config.map(function (item) { return React.createElement(Tab, { value: item.type }, item.typeName); })))),
            bankAccountFields.map(function (item) {
                var field = null;
                if (item.type === "input") {
                    field = (React.createElement(Controller, { rules: {
                            required: {
                                value: true,
                                message: RequiredFieldMessage.description,
                            },
                        }, control: control, name: item.field, render: function (_a) {
                            var field = _a.field, error = _a.fieldState.error;
                            return (React.createElement(FormControlInput, __assign({ error: error === null || error === void 0 ? void 0 : error.message, label: item.label }, field)));
                        } }));
                }
                // if (item.type === "select") {
                //     field = (
                //         <Controller
                //             control={control}
                //             name={item.field}
                //             render={({ field }) => (
                //                 <FormControlSelect {...field}  onChange={(_, v) => field.onChange(v?.key)} options={item.options} label={item.label} />
                //             )}
                //         />
                //     )
                // }
                return React.createElement(Box, { gridColumn: item.grid }, field);
            })),
        React.createElement(Button, { width: "250px", mt: "100px", onClick: handleSubmit(onSubmit) }, "Save")));
};
