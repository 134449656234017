// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JD7UTRHPLOtWGee{font-family:"NEOPIXEL-Regular";font-size:46px;color:var(--deep-blue-500);text-align:center;margin-bottom:24px}`, "",{"version":3,"sources":["webpack://./src/pages/SigninPage/index.module.scss"],"names":[],"mappings":"AAAA,iBACI,8BAAA,CACA,cAAA,CACA,0BAAA,CACA,iBAAA,CACA,kBAAA","sourcesContent":[".formTitle {\n    font-family: \"NEOPIXEL-Regular\";\n    font-size: 46px;\n    color: var(--deep-blue-500);\n    text-align: center;\n    margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formTitle": `JD7UTRHPLOtWGee`
};
export default ___CSS_LOADER_EXPORT___;
