import { useQuery } from "@tanstack/react-query";
import { currencyApi } from "./api";
export var useGetCurrencyBySlug = function (slug) {
    return useQuery({
        queryKey: ["getCurrencyBySlug", slug],
        queryFn: function () { return currencyApi.currency.getCurrencyBySlug(slug === null || slug === void 0 ? void 0 : slug.toLowerCase()); },
        staleTime: 100000,
        gcTime: 100000,
        enabled: !!slug,
        select: function (data) {
            return data.data;
        },
    });
};
