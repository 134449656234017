import { observer } from "mobx-react-lite";
import React from "react";
import { useGetVerificationInfo } from "@/api/registration";
import { ExtraDocsStore } from "@/pages/CompanyInfoPage/store/ExtraDocsStore";
import { Box, Button, Typography } from "@/shared/components";
import { Upload } from "@/shared/components/Upload";
import { useProvider, useStore } from "@/shared/hooks/useStore";
export var UploadDocuments = observer(function () {
    var _a;
    var info = useGetVerificationInfo();
    var extraDocs = useStore(ExtraDocsStore);
    var ExtraDocsProvider = useProvider(ExtraDocsStore);
    return (React.createElement(ExtraDocsProvider, { value: extraDocs },
        React.createElement(Box, { bgColor: "general-white", borderRadius: "22px", padding: "60px", display: "flex", flexDirection: "column" },
            React.createElement(Box, { display: "flex", flexDirection: "column", maxWidth: "720px", gap: "30px" },
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
                    React.createElement(Typography, { type: "text24", fontWeight: 500 }, "Want to add more documents?"),
                    React.createElement(Typography, { color: "black-500" },
                        "Please, make sure that you upload relevant company documents. Allowed formats: pdf, doc, docx, jpeg, png and tiff.\u00A0",
                        React.createElement(Typography, null, "The total weight of files uploaded must not exceed 20 MB."))),
                extraDocs.items.map(function (item, index) {
                    var _a, _b, _c;
                    return (React.createElement(Upload
                    // eslint-disable-next-line react/no-array-index-key
                    , { 
                        // eslint-disable-next-line react/no-array-index-key
                        key: index, success: item.success, label: "Additional documents", disabled: extraDocs.loading, error: (_a = item.errors.at(0)) === null || _a === void 0 ? void 0 : _a.description, fileName: (_b = item.file) === null || _b === void 0 ? void 0 : _b.name, loading: extraDocs.loading, multiple: false, onRemove: item.success ? undefined : function () { return extraDocs.remove(index); }, size: (_c = item.file) === null || _c === void 0 ? void 0 : _c.size, onFiles: function (_a) {
                            var file = _a[0];
                            return extraDocs.addFile(file, index);
                        } }));
                })),
            React.createElement(Box, { mt: "10px", display: "flex", justifyContent: "flex-end", maxWidth: "720px" },
                React.createElement(Typography, { color: "deep-blue-500", onClick: extraDocs.addItem }, "+ Add new document")),
            React.createElement(Button, { width: "250px", mt: "60px", disabled: !((_a = info.data) === null || _a === void 0 ? void 0 : _a.id) || extraDocs.loading, onClick: function () { return extraDocs.upload(info.data.id); } }, "Upload"))));
});
