import { observer } from "mobx-react-lite";
import * as React from "react";
import { Outlet } from "react-router-dom";
import styles from "./index.module.scss";
import { AuthPageStore } from "@/pages/AuthPage/stores/AuthPageStore";
import { useProvider, useStore } from "@/shared/hooks/useStore";
import CircleAuth from "@/shared/icons/circle-auth.svg";
export var AuthPage = observer(function () {
    var page = useStore(AuthPageStore);
    var PageProvider = useProvider(AuthPageStore);
    return (React.createElement(PageProvider, { value: page },
        React.createElement("div", { className: styles.rootAfter },
            React.createElement(CircleAuth, { className: styles.rootAfterSvg })),
        React.createElement("div", { className: styles.root },
            React.createElement("div", { className: styles.side },
                React.createElement(Outlet, null)))));
});
