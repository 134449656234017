var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { observer } from "mobx-react-lite";
import * as React from "react";
import { action } from "mobx";
import { QRCodeSVG } from "qrcode.react";
import { onChange } from "@/shared/utils/input";
import { Box } from "@/shared/components/Box";
import { Popup } from "@/shared/components/Popup";
import { Typography } from "@/shared/components/Typography";
import { useCopy } from "@/shared/hooks/useCopy";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
import { UserSettingsStore } from "@/pages/ProfilePage/stores/UserSettingsStore";
import { Button, FormControlInput } from "@/shared/components";
import { registrationApi } from "@/api/registration";
import { TwoFactorType, } from "@/api/registration/types";
import { CLIENT_ID } from "@/config";
import { AuthStore } from "@/shared/stores/AuthStore";
import { sliceAddress } from "@/shared/utils/slice-address";
import { tokenStorage } from "@/api/tokenStorage";
export var TwoFactorAuthPopup = observer(function (_a) {
    var _b, _c, _d, _e;
    var onClose = _a.onClose;
    var auth = useStoreContext(AuthStore);
    var settingsStore = useStore(UserSettingsStore, auth);
    var _f = React.useState(), createTwoFAData = _f[0], setCreateTwoFAData = _f[1];
    // const mfa = useStoreContext(MfaStore);
    var copy = useCopy((_b = createTwoFAData === null || createTwoFAData === void 0 ? void 0 : createTwoFAData.barcodeUri) !== null && _b !== void 0 ? _b : "");
    React.useEffect(function () {
        var asyncFn = function () { return __awaiter(void 0, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, registrationApi.mfa.createTwoFactorAuthentication({
                            type: TwoFactorType.Otp,
                        })];
                    case 1:
                        data = _a.sent();
                        setCreateTwoFAData(data.data);
                        console.log("🚀 ~ React.useEffect ~ data:", data);
                        return [2 /*return*/];
                }
            });
        }); };
        asyncFn();
    }, []);
    var handleChallenge = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, challengeResp, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    accessToken = tokenStorage.getAccessToken();
                    return [4 /*yield*/, registrationApi.auth.getToken({
                            grant_type: "mfa-otp",
                            client_id: CLIENT_ID,
                            mfaToken: accessToken !== null && accessToken !== void 0 ? accessToken : "",
                            otp: settingsStore.authenticator,
                        })];
                case 1:
                    challengeResp = _a.sent();
                    console.log("🚀 ~ handleChallenge ~ challengeResp:", challengeResp);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.log("🚀 ~ handleChallenge ~ e:", e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Popup, { onClose: onClose, width: "406px" },
        React.createElement(Box, { display: "flex", justifyContent: "center", marginBottom: "16px" },
            React.createElement(Typography, { type: "text20", fontWeight: 600 }, "Two-Factor Authentication")),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "16px" },
            React.createElement(Typography, { type: "text14" },
                "The first step is to download Google Authenticator app for your Android or iOS device. If you need help getting started, please see",
                " ",
                React.createElement("a", { href: "https://support.google.com/" }, "Google\u2019s Support Page.")),
            React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "12px", bgColor: "neutral-1", padding: "16px", borderRadius: "8px" },
                React.createElement(Typography, null, "Scan the QR Code or enter the token"),
                React.createElement(QRCodeSVG, { value: (_c = createTwoFAData === null || createTwoFAData === void 0 ? void 0 : createTwoFAData.barcodeUri) !== null && _c !== void 0 ? _c : "" }),
                React.createElement(Box, { display: "flex", alignItems: "start", gap: "10px" },
                    React.createElement(Typography, { type: "text14" },
                        "Account token (key):",
                        " ",
                        sliceAddress((_d = createTwoFAData === null || createTwoFAData === void 0 ? void 0 : createTwoFAData.barcodeUri) !== null && _d !== void 0 ? _d : "")),
                    React.createElement(Typography, { type: "text14Bold", color: "deep-blue-500", onClick: copy }, "Copy")),
                React.createElement(Typography, { type: "text14", color: "text-secondary" }, "The token will not be shown again after 2FA is enabled. If you have multiple devices, add your account token to all of them before clicking enable.")),
            React.createElement(FormControlInput, { type: "text", hiddenLabel: true, outLabel: "Google Authenticator Code", placeholder: "Check your 2FA app", width: "100%", error: (_e = settingsStore.errorsByKey.authenticator) === null || _e === void 0 ? void 0 : _e[0], variantError: "alert", disabled: settingsStore.loading, value: settingsStore.authenticator, onChange: onChange(action(function (value) {
                    settingsStore.authenticator = value;
                })) }),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "8px" },
                React.createElement(Button, { size: "s", disabled: !settingsStore.isAuthenticatorValid ||
                        settingsStore.loading, onClick: handleChallenge }, "Enable"),
                React.createElement(Button, { size: "s", variant: "LightBlue", onClick: onClose }, "Close")))));
});
