import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath } from "react-router-dom";
import styles from "./index.module.scss";
import { AuthPageStore } from "@/pages/AuthPage/stores/AuthPageStore";
import { SignupPageStore } from "@/pages/SignupPage/stores/SignupPageStore";
import { ROUTES } from "@/routes";
import { Box, FormControlCheckbox, FormControlInput, } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { Button } from "@/shared/components/Button";
import { Link } from "@/shared/components/Link";
import { PasswordHint } from "@/shared/components/PasswordHint";
import { Typography } from "@/shared/components/Typography";
import privacyPolicy from "@/shared/files/privacy_policy.pdf";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { onChange, onCheckbox } from "@/shared/utils/input";
export var SignupForm = observer(function () {
    var _a, _b, _c, _d, _e, _f;
    var page = useStoreContext(SignupPageStore);
    var auth = useStoreContext(AuthPageStore);
    var media = useMedia();
    return (React.createElement(AuthForm, { onBack: media.tablet ? auth.toggleForm : undefined, onSubmit: page.submitEmail, title: "Create an account", content: React.createElement(React.Fragment, null,
            React.createElement(Box, { padding: media.phone ? "0" : "0px", display: "flex", alignItems: "center", gap: "8px", width: "100%" },
                React.createElement(FormControlInput, { placeholder: "Your Name", outLabel: "First Name", value: page.firstName, onChange: onChange(action(function (value) {
                        page.firstName = value;
                    })), variantError: "alert", error: (_a = page.errorsByKey.firstName) === null || _a === void 0 ? void 0 : _a[0], disabled: page.loading }),
                React.createElement(FormControlInput, { placeholder: "Your Last Name", outLabel: "Last Name", value: page.lastName, onChange: onChange(action(function (value) {
                        page.lastName = value;
                    })), variantError: "alert", error: (_b = page.errorsByKey.lastName) === null || _b === void 0 ? void 0 : _b[0], disabled: page.loading })),
            React.createElement(FormControlInput, { placeholder: "email@example.com", outLabel: "Email", value: page.email, onChange: onChange(action(function (value) {
                    page.email = value;
                })), variantError: "alert", error: (_c = page.errorsByKey.email) === null || _c === void 0 ? void 0 : _c[0], disabled: page.loading }),
            React.createElement(FormControlInput, { type: "password", placeholder: "Your password", outLabel: "Password", value: page.password, onChange: onChange(action(function (value) {
                    page.password = value;
                })), variantError: "alert", error: (_d = page.errorsByKey.password) === null || _d === void 0 ? void 0 : _d[0], disabled: page.loading }),
            React.createElement(PasswordHint, { length: page.passwordValidation.length, uppercase: page.passwordValidation.uppercase, lowercase: page.passwordValidation.lowercase, special: page.passwordValidation.special, digit: page.passwordValidation.digit }),
            React.createElement(FormControlInput, { type: "password", placeholder: "Confirm your password", outLabel: "Confirm Password", value: page.confirmPassword, onChange: onChange(action(function (value) {
                    page.confirmPassword = value;
                })), variantError: "alert", error: (_e = page.errorsByKey.confirmPassword) === null || _e === void 0 ? void 0 : _e[0], disabled: page.loading })), footer: React.createElement(React.Fragment, null,
            React.createElement(FormControlCheckbox, { checked: page.privacy, onChange: onCheckbox(action(function (value) {
                    page.privacy = value;
                })), label: React.createElement(Typography, { type: "text14", color: "black-1000" },
                    "CryptoExchange \u2013 By checking this box I confirm I wish to create an account with CryptoExchange and that I have read the",
                    " ",
                    React.createElement("a", { href: privacyPolicy }, "CryptoExchange terms of service"),
                    " ",
                    "and ",
                    React.createElement("a", { href: privacyPolicy }, " Privacy policy"),
                    " ",
                    "agree to them.") }),
            React.createElement(React.Fragment, null,
                React.createElement(Button, { type: "submit", width: "100%", error: (_f = page.errorsByKey.unknown) === null || _f === void 0 ? void 0 : _f[0], disabled: !page.isSignupFormValid || page.loading }, "Next"),
                React.createElement("div", { className: styles.row },
                    "Already have?",
                    " ",
                    React.createElement(Link, { type: "text12Bold", to: generatePath(ROUTES.signin) }, "Log in")))) }));
});
