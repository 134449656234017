import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
var HeightInput = {
    l: "60px",
    m: "42px",
    xs: "30px",
};
var HrPaddingInput = {
    l: "20px",
    m: "14px",
    xs: "5px",
};
var RadiusInput = {
    l: "12px",
    m: "6px",
    xs: "6px",
};
export var StyledInput = styled(TextField, {
    shouldForwardProp: function (prop) { return !prop.startsWith("$"); },
})(function (_a) {
    var theme = _a.theme, multiline = _a.multiline, $size = _a.$size, $width = _a.$width, _b = _a.$textAlign, $textAlign = _b === void 0 ? "left" : _b, $fontFamily = _a.$fontFamily, InputProps = _a.InputProps;
    return ({
        // padding: multiline ? "16px" : PaddingtInput[$size],
        // borderRadius: '8px',
        // transition: 'background 0.2s ease',
        // display: 'flex',
        // alignItems: multiline ? 'normal' : 'center',
        // gap: '8px',
        // height: !multiline ? HeightInput[$size] : 'auto',
        width: $width || "auto",
        "& .MuiInputBase-root": {
            borderRadius: RadiusInput[$size],
            paddingRight: (InputProps === null || InputProps === void 0 ? void 0 : InputProps.endAdornment)
                ? HrPaddingInput[$size]
                : undefined,
            paddingLeft: (InputProps === null || InputProps === void 0 ? void 0 : InputProps.startAdornment)
                ? HrPaddingInput[$size]
                : undefined,
        },
        "& .MuiInputBase-input": {
            textAlign: $textAlign,
            padding: "0 ".concat(HrPaddingInput[$size]),
            height: !multiline ? HeightInput[$size] : "auto",
            fontFamily: $fontFamily || theme.fontFamily,
            fontSize: $fontFamily === "NEOPIXEL-Regular" ? "24px" : "",
            "&.Mui-disabled": {
                cursor: "not-allowed",
            },
        },
        "&.No-label fieldset": {
            top: "0",
            legend: {
                display: "none",
            },
        },
        "& input": {
            "&:-webkit-autofill": {
                // To remove autocomplete background
                WebkitBoxShadow: "0 0 0 1000px ".concat(theme.colors["general-white"], " inset"),
            },
        },
        "&.Mui-disabled,&.BaseInput-readOnly": {
            cursor: "not-allowed",
            "& input,& textarea": {
                cursor: "not-allowed",
                WebkitTextFillColor: "inherit",
            },
        },
        "&.BaseInput-clickable:not(.Mui-disabled,.BaseInput-readOnly)": {
            cursor: "pointer",
            "input, textarea": {
                cursor: "pointer",
                WebkitTextFillColor: "inherit",
            },
        },
        "input::-webkit-input-placeholder, textarea::-webkit-input-placeholder": {
            color: theme.colors["black-400"],
            fontWeight: 300,
            fontSize: 14,
            opacity: 1,
        },
    });
});
