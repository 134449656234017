import { formattedAmount } from "@broxus/js-utils";
import BigNumber from "bignumber.js";
import { observer } from "mobx-react-lite";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { UserCurrencyStore } from "@/shared/stores/UserCurrencyStore";
export var Amount = observer(function (_a) {
    var _b;
    var from = _a.from, to = _a.to, amount = _a.amount;
    var rates = useStoreContext(CurrencyListStore).rates;
    var userCurrency = useStoreContext(UserCurrencyStore);
    var toCurrency = (_b = (to || userCurrency.selected || "usd")) === null || _b === void 0 ? void 0 : _b.toLowerCase();
    var fromRate = rates[from === null || from === void 0 ? void 0 : from.toLowerCase()];
    var toRate = rates[toCurrency];
    if ((fromRate === null || fromRate === void 0 ? void 0 : fromRate.eurPrice) && (toRate === null || toRate === void 0 ? void 0 : toRate.eurPrice)) {
        var value = BigNumber(fromRate.eurPrice)
            .multipliedBy(amount)
            .dividedBy(toRate.eurPrice)
            .toFixed();
        return "$".concat(formattedAmount(value, undefined, {
            decimalSeparator: ".",
            digitsSeparator: ",",
        }));
    }
    return "$0";
});
