var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { formattedTokenAmount } from "@broxus/js-utils";
import BigNumber from "bignumber.js";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TransferModal } from "../Modal";
import { useGetCurrencyBySlug } from "@/api/currency";
import { useCreateTransfer } from "@/api/wallet";
import { Header } from "@/pages/TransferPage/components/Header";
import { TransferAccountFormStore } from "@/pages/TransferPage/stores/TransferAccountFormStore";
import { ROUTES, TransferType } from "@/routes";
import { Box, Button } from "@/shared/components";
import { AccountSelect } from "@/shared/components/AccountSelect";
import { BankAccountPopup } from "@/shared/components/BankAccountPopup";
import { BankIcon } from "@/shared/components/BankIcon";
import { ExchangeField } from "@/shared/components/ExchangeField";
import { ExchangeInput } from "@/shared/components/ExchangeInput";
import { FormAlert } from "@/shared/components/FormAlert";
import { Info } from "@/shared/components/Info";
import { OperationLayout } from "@/shared/components/OperationLayout";
import { SectionAmount } from "@/shared/components/SectionAmount";
import { useMedia } from "@/shared/hooks/useMedia";
import { useProvider, useStore, useStoreContext, } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { generateQueryPath } from "@/shared/utils/path";
import { sliceAddress } from "@/shared/utils/slice-address";
export var Account = observer(function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var media = useMedia();
    var fromPopup = useToggler();
    var toPopup = useToggler();
    var navigate = useNavigate();
    var search = useSearchParams()[0];
    var from = search.get("from");
    var to = search.get("to");
    var accountList = useStoreContext(AccountListStore);
    var FormProvider = useProvider(TransferAccountFormStore);
    var balanceList = useStoreContext(BalanceListStore);
    var form = useStore(TransferAccountFormStore, balanceList, accountList);
    var accounts = useStoreContext(AccountListStore);
    var fromAcc = from ? accounts.fiatById[from] : undefined;
    var toAcc = to ? accounts.fiatById[to] : undefined;
    var _o = React.useState(false), isOpen = _o[0], setIsOpen = _o[1];
    var fromError = form.fromEnough === false ? "Enter a smaller amount" : undefined;
    React.useEffect(function () {
        runInAction(function () {
            form.from = from !== null && from !== void 0 ? from : undefined;
            form.to = to !== null && to !== void 0 ? to : undefined;
        });
    }, [from, to, form]);
    React.useEffect(function () {
        if (from || to) {
            balanceList.sync(from, to);
        }
    }, [from, to, balanceList]);
    var _p = useCreateTransfer({}), mutateAsync = _p.mutateAsync, isPending = _p.isPending;
    var createTransfer = function () { return __awaiter(void 0, void 0, void 0, function () {
        var result, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, mutateAsync({
                            amount: form.toAmount,
                            fromAccount: fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.account,
                            toAccount: toAcc === null || toAcc === void 0 ? void 0 : toAcc.account,
                            currency: fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency,
                        })];
                case 1:
                    result = _a.sent();
                    setIsOpen(false);
                    balanceList.sync(from, to);
                    form.reset();
                    return [2 /*return*/, {
                            id: result.data.id,
                            amount: result.data.amount,
                            currency: result.data.currency,
                            createdAt: result.data.createdAt,
                        }];
                case 2:
                    error_1 = _a.sent();
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var data = useGetCurrencyBySlug(fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency).data;
    var fee = (Number(form.fromAmount || 0) *
        (((_a = data === null || data === void 0 ? void 0 : data.settings) === null || _a === void 0 ? void 0 : _a.transferFeePercent) || 0)) /
        100;
    var info = [
        {
            label: "Amount",
            value: (form === null || form === void 0 ? void 0 : form.fromAmount)
                ? "".concat(formattedTokenAmount(form === null || form === void 0 ? void 0 : form.fromAmount), " ").concat((_b = form === null || form === void 0 ? void 0 : form.fromAcc) === null || _b === void 0 ? void 0 : _b.currency)
                : "–",
        },
        {
            label: "Transaction fee",
            value: fee ? formattedTokenAmount(fee) : "–",
        },
        {
            label: "Operation time",
            value: "Instantly",
        },
    ];
    var total = BigNumber(form.fromAmount || 0)
        .plus(fee)
        .toString();
    return (React.createElement(FormProvider, { value: form },
        fromPopup.enabled && (React.createElement(BankAccountPopup, { selectedId: (_c = form.fromAcc) === null || _c === void 0 ? void 0 : _c.account, onClose: fromPopup.off, onSelect: function (id) {
                fromPopup.off();
                navigate(generateQueryPath(ROUTES.transfer, {
                    type: TransferType.Account,
                }, {
                    from: id === to ? to : id,
                    to: id === to ? from : undefined,
                }), {
                    replace: true,
                });
            } })),
        toPopup.enabled && (React.createElement(BankAccountPopup, { currency: (_d = form.fromAcc) === null || _d === void 0 ? void 0 : _d.currency, selectedId: (_e = form.toAcc) === null || _e === void 0 ? void 0 : _e.account, onClose: toPopup.off, onSelect: function (id) {
                toPopup.off();
                navigate(generateQueryPath(ROUTES.transfer, {
                    type: TransferType.Account,
                }, {
                    from: id === from ? to : from,
                    to: id === from ? from : id,
                }), {
                    replace: true,
                });
            } })),
        React.createElement(OperationLayout, { main: React.createElement(Box, null,
                React.createElement(Header, { active: TransferType.Account }),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
                    React.createElement(ExchangeField, { error: fromError, input: React.createElement(ExchangeInput, { disabled: form.loading, invalid: !!fromError, postFix: (_f = form.fromAcc) === null || _f === void 0 ? void 0 : _f.currency.toUpperCase(), value: form.fromAmount, onChange: action(function (value) {
                                form.fromAmount = value;
                            }) }), select: React.createElement(AccountSelect, { disabled: form.loading, name: form.fromAcc
                                ? sliceAddress(form.fromAcc.account)
                                : undefined, descLoading: !form.fromBalance, desc: form.fromBalance
                                ? "".concat(formattedTokenAmount(form.fromBalance.balance), " ").concat(form.fromBalance.currency.toUpperCase())
                                : undefined, onClick: fromPopup.on, icon: form.fromAcc ? (React.createElement(BankIcon, { symbol: form.fromAcc.currency, size: 24 })) : undefined }) }),
                    React.createElement(ExchangeField, { input: React.createElement(ExchangeInput, { disabled: true, postFix: (_g = form.toAcc) === null || _g === void 0 ? void 0 : _g.currency.toUpperCase(), value: form.toAmount }), select: React.createElement(AccountSelect, { disabled: form.loading, name: form.toAcc
                                ? sliceAddress(form.toAcc.account)
                                : undefined, descLoading: !form.toBalance, desc: form.toBalance
                                ? "".concat(formattedTokenAmount(form.toBalance.balance), " ").concat(form.toBalance.currency.toUpperCase())
                                : undefined, iconUri: form.toAcc
                                ? form.toAcc.currency
                                : undefined, onClick: toPopup.on, icon: form.toAcc ? (React.createElement(BankIcon, { symbol: (_h = form.toAcc) === null || _h === void 0 ? void 0 : _h.currency, size: 24 })) : undefined }) }),
                    form.errors.length > 0 && (React.createElement(FormAlert, { text: form.errors[0].description })))), card: media.desktop && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "50px" },
                React.createElement(Info, { data: info }),
                React.createElement(Box, { height: "1px", bgColor: "black-1000", width: "100%" }),
                React.createElement(SectionAmount, { amount: total, currency: fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency }),
                React.createElement(Button, { width: "100%", disabled: !form.valid || form.loading, onClick: function () { return setIsOpen(true); } }, "Send"))), side: !media.desktop && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "30px", width: "100%" },
                React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }),
                React.createElement(Info, { dark: false, data: __spreadArray(__spreadArray([], info, true), [
                        {
                            label: "Total + Fees",
                            value: "".concat(total, " ").concat(fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency, "\""),
                        },
                    ], false) }),
                React.createElement(Button, { width: "100%", disabled: !form.valid || form.loading, onClick: function () { return setIsOpen(true); } }, "Send"))) }),
        React.createElement(TransferModal, { to: "".concat((_k = (_j = form.fromAcc) === null || _j === void 0 ? void 0 : _j.account) === null || _k === void 0 ? void 0 : _k.slice(0, 3), "...").concat((_m = (_l = form.fromAcc) === null || _l === void 0 ? void 0 : _l.account) === null || _m === void 0 ? void 0 : _m.slice(-4)), amount: form.fromAmount, currency: fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency, isOpen: isOpen, transfer: createTransfer, info: [
                {
                    label: "Fee",
                    value: fee ? formattedTokenAmount(fee) : "–",
                },
                {
                    label: "Operation time",
                    value: "Instantly",
                },
            ], onClose: function () { return setIsOpen(false); }, isLoading: isPending })));
});
