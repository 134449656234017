import React from "react";
import { Box, Button, Modal, Typography } from "@/shared/components";
import { DATE_FORMAT, formatDate } from "@/shared/lib";
export var SuccessPopup = function (_a) {
    var onClose = _a.onClose, withdrawData = _a.withdrawData;
    return (React.createElement(Modal, { showCloseButton: false, open: true, onClose: onClose, bgColor: "general-green" },
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "50px", alignItems: "center" },
            React.createElement(Typography, { color: "general-white", sx: { opacity: 0.6 } }, formatDate(withdrawData === null || withdrawData === void 0 ? void 0 : withdrawData.updatedAt, DATE_FORMAT.date_with_time)),
            React.createElement(Typography, { type: "text28Bold", textAlign: "center", color: "general-white" }, "Success"),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" },
                React.createElement(Typography, { color: "general-white" }, (withdrawData === null || withdrawData === void 0 ? void 0 : withdrawData.meta).public_note))),
        React.createElement(Button, { mt: "100px", width: "100%", variant: "White", onClick: onClose }, "Done")));
};
