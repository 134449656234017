import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { NewPasswordForm } from "./components/NewPasswordForm";
import { ResetCodeForm } from "./components/ResetCodeForm";
import { PasswordResetForm } from "./components/PasswordResetForm";
import { PasswordResetPageStore, PasswordResetStep, } from "@/pages/PasswordResetPage/stores/PasswordResetPageStore";
import { ROUTES } from "@/routes";
import { useProvider, useStore } from "@/shared/hooks/useStore";
export var PasswordResetPage = observer(function () {
    var navigate = useNavigate();
    var page = useStore(PasswordResetPageStore);
    var PageProvider = useProvider(PasswordResetPageStore);
    var component = React.useCallback(function () {
        switch (page.step) {
            case PasswordResetStep.Code:
                return React.createElement(ResetCodeForm, null);
            case PasswordResetStep.Password:
                return React.createElement(NewPasswordForm, null);
            default:
                return React.createElement(PasswordResetForm, null);
        }
    }, [page.step]);
    React.useEffect(function () {
        if (page.success) {
            navigate(generatePath(ROUTES.signin));
        }
    }, [page.success, navigate]);
    return React.createElement(PageProvider, { value: page }, component());
});
