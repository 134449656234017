import React from "react";
import { Box, Button, Modal, Typography } from "@/shared/components";
import { DATE_FORMAT, formatDate } from "@/shared/lib";
export var ErrorPopup = function (_a) {
    var onClose = _a.onClose, errorText = _a.errorText;
    return (React.createElement(Modal, { showCloseButton: false, open: !!errorText, onClose: function () { return onClose(); }, bgColor: "black-1200" },
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "50px", alignItems: "center" },
            React.createElement(Typography, { color: "general-white", sx: { opacity: 0.6 } }, formatDate(new Date().toISOString(), DATE_FORMAT.date_with_time)),
            React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center" },
                React.createElement(Typography, { type: "text28Bold", variant: "h3", mb: "10px", textAlign: "center", color: "general-white" }, errorText || "Something went wrong"),
                React.createElement(Typography, { color: "black-500" }, "We couldn't process your request."),
                React.createElement(Typography, { color: "black-500" }, "Please try again."))),
        React.createElement(Box, { display: "flex", flexDirection: "column", width: "100%", alignItems: "center", gap: "20px" },
            React.createElement(Button, { mt: "200px", width: "100%", variant: "White", onClick: function () { return onClose(); } }, "Try again"),
            React.createElement(Typography, { color: "general-white" }, "Contact support"))));
};
