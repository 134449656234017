var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import * as React from "react";
import { NumericFormat } from "react-number-format";
export var ExchangeInput = function (_a) {
    var autoFocus = _a.autoFocus, postFix = _a.postFix, value = _a.value, invalid = _a.invalid, _b = _a.placeholder, placeholder = _b === void 0 ? "0" : _b, disabled = _a.disabled, readOnly = _a.readOnly, decimals = _a.decimals, props = __rest(_a, ["autoFocus", "postFix", "value", "invalid", "placeholder", "disabled", "readOnly", "decimals"]);
    var inputRef = React.useRef(null);
    var onChange = function (values) {
        var _a;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, values.value);
    };
    return (React.createElement(NumericFormat, { readOnly: readOnly, disabled: disabled, autoFocus: autoFocus, getInputRef: inputRef, customInput: Input, decimalScale: decimals, decimalSeparator: ".", type: "text", suffix: postFix ? " ".concat(postFix) : undefined, value: value !== null && value !== void 0 ? value : "", onValueChange: onChange, placeholder: placeholder, invalid: invalid }));
};
var Input = styled("input")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: 0;\n    padding: 0;\n    width: 100%;\n    border: none;\n    font-size: 32px;\n    background-color: transparent;\n    outline: none;\n    font-weight: 400;\n    // height: 24px;\n    color: ", ";\n    transition: color 0.2s ease;\n\n    &::placeholder {\n        color: ", ";\n        opacity: 1;\n    }\n"], ["\n    margin: 0;\n    padding: 0;\n    width: 100%;\n    border: none;\n    font-size: 32px;\n    background-color: transparent;\n    outline: none;\n    font-weight: 400;\n    // height: 24px;\n    color: ", ";\n    transition: color 0.2s ease;\n\n    &::placeholder {\n        color: ", ";\n        opacity: 1;\n    }\n"])), function (props) {
    return props.invalid
        ? props.theme.colors["general-red"]
        : props.theme.colors["black-1200"];
}, function (props) {
    return props.invalid
        ? props.theme.colors["general-red"]
        : props.theme.colors["black-300"];
});
var templateObject_1;
