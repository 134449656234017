var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useRef, useState } from "react";
import { BaseInput } from "../Input";
import { Box } from "../Box";
export var OTPInput = function (_a) {
    var _b = _a.length, length = _b === void 0 ? 5 : _b, onComplete = _a.onComplete;
    var inputRef = useRef(Array(length).fill(null));
    var _c = useState(Array(length).fill("")), OTP = _c[0], setOTP = _c[1];
    var handleTextChange = function (input, index) {
        var _a, _b;
        if (OTP.length === length && input === "") {
            onComplete("");
        }
        var newPin = __spreadArray([], OTP, true);
        newPin[index] = input;
        setOTP(newPin);
        if (input.length === 1 && index < length - 1) {
            (_a = inputRef.current[index + 1]) === null || _a === void 0 ? void 0 : _a.focus();
        }
        if (input.length === 0 && index > 0) {
            (_b = inputRef.current[index - 1]) === null || _b === void 0 ? void 0 : _b.focus();
        }
        if (newPin.every(function (digit) { return digit !== ""; })) {
            onComplete(newPin.join(""));
        }
    };
    return (React.createElement(Box, { display: "grid", gridTemplateColumns: "repeat(".concat(length, ", 1fr)"), gap: "16px" }, Array.from({ length: length }, function (_, idx) { return (React.createElement(BaseInput, { ref: function (ref) {
            return (inputRef.current[idx] = ref);
        }, key: idx, type: "text", maxLength: 1, size: "l", value: OTP[idx], textAlign: "center", fontFamily: "NEOPIXEL-Regular", onChange: function (e) { return handleTextChange(e.target.value, idx); } })); })));
};
