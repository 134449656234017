var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { generatePath, Link, useNavigate, useParams } from "react-router-dom";
import MaskedInput from "react-text-mask";
import { FormLayout } from "@/pages/KYBPage/components/FormLayout";
import { IndividualFormStore } from "@/pages/KYBPage/stores/IndividualFormStore";
import { IndividualListStore } from "@/pages/KYBPage/stores/IndividualListStore";
import { ROUTES } from "@/routes";
import { Box, FormControlInput, Typography } from "@/shared/components";
import { Button } from "@/shared/components/Button";
import { FormAlert } from "@/shared/components/FormAlert";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { countries } from "@/shared/constants/countries";
import { IndividualTypes, USResidencyStatus } from "@/shared/constants/dict";
import { useProvider, useStore, useStoreContext } from "@/shared/hooks/useStore";
import { RequiredFieldMessage, WrongFormatMessage } from "@/shared/utils/error";
import { EMAIL_REGEXP } from "@/shared/utils/is-email";
export var IndividualForm = observer(function () {
    var _a, _b;
    var params = useParams();
    var navigate = useNavigate();
    var individualList = useStoreContext(IndividualListStore);
    var individualForm = useStore(IndividualFormStore, individualList);
    var IndividualFormProvider = useProvider(IndividualFormStore);
    var form = useForm();
    var submit = form.handleSubmit(function (formData) { return __awaiter(void 0, void 0, void 0, function () {
        var uuid;
        return __generator(this, function (_a) {
            uuid = params.param === "create" ? undefined : params.param;
            individualForm.submitForm(formData, uuid).then(function (success) {
                if (success)
                    navigate(generatePath(ROUTES.kybFormId, { id: 5 }));
            });
            return [2 /*return*/];
        });
    }); });
    React.useEffect(function () {
        var data = individualList.list.find(function (item) { return item.id === params.param; });
        form.reset(data);
    }, [params.param, individualList.list, form]);
    return (React.createElement(IndividualFormProvider, { value: individualForm },
        React.createElement(FormLayout, { index: 5, showSubmitBtns: false, onSubmit: submit, onBack: function () { return navigate(generatePath(ROUTES.kybFormId, { id: 4 })); }, title: "Company Roles and Structure", desc: "Please provide all the persons who play role in the company. You need to enter information about all shareholders. If there are more than 10 of them, then you need to enter data on the 10 largest shareholders.", loading: individualList.loading || individualForm.loading },
            React.createElement(Controller, { control: form.control, name: "individualType", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, value: field.value, options: IndividualTypes, label: "Role", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", disabled: individualForm.loading || field.disabled }));
                } }),
            React.createElement(Controller, { control: form.control, name: "percentageOwnership", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                    pattern: {
                        value: /^\d+$/,
                        message: WrongFormatMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Percentage ownership", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                } }),
            React.createElement(Controller, { control: form.control, name: "firstName", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: individualForm.loading || field.disabled, label: "First name", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                } }),
            React.createElement(Controller, { control: form.control, name: "middleName", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Middle name", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", InputProps: {
                            endAdornment: (React.createElement(Typography, { type: "text12", color: "black-500" }, "Optional")),
                        } })));
                } }),
            React.createElement(Controller, { control: form.control, name: "lastName", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Last name", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                } }),
            React.createElement(Controller, { control: form.control, name: "emailAddress", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                    pattern: {
                        value: EMAIL_REGEXP,
                        message: WrongFormatMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Email", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                } }),
            React.createElement(Controller, { control: form.control, name: "telephoneNumber", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Phone number", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                } }),
            React.createElement(Controller, { control: form.control, name: "mailingAddress.flatNumber", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Unit number", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", InputProps: {
                            endAdornment: (React.createElement(Typography, { type: "text12", color: "black-500" }, "Optional")),
                        } })));
                } }),
            React.createElement(Controller, { control: form.control, name: "mailingAddress.street", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Address line 1", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                } }),
            React.createElement(Controller, { control: form.control, name: "mailingAddress.buildingNumber", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Address line 2", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", InputProps: {
                            endAdornment: (React.createElement(Typography, { type: "text12", color: "black-500" }, "Optional")),
                        } })));
                } }),
            React.createElement(Controller, { control: form.control, name: "mailingAddress.subStreet", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Address line 3", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", InputProps: {
                            endAdornment: (React.createElement(Typography, { type: "text12", color: "black-500" }, "Optional")),
                        } })));
                } }),
            React.createElement(Controller, { control: form.control, name: "mailingAddress.town", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: individualForm.loading || field.disabled, label: "City", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                } }),
            React.createElement(Controller, { control: form.control, name: "mailingAddress.postCode", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Postal / Zip code", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                } }),
            React.createElement(Controller, { control: form.control, name: "mailingAddress.state", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: individualForm.loading || field.disabled, label: "State", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                } }),
            React.createElement(Controller, { control: form.control, name: "mailingAddress.country", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, value: field.value, options: countries.map(function (item) { return ({
                            key: item.codeAlpha3,
                            label: "".concat(item.flag, " ").concat(item.name),
                        }); }), label: "Country", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", disabled: individualForm.loading || field.disabled }));
                } }),
            React.createElement(Controller, { control: form.control, name: "taxReferenceNumber", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Tax reference number of the country", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                } }),
            React.createElement(Controller, { control: form.control, name: "passportNumber", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Passport number", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", InputProps: {
                            endAdornment: (React.createElement(Typography, { type: "text12", color: "black-500" }, "Optional")),
                        } })));
                } }),
            React.createElement(Controller, { control: form.control, name: "nationality", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Nationality", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                } }),
            React.createElement(Controller, { control: form.control, name: "dateOfBirth", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(MaskedInput, __assign({}, field, { mask: [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/], render: function (innerRef, props) { return (React.createElement(FormControlInput, __assign({}, props, { ref: innerRef, value: props.defaultValue, disabled: individualForm.loading || field.disabled, label: "Date of birth", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", placeholder: "YYYY-MM-DD" }))); } })));
                } }),
            React.createElement(Controller, { control: form.control, name: "title", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: individualForm.loading || field.disabled, label: "Job title", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", InputProps: {
                            endAdornment: (React.createElement(Typography, { type: "text12", color: "black-500" }, "Optional")),
                        } })));
                } }),
            React.createElement(Controller, { control: form.control, name: "usResidencyStatus", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, value: field.value, options: USResidencyStatus, label: "US Residency status", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", disabled: individualForm.loading || field.disabled }));
                } }),
            ((_a = individualForm.errors[0]) === null || _a === void 0 ? void 0 : _a.description) && React.createElement(FormAlert, { text: (_b = individualForm.errors[0]) === null || _b === void 0 ? void 0 : _b.description }),
            React.createElement(Box, { display: "flex", justifyContent: "end", gap: "10px" },
                React.createElement(Button, { type: "submit", paddingLeft: "25px", paddingRight: "25px", disabled: individualList.loading || individualForm.loading }, "Save"),
                React.createElement(Button, { variant: "Bordered", paddingLeft: "25px", paddingRight: "25px", LinkComponent: Link, to: generatePath(ROUTES.kybFormId, {
                        id: 5,
                    }) }, "Decline")))));
});
