import aave from "./aave.svg";
import algo from "./algo.svg";
import anw from "./anw.svg";
import avax from "./avax.svg";
import bat from "./bat.svg";
import bittorrent from "./bittorrent.svg";
import bnb from "./bnb.svg";
import chainlink from "./chainlink.svg";
import dai from "./dai.svg";
import dao from "./dao.svg";
import dash from "./dash.svg";
import doge from "./doge.svg";
import eth from "./eth.svg";
import ever from "./ever.svg";
import gala from "./gala.svg";
import gleec from "./gleec.svg";
import gram from "./gram.svg";
import mana from "./mana.svg";
import maps from "./maps.svg";
import matic from "./matic.svg";
import mkr from "./mkr.svg";
import n901 from "./n901.svg";
import omnisego from "./omnisego.svg";
import qash from "./qash.svg";
import rep from "./rep.svg";
import ripple from "./ripple.svg";
import sand from "./sand.svg";
import shib from "./shib.svg";
import sol from "./sol.svg";
import standart from "./standart.svg";
import ton from "./ton.svg";
import trx from "./trx.svg";
import ucd from "./ucd.svg";
import ucd1 from "./ucd1.svg";
import uni from "./uni.svg";
import usdc from "./usdc.svg";
import usdt from "./usdt.svg";
import xdc from "./xdc.svg";
import zrx from "./zrx.svg";
import btc from "./btc.svg";
import ltc from "./ltc.svg";
import eur from "./eur.svg";
import usd from "./usd.svg";
import gel from "./gel.svg";
import sgel from "./sgel.svg";
export var TokenIcons = {
    aave: aave,
    algo: algo,
    anw: anw,
    avax: avax,
    bat: bat,
    bittorrent: bittorrent,
    bnb: bnb,
    chainlink: chainlink,
    dai: dai,
    dao: dao,
    dash: dash,
    doge: doge,
    eth: eth,
    ever: ever,
    gala: gala,
    gleec: gleec,
    gram: gram,
    mana: mana,
    maps: maps,
    matic: matic,
    mkr: mkr,
    n901: n901,
    omnisego: omnisego,
    qash: qash,
    rep: rep,
    ripple: ripple,
    sand: sand,
    shib: shib,
    sol: sol,
    standart: standart,
    ton: ton,
    trx: trx,
    ucd: ucd,
    ucd1: ucd1,
    uni: uni,
    usdc: usdc,
    usdt: usdt,
    xdc: xdc,
    zrx: zrx,
    btc: btc,
    ltc: ltc,
    eur: eur,
    usd: usd,
    gel: gel,
    sgel: sgel,
};
