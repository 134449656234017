var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { SubscriptionCard } from "./components/Card";
import { useGetVerificationInfo } from "@/api/registration";
import { VerificationStatus } from "@/api/registration/types";
import { ROUTES } from "@/routes";
import { Box, Button, Modal, Typography } from "@/shared/components";
import { useErrorToasts } from "@/shared/hooks/useErrorToasts";
import { useProvider, useStore } from "@/shared/hooks/useStore";
import { CompanyInfoStore, SubscriptionType } from "@/shared/stores/CompanyInfoStore";
import { UnderReviewPopup } from "@/widgets/UnderReviewPopup";
var options = [
    "Feature description text",
    "Description text example",
    "Feature text description example",
    "Feature description text",
    "Description text example",
    "Feature text description example",
];
export var SubscriptionPage = observer(function () {
    var _a;
    var navigate = useNavigate();
    var companyInfo = useStore(CompanyInfoStore);
    var CompanyInfoProvider = useProvider(CompanyInfoStore);
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var verification = useGetVerificationInfo();
    useErrorToasts(companyInfo.errors);
    var selectPlanFn = function (type) { return __awaiter(void 0, void 0, void 0, function () {
        var success, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, companyInfo.update({
                            meta: {
                                subscriptionType: type,
                            },
                        })];
                case 1:
                    success = _a.sent();
                    if (success) {
                        navigate(generatePath(ROUTES.kyb));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.error(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(CompanyInfoProvider, { value: companyInfo },
        ((_a = verification.data) === null || _a === void 0 ? void 0 : _a.status) === VerificationStatus.PENDING && React.createElement(UnderReviewPopup, null),
        React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "40px" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
                React.createElement(Typography, { textAlign: "center", type: "text32Bold" },
                    "Find the right plan for",
                    " ",
                    React.createElement(Typography, { type: "text32Bold", color: "deep-blue-500" }, "your financial goals")),
                React.createElement(Typography, { color: "black-500", textAlign: "center" }, "Get unlimited access")),
            React.createElement(Box, { display: "grid", gridTemplateColumns: "repeat(2,350px)", gap: "40px" },
                React.createElement(SubscriptionCard, { title: "Personal", description: "Description text example", price: "400", options: options, onSelect: function () { return selectPlanFn(SubscriptionType.Personal); }, disabled: !companyInfo.ready || companyInfo.loading || !verification.data }),
                React.createElement(SubscriptionCard, { theme: "black", title: "Business", description: "Description text example", price: "1,200", options: options, onSelect: function () { return selectPlanFn(SubscriptionType.Business); }, disabled: !companyInfo.ready || companyInfo.loading || !verification.data })),
            React.createElement(Box, { padding: "30px", bgColor: "black-100", display: "flex", flexDirection: "column", gap: "30px", width: "100%", maxWidth: "710px", alignItems: "center", borderRadius: "22px", mb: "110px", boxSizing: "border-box" },
                React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center" },
                    React.createElement(Typography, { type: "text22Bold" }, "Need customization?"),
                    React.createElement(Typography, { type: "text14", color: "black-500" }, "Our team will contact you with additional information regarding monthly plans")),
                React.createElement(Button, { variant: "Black", width: "290px", onClick: function () { return setIsOpen(true); } }, "Request another plan")),
            React.createElement(Modal, { open: isOpen, onClose: function () { return setIsOpen(false); }, bgColor: "general-green", showCloseButton: false, isMobileFull: true },
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "260px", mt: "100px" },
                    React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" },
                        React.createElement(Typography, { color: "general-white", type: "text28Bold" }, "We'll be in touch soon"),
                        React.createElement(Typography, { color: "general-white", textAlign: "center", type: "text14" }, "Our team will contact you with additional information regarding monthly plans")),
                    React.createElement(Button, { variant: "White", onClick: function () { return setIsOpen(false); } }, "Got it"))))));
});
