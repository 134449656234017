import { useQuery } from "@tanstack/react-query";
import { currencyApi } from "./api";
export var useGetTokens = function () {
    return useQuery({
        queryKey: ["tokens"],
        queryFn: function () { return currencyApi.token.getAllTokensShort(); },
        staleTime: 100000,
        gcTime: 100000,
        select: function (_a) {
            var data = _a.data;
            return data.reduce(function (acc, item) {
                acc[item.name] = item;
                return acc;
            }, {});
        },
    });
};
