var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { styled } from "@mui/material/styles";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { WalletAddress } from "@/pages/PaymentPage/components/PaymentFormCrypto/WalletAddress";
import { PaymentCryptoFormStore, wallets, } from "@/pages/PaymentPage/stores/PaymentCryptoFormStore";
import { Box, Typography } from "@/shared/components";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { Autocomplete } from "@/shared/components/Select";
import { ListOptionDefault } from "@/shared/components/Select/Option";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { useStoreContext } from "@/shared/hooks/useStore";
var tokens = ["BTC", "ETH", "USDT", "USDC"];
export var TokenForm = observer(function () {
    var _a, _b, _c;
    var form = useStoreContext(PaymentCryptoFormStore);
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px" },
        React.createElement(Typography, { type: "text32Bold" }, "Pay with crypto"),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
            React.createElement(Autocomplete, { fullWidth: true, disabled: form.success, label: "Search coin", options: wallets, value: (_a = wallets.find(function (item) { return item.key === form.walletKey; })) !== null && _a !== void 0 ? _a : null, onChange: function (_, option) {
                    runInAction(function () {
                        form.walletKey = option === null || option === void 0 ? void 0 : option.key;
                    });
                }, renderOption: function (p, option, state) { return (React.createElement(ListOptionDefault, __assign({}, state, p, { key: option.key }),
                    React.createElement(Box, { display: "flex", alignItems: "center", gap: "10px" },
                        React.createElement(TokenIcon, { symbol: option.symbol }),
                        React.createElement(Typography, { type: "text16" }, option.symbol),
                        React.createElement(Typography, { type: "text16", color: "black-500" }, option.name)))); } }),
            React.createElement(Box, { display: "flex", gap: "10px" }, tokens.map(function (item) {
                var _a;
                var key = (_a = wallets.find(function (wallet) {
                    return wallet.symbol.toLowerCase() ===
                        item.toLowerCase();
                })) === null || _a === void 0 ? void 0 : _a.key;
                return key ? (React.createElement(TokenBtn, { key: item, disabled: form.success || form.loading, onClick: action(function () {
                        form.walletKey = key;
                    }) },
                    React.createElement(TokenIcon, { symbol: item }),
                    React.createElement(Typography, { type: "text14", fontWeight: 500 }, item))) : null;
            }))),
        React.createElement(FormControlSelect, { label: "Select network", disabled: !form.walletKey || form.success || form.loading, options: (_c = (_b = wallets.find(function (item) { return item.key === form.walletKey; })) === null || _b === void 0 ? void 0 : _b.network) !== null && _c !== void 0 ? _c : [], value: form.networkKey, onChange: function (_, option) {
                runInAction(function () {
                    form.networkKey = option === null || option === void 0 ? void 0 : option.key;
                });
            } }),
        form.walletAddress && (React.createElement(WalletAddress, { address: form.walletAddress }))));
});
var TokenBtn = styled("button")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background: ", ";\n    padding: 10px;\n    border-radius: 12px;\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    cursor: pointer;\n"], ["\n    background: ", ";\n    padding: 10px;\n    border-radius: 12px;\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    cursor: pointer;\n"])), function (props) { return props.theme.colors["black-50"]; });
var templateObject_1;
