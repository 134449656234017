var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { PaymentInfoPopup } from "@/pages/PaymentPage/components/PaymentInfoPopup";
import { PaymentTypeItem } from "@/pages/PaymentPage/components/PaymentTypeItem";
import { PaymentParam, PaymentTarget, PaymentType, ROUTES } from "@/routes";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { useToggler } from "@/shared/hooks/useToggler";
import bgImg from "@/shared/img/payment-bg.jpg";
import { generateQueryPath } from "@/shared/utils/path";
export var PaymentTypePage = observer(function () {
    var navigate = useNavigate();
    var infoPopup = useToggler();
    var search = useSearchParams()[0];
    var target = search.get(PaymentParam.Target);
    var plan = search.get(PaymentParam.Plan);
    var _a = React.useState(PaymentType.Crypto), paymentType = _a[0], setPaymentType = _a[1];
    return (React.createElement(React.Fragment, null,
        infoPopup.enabled && React.createElement(PaymentInfoPopup, { onClose: infoPopup.off }),
        React.createElement(Bg, null),
        React.createElement(Wrapper, null,
            target === PaymentTarget.Kyb && (React.createElement(Button, { variant: "Gray", icon: "Back", width: "auto", paddingRight: "25px", onClick: function () { return navigate(generatePath(ROUTES.kybForm)); } }, "Back")),
            React.createElement(Typography, { paddingTop: target === PaymentTarget.Kyb ? "60px" : "112px", type: "text32Bold", color: "black-1200", marginBottom: "10px", variant: "h1" }, "Payment"),
            React.createElement(Typography, { type: "text16", color: "black-500", marginBottom: "40px", variant: "body1" },
                "Choose your preferred payment method.",
                " ",
                React.createElement(LearnMore, { type: "button", onClick: infoPopup.on },
                    "Learn more",
                    React.createElement(InfoIcon, { icon: "Info", size: 14 }))),
            React.createElement(Box, { display: "grid", gridAutoFlow: "row", gridAutoRows: "1fr", gap: "10px" },
                React.createElement(PaymentTypeItem, { active: paymentType === PaymentType.Crypto, icon: "PaymentTypeCrypto", title: "Pay with crypto", desc: "00.00 USDT", onClick: function () { return setPaymentType(PaymentType.Crypto); } }),
                React.createElement(PaymentTypeItem, { disable: true, active: paymentType === PaymentType.Card, icon: "PaymentTypeCard", title: "Pay with card", desc: "\u20AC00.00", onClick: function () { return setPaymentType(PaymentType.Card); } }),
                React.createElement(PaymentTypeItem, { disable: true, icon: "PaymentTypeOther", title: "Other payment method" })),
            React.createElement(Button, { minWidth: 250, marginTop: "100px", onClick: function () {
                    var _a;
                    navigate(generateQueryPath(ROUTES.payment, {}, (_a = {},
                        _a[PaymentParam.Target] = target,
                        _a[PaymentParam.Type] = paymentType,
                        _a[PaymentParam.Plan] = plan,
                        _a)), { replace: true });
                } }, "Next"))));
});
var Bg = styled("div")(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            position: "fixed",
            top: 0,
            bottom: 0,
            left: "max(calc(((100% - 1240px) / 2) + 560px + min(160px, ((100% - 1240px) / 2))), 600px)",
            right: 0,
            zIndex: -1,
            backgroundImage: "url(".concat(bgImg, ")"),
            backgroundSize: "620px",
            backgroundPosition: "bottom right",
            backgroundRepeat: "no-repeat",
            backgroundColor: theme.colors["black-1200"]
        },
        _b[theme.breakpoints.down(1220)] = {
            backgroundPosition: "bottom left",
        },
        _b[theme.breakpoints.down("md")] = {
            display: "none",
        },
        _b);
});
var Wrapper = styled("div")(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            maxWidth: 560
        },
        _b[theme.breakpoints.down("md")] = {
            maxWidth: "unset",
        },
        _b);
});
var InfoIcon = styled(BaseIcon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: inline-flex;\n    vertical-align: middle;\n    margin-left: 5px;\n"], ["\n    display: inline-flex;\n    vertical-align: middle;\n    margin-left: 5px;\n"])));
var LearnMore = styled("button")(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    color: ", ";\n"], ["\n    color: ", ";\n"])), function (props) { return props.theme.colors["deep-blue-500"]; });
var templateObject_1, templateObject_2;
