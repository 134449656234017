var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import { compose, spacing } from "@mui/system";
import { useGridApiRef, } from "@mui/x-data-grid";
import classNames from "classnames";
import React, { memo } from "react";
import { BaseIcon } from "../BaseIcon";
import { Box } from "../Box";
import { Checkbox } from "../Checkbox";
import { Typography } from "../Typography";
import { DataGrid } from "./styled";
var ImplTable = React.forwardRef(function (_a, ref) {
    var page = _a.page, onPageChange = _a.onPageChange, pagination = _a.pagination, totalPages = _a.totalPages, totalItems = _a.totalItems, className = _a.className, props = __rest(_a, ["page", "onPageChange", "pagination", "totalPages", "totalItems", "className"]);
    var apiRef = useGridApiRef();
    var columnSortedAscendingIcon = function () { return (React.createElement(BaseIcon, { size: 16, icon: "DropDown", color: "black-1200" })); };
    var columnSortedDescendingIcon = function () { return (React.createElement(BaseIcon, { size: 16, icon: "DropDown", rotate: 180, color: "black-1200" })); };
    var columnUnsortedIcon = function () { return (React.createElement(BaseIcon, { size: 16, icon: "DropDown", color: "black-400" })); };
    var baseCheckbox = function (props) { return (React.createElement(Checkbox, __assign({ size: "medium" }, props))); };
    var noRowsOverlay = function () { return (React.createElement(Box, { height: "100%", width: "100%", display: "flex", flexDirection: "column", gap: "24px", alignItems: "center", justifyContent: "center" },
        React.createElement(BaseIcon, { width: 302, height: 102, icon: "EmptyList", color: "black-1200" }),
        React.createElement(Typography, { color: "black-500", type: "text14" }, "You don\u2019t have any transactions."))); };
    return (React.createElement(Box, { display: "flex", flexDirection: "column" },
        React.createElement(DataGrid, __assign({ apiRef: apiRef, ref: ref, disableColumnMenu: true, disableRowSelectionOnClick: true, disableColumnSelector: true, columnHeaderHeight: 34, rowHeight: 42, hideFooter: true, autoHeight: true, checkboxSelection: false, experimentalFeatures: { columnGrouping: true }, slots: {
                columnSortedAscendingIcon: columnSortedAscendingIcon,
                columnSortedDescendingIcon: columnSortedDescendingIcon,
                columnUnsortedIcon: columnUnsortedIcon,
                noRowsOverlay: noRowsOverlay,
                baseCheckbox: baseCheckbox,
            }, className: classNames(className, {
                Pagination: pagination && !!totalPages,
            }) }, props)),
        !!totalPages && pagination && onPageChange && (React.createElement(Pagination, { totalPages: totalPages || 0, totalItems: totalItems || 0, onPageChange: onPageChange, page: page || 0 }))));
});
var Pagination = function (_a) {
    var page = _a.page, totalPages = _a.totalPages, totalItems = _a.totalItems, onPageChange = _a.onPageChange;
    return (React.createElement(Box, { width: "100%", justifyContent: "space-between", display: "flex", padding: "24px 0px", alignItems: "center", bgColor: "general-white", borders: ["bottom", "left", "right"], boxSizing: "border-box" },
        React.createElement(Box, null, !!totalItems && (React.createElement(Typography, { type: "text12", color: "black-500" },
            (page + 1) * totalPages,
            " of ",
            totalItems,
            " items."))),
        React.createElement(Box, { display: "flex", alignItems: "center", gap: "4px" },
            React.createElement(BaseIcon, { icon: "DropDown", disabled: page === 0, rotate: 90, width: 25, height: 25, color: page === 0 ? "black-500" : undefined, onClick: function () { return onPageChange(page - 1); } }),
            React.createElement(Box, { display: "flex", alignItems: "center", borderRadius: "4px", paddingX: "12px", paddingY: "6px", gap: "4px", borderWidth: "1px", borderStyle: "solid", borderColor: "black-200" },
                React.createElement(Typography, { type: "text14" }, page + 1),
                React.createElement(Typography, { type: "text14", color: "black-400", fontWeight: "300" },
                    "/ ",
                    totalPages,
                    " pages")),
            React.createElement(BaseIcon, { width: 25, height: 25, disabled: !!totalPages && page === totalPages - 1, icon: "DropDown", rotate: -90, color: page === totalPages - 1 ? "black-500" : undefined, onClick: function () { return onPageChange(page + 1); } }))));
};
export var Table = memo(styled(ImplTable, {
    shouldForwardProp: function (prop) {
        return !spacing.filterProps.includes(prop);
    },
})(compose(spacing)));
