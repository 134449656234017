import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { Navigate, generatePath, useNavigate, useSearchParams, } from "react-router-dom";
import ProfileSidebar from "./components/Sidebar";
import UserSettings from "./components/Settings";
import UserAddresses from "./components/Addresses";
import UserVerificationBlocks from "./components/Verification/Blocks";
import { Box } from "@/shared/components";
import { useStoreContext } from "@/shared/hooks/useStore";
import { AuthStore } from "@/shared/stores/AuthStore";
import { ProfileType, ROUTES } from "@/routes";
import { useMedia } from "@/shared/hooks/useMedia";
import { useGetVerificationInfo } from "@/api/registration";
import { generateQueryPath } from "@/shared/utils/path";
export var ProfilePage = observer(function () {
    var auth = useStoreContext(AuthStore);
    var navigate = useNavigate();
    var search = useSearchParams()[0];
    var _a = useMedia(), desktop = _a.desktop, phone = _a.phone;
    var type = search.get("type");
    var data = useGetVerificationInfo().data;
    var status = data === null || data === void 0 ? void 0 : data.status;
    var component = useCallback(function () {
        switch (type) {
            case ProfileType.Settings:
                return React.createElement(UserSettings, null);
            case ProfileType.Verification:
                // return <UserVerification step={2} />;
                return React.createElement(UserVerificationBlocks, { status: status });
            case ProfileType.Addresses:
                return React.createElement(UserAddresses, null);
            default:
                return (React.createElement(Navigate, { to: generateQueryPath(ROUTES.profile, {}, { type: ProfileType.Settings }), replace: true }));
        }
    }, [type, status]);
    return (React.createElement(Box, { display: "flex", gap: "32px", my: "32px", flexDirection: "column", alignItems: "start", justifyContent: "center" }, phone ? (component()) : (React.createElement(Box, { display: "flex", alignItems: "start", justifyContent: "space-between", gap: desktop ? "96px" : "24px" },
        React.createElement(ProfileSidebar, { handleLogout: function () {
                var _a;
                auth.logout();
                navigate(generatePath(ROUTES.signin));
                (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.reload();
            } }),
        component()))));
});
