var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { BaseIcon } from "../BaseIcon";
import { StyledDialog } from "./styled";
export var Modal = function (_a) {
    var children = _a.children, isMobileFull = _a.isMobileFull, _b = _a.showCloseButton, showCloseButton = _b === void 0 ? true : _b, _c = _a.bgColor, bgColor = _c === void 0 ? "general-white" : _c, disablePadding = _a.disablePadding, props = __rest(_a, ["children", "isMobileFull", "showCloseButton", "bgColor", "disablePadding"]);
    return (React.createElement(StyledDialog, __assign({ container: document.getElementById("root"), "$bgColor": bgColor, "$disablePadding": disablePadding, "$isMobileFull": isMobileFull }, props),
        showCloseButton && (React.createElement(BaseIcon, { icon: "Close", position: "absolute", top: "0", right: "5px", bgColor: "black-50", padding: "0", size: 45, borderRadius: "12px", onClick: function (e) { var _a; return (_a = props.onClose) === null || _a === void 0 ? void 0 : _a.call(props, e, "backdropClick"); } })),
        children));
};
