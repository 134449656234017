var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { Box, FormControlInput, Modal, Typography } from "@/shared/components";
import { Button } from "@/shared/components/Button";
import { useStore } from "@/shared/hooks/useStore";
import { onChange } from "@/shared/utils/input";
import { UserAddressesStore } from "@/pages/ProfilePage/stores/UserAddressesStore";
import { useCreateRecipient } from "@/api/wallet/useCreateRecipient";
import { CounterpartyProfileType, CounterpartyRailType, CounterpartyType, } from "@/api/wallet/types";
export var AddAddress = observer(function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var isFeat = _a.isFeat, open = _a.open, onClose = _a.onClose;
    var userAddressesStore = useStore(UserAddressesStore);
    var mutateAsync = useCreateRecipient({}).mutateAsync;
    var onCryptoSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            mutateAsync({
                blockchain: userAddressesStore.blockchainNetwork,
                blockchainAddress: userAddressesStore.accountAddress,
                type: CounterpartyType.CRYPTO,
                rail: CounterpartyRailType.CRYPTO,
                profile: {
                    profileType: CounterpartyProfileType.INDIVIDUAL,
                    name: "asd",
                },
                disabled: false,
                walletType: "INSTITUTION",
            });
            return [2 /*return*/];
        });
    }); };
    return (React.createElement(Modal, { open: open, onClose: onClose, isMobileFull: true, showCloseButton: false },
        React.createElement(Box, { width: "100%", display: "flex", flexDirection: "column", gap: "16px" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "16px" },
                React.createElement(Typography, { type: "text20", fontWeight: 600, textAlign: "center" },
                    "Add ",
                    isFeat ? "Bank" : "Address")),
            isFeat ? (React.createElement(React.Fragment, null,
                React.createElement(FormControlInput, { outLabel: "Send money to", type: "text", value: userAddressesStore.bank, onChange: onChange(action(function (val) {
                        userAddressesStore.bank = val;
                    })), disabled: userAddressesStore.loading, variantError: "alert", error: (_b = userAddressesStore.errorsByKey.bank) === null || _b === void 0 ? void 0 : _b[0] }),
                React.createElement(FormControlInput, { outLabel: "Bank name", type: "text", value: userAddressesStore.bankName, onChange: onChange(action(function (val) {
                        userAddressesStore.bankName = val;
                    })), disabled: userAddressesStore.loading, variantError: "alert", error: (_c = userAddressesStore.errorsByKey.bankName) === null || _c === void 0 ? void 0 : _c[0] }),
                React.createElement(FormControlInput, { outLabel: "Routing number", type: "text", value: userAddressesStore.routingNumber, onChange: onChange(action(function (val) {
                        userAddressesStore.routingNumber = val;
                    })), disabled: userAddressesStore.loading, variantError: "alert", error: (_d = userAddressesStore.errorsByKey
                        .routingNumber) === null || _d === void 0 ? void 0 : _d[0] }),
                React.createElement(FormControlInput, { outLabel: "Account number", type: "text", value: userAddressesStore.accountNumber, onChange: onChange(action(function (val) {
                        userAddressesStore.accountNumber = val;
                    })), disabled: userAddressesStore.loading, variantError: "alert", error: (_e = userAddressesStore.errorsByKey
                        .accountNumber) === null || _e === void 0 ? void 0 : _e[0] }))) : (React.createElement(React.Fragment, null,
                React.createElement(FormControlInput, { outLabel: "Blockchain network", type: "text", value: userAddressesStore.blockchainNetwork, onChange: onChange(action(function (val) {
                        userAddressesStore.blockchainNetwork =
                            val;
                    })), disabled: userAddressesStore.loading, variantError: "alert", error: (_f = userAddressesStore.errorsByKey
                        .blockchainNetwork) === null || _f === void 0 ? void 0 : _f[0] }),
                React.createElement(FormControlInput, { outLabel: "Account address", type: "text", value: userAddressesStore.accountAddress, onChange: onChange(action(function (val) {
                        userAddressesStore.accountAddress = val;
                    })), disabled: userAddressesStore.loading, variantError: "alert", error: (_g = userAddressesStore.errorsByKey
                        .accountAddress) === null || _g === void 0 ? void 0 : _g[0] }),
                React.createElement(FormControlInput, { outLabel: "Address name", type: "text", value: userAddressesStore.addressName, onChange: onChange(action(function (val) {
                        userAddressesStore.addressName = val;
                    })), disabled: userAddressesStore.loading, variantError: "alert", error: (_h = userAddressesStore.errorsByKey.a) === null || _h === void 0 ? void 0 : _h[0] })))),
        React.createElement(Box, { width: "100%", display: "flex", flexDirection: "column", gap: "8px", mt: "16px" },
            React.createElement(Button, { type: "submit", width: "100%", onClick: function () { return onCryptoSubmit(); }, disabled: (isFeat
                    ? userAddressesStore.isBankFormValid
                    : userAddressesStore.isBlockchainFormValid) ||
                    userAddressesStore.loading },
                "Add ",
                isFeat ? "Bank" : "Address"),
            React.createElement(Button, { type: "submit", variant: "LightBlue", width: "100%", onClick: onClose, disabled: userAddressesStore.loading }, "Close"))));
});
