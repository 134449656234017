import { styled } from "@mui/material/styles";
import { DataGrid as DataGridMU } from "@mui/x-data-grid";
export var DataGrid = styled(DataGridMU, {
    shouldForwardProp: function (prop) { return !prop.startsWith("$"); },
})(function (_a) {
    var theme = _a.theme;
    return ({
        "--unstable_DataGrid-radius": "24px",
        "--DataGrid-overlayHeight": "400px",
        border: "none",
        borderColor: theme.colors["black-100"],
        background: theme.colors["general-white"],
        "&.Pagination": {
            borderBottomLeftRadius: "0",
            borderBottomRightRadius: "0",
        },
        "& .MuiDataGrid-withBorderColor": {
            borderColor: theme.colors["black-100"],
        },
        "& .MuiDataGrid-columnSeparator": {
            display: "none",
        },
        "& .MuiDataGrid-cell": {
            fontSize: "14px",
            padding: "0 10px",
            fontFamily: theme.fontFamily,
            position: "relative",
            // justifyContent: "end",
            "&:last-child": {
                paddingRight: "12px",
            },
            "&:first-child": {},
            "&:focus, &:focus-within": {
                outline: "solid transparent 0px",
            },
        },
        "& .MuiDataGrid-columnHeaderTitleContainer": {
        // justifyContent: "end",
        },
        "& .MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell": {
            borderBottomColor: theme.colors["black-50"],
        },
        "& .MuiDataGrid-row:hover, .MuiDataGrid-row.Mui-hovered": {
            borderColor: theme.colors["black-50"],
        },
        "&:not(.Selection) .MuiDataGrid-columnHeader": {
            "&:first-child": {
                paddingLeft: "12px",
            },
        },
        "& .MuiDataGrid-columnHeader": {
            "&.MuiDataGrid-columnHeader--alignRight": {
                "& .MuiDataGrid-columnHeaderTitleContainer,& .MuiDataGrid-columnHeaderDraggableContainer": {
                    flexDirection: "initial",
                },
            },
            "&.MuiDataGrid-columnHeader--sorted .MuiDataGrid-columnHeaderTitle": {
                color: theme.colors["black-1200"],
            },
            "& .MuiDataGrid-iconButtonContainer": {
                visibility: "visible",
            },
            padding: "0 10px",
            "&:last-child": {
                paddingRight: "12px",
            },
            "& .MuiCheckbox-indeterminate": {
                color: theme.colors["deep-blue-500"],
            },
            "& .MuiDataGrid-columnHeaderTitle": {
                fontSize: "12px",
                fontWeight: "600",
                fontFamily: theme.fontFamily,
                color: theme.colors["neutral-4"],
            },
            "&:focus-within": {
                outline: "solid transparent 0px",
            },
        },
    });
});
