var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import { BaseIcon, Typography } from "@/shared/components";
export var SquareBtn = function (_a) {
    var icon = _a.icon, to = _a.to, onClick = _a.onClick, _b = _a.type, type = _b === void 0 ? "gray" : _b, children = _a.children;
    var content = (React.createElement(React.Fragment, null,
        React.createElement(Inner, { bgColor: type === "blue" ? "deep-blue-500" : "black-100" },
            React.createElement(BaseIcon, { icon: icon, color: type === "blue" ? "general-white" : "black-1200" })),
        React.createElement(Typography, { type: "text14", color: type === "blue" ? "deep-blue-500" : "black-1200", whiteSpace: "nowrap" }, children)));
    return to ? (React.createElement(LinkWrapper, { to: to }, content)) : (React.createElement(Wrapper, { onClick: onClick }, content));
};
var LinkWrapper = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 5px;\n"], ["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 5px;\n"])));
var Wrapper = styled('button')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 5px;\n"], ["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 5px;\n"])));
var Inner = styled("div")(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 46px;\n    height: 46px;\n    background: ", ";\n    border-radius: 14px;\n    align-items: center;\n    justify-content: center;\n    display: flex;\n"], ["\n    width: 46px;\n    height: 46px;\n    background: ", ";\n    border-radius: 14px;\n    align-items: center;\n    justify-content: center;\n    display: flex;\n"])), function (props) { return props.theme.colors[props.bgColor]; });
var templateObject_1, templateObject_2, templateObject_3;
