var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { TokenIcon } from "../TokenIcon";
import { Amount } from "../Amount";
import { Box, Typography } from "@/shared/components";
import { Loader } from "@/shared/components/Loader";
import { useMedia } from "@/shared/hooks/useMedia";
export var WalletsTable = observer(function (_a) {
    var loaded = _a.loaded, balanceTotal = _a.balanceTotal, children = _a.children;
    var media = useMedia();
    return (React.createElement(Box, { bgColor: "general-white", borderRadius: "22px" },
        React.createElement(Box, { width: "100%", display: "flex", flexWrap: "wrap", position: "relative" },
            !loaded && (React.createElement(Loader, { position: "absolute", top: "50%", left: "50%", margin: "-12px 0 0 -12px", size: 24 })),
            React.createElement(List, { loaded: !!loaded, desktop: media.desktop },
                React.createElement(CardBlock, null,
                    React.createElement(Box, { padding: 4 },
                        React.createElement(Typography, { type: "text16", color: "text-secondary" }, "Your total balance"),
                        React.createElement(BalanceBlock, null,
                            React.createElement(TokenIcon, { size: 24, symbol: "usd" }),
                            balanceTotal && (React.createElement(Typography, { type: "text22Bold", color: "text" }, balanceTotal ? (React.createElement(Amount, { from: "usd", amount: balanceTotal })) : ("0")))))),
                children))));
});
var BalanceBlock = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    gap: 12px;\n    margin-top: 8px;\n    align-items: center;\n"], ["\n    display: flex;\n    gap: 12px;\n    margin-top: 8px;\n    align-items: center;\n"])));
var CardBlock = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 100%;\n    background-color: var(--neutral-1);\n    border-radius: 12px;\n    max-width: 265px;\n\n    @media (max-width: 1023px) {\n        max-width: 100%;\n    }\n"], ["\n    width: 100%;\n    background-color: var(--neutral-1);\n    border-radius: 12px;\n    max-width: 265px;\n\n    @media (max-width: 1023px) {\n        max-width: 100%;\n    }\n"])));
var List = styled("div")(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 100%;\n    display: grid;\n    grid-gap: 16px;\n    grid-template-columns: 1fr 1fr 1fr;\n    opacity: ", ";\n    transition: opacity 0.2s ease;\n    pointer-events: ", ";\n\n    @media (max-width: 1023px) {\n        grid-template-columns: 1fr 1fr;\n    }\n\n    @media (max-width: 576px) {\n        grid-template-columns: 1fr;\n    }\n"], ["\n    width: 100%;\n    display: grid;\n    grid-gap: 16px;\n    grid-template-columns: 1fr 1fr 1fr;\n    opacity: ", ";\n    transition: opacity 0.2s ease;\n    pointer-events: ", ";\n\n    @media (max-width: 1023px) {\n        grid-template-columns: 1fr 1fr;\n    }\n\n    @media (max-width: 576px) {\n        grid-template-columns: 1fr;\n    }\n"])), function (props) { return (props.loaded ? 1 : 0); }, function (props) { return (props.loaded ? "unset" : "none"); });
var templateObject_1, templateObject_2, templateObject_3;
