import { formattedAmount } from "@broxus/js-utils";
import * as React from "react";
import { Box, Button, Typography } from "@/shared/components";
import { AccountItem } from "@/shared/components/AccountItem";
import { Amount } from "@/shared/components/Amount";
import { TokenIcon } from "@/shared/components/TokenIcon";
export var WalletItem = function (_a) {
    var amount = _a.amount, symbol = _a.symbol, shortName = _a.shortName, id = _a.id, depositOpen = _a.depositOpen, withdrawOpen = _a.withdrawOpen;
    return (React.createElement(AccountItem, { key: id },
        React.createElement(Box, { display: "flex", flexDirection: "column", flexGrow: "1", gap: 3, padding: 4 },
            React.createElement(Box, { display: "flex", alignItems: "center", gap: 2 },
                React.createElement(TokenIcon, { symbol: symbol.toLowerCase(), size: 24 }),
                React.createElement(Typography, { type: "text16Bold", color: "text" }, shortName),
                React.createElement(Typography, { type: "text16", color: "black-400" }, shortName)),
            React.createElement(Box, { display: "flex", alignItems: "baseline", gap: 2 },
                amount ? (React.createElement(Typography, { type: "text16" },
                    formattedAmount(amount, undefined, {
                        decimalSeparator: ".",
                        digitsSeparator: ",",
                    }),
                    " ")) : (React.createElement(Typography, { type: "text16" }, 0)),
                React.createElement(Typography, { type: "text14", color: "black-400" }, amount ? React.createElement(Amount, { from: symbol, amount: amount }) : "0")),
            React.createElement(Box, { display: "flex", gap: 4 },
                React.createElement(Button, { width: "fit-content", padding: 0, variant: "Link", onClick: function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        depositOpen(true, symbol);
                    } }, "Deposit"),
                React.createElement(Button, { width: "fit-content", padding: 0, variant: "Link", onClick: function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        withdrawOpen(true, symbol);
                    } }, "Withdraw")))));
};
