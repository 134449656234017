import * as React from "react";
import styles from "./index.module.scss";
import { Button } from "@/shared/components";
import { Typography } from "@/shared/components/Typography";
import { preventDefault } from "@/shared/utils/prevent";
export var AuthForm = function (_a) {
    var title = _a.title, desc = _a.desc, footer = _a.footer, content = _a.content, showOnBack = _a.showOnBack, onBack = _a.onBack, onSubmit = _a.onSubmit;
    return (React.createElement("form", { className: styles.root, onSubmit: preventDefault(onSubmit) },
        React.createElement("div", { className: styles.header },
            title && (React.createElement(Typography, { type: "title26Neo", className: styles.title }, title)),
            desc && (React.createElement(Typography, { className: styles.desc, type: "text14", color: "black-1000" }, desc))),
        content && React.createElement("div", { className: styles.content }, content),
        footer && (React.createElement("div", { className: styles.footer },
            footer,
            showOnBack && (React.createElement(Button, { variant: "Link", width: "100%", fontWeight: 600, onClick: onBack }, "Back"))))));
};
