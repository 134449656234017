export var featColumns = [
    {
        justifyContent: "end",
        field: "bankName",
        headerName: "Bank name",
        resizable: false,
        sortable: false,
        flex: 1,
        // renderCell: ({ row }) => (
        //     <TransactionAmount
        //         status={row.status}
        //         amount={row.change}
        //         currency={row.currency}
        //         text="text14"
        //     />
        // ),
    },
    {
        field: "routingNumber",
        headerName: "Routing number",
        resizable: false,
        sortable: false,
        flex: 1,
        // renderCell: ({ row }) => (
        //     <TransactionAmount
        //         status={row.status}
        //         amount={row.change}
        //         currency={row.currency}
        //         text="text14"
        //     />
        // ),
    },
    {
        field: "accountNumber",
        headerName: "Account number",
        resizable: false,
        sortable: false,
        flex: 1,
        // renderCell: ({ value, row }) => (
        //     <Box display="flex" flexDirection="column">
        //         <Typography type="text14">
        //             {value}, {row.currency}
        //         </Typography>
        //         {getBenicifiaryInfo(row.reasonType, row.source)}
        //     </Box>
        // ),
    },
];
export var cryptoColumns = [
    {
        justifyContent: "end",
        field: "addressName",
        headerName: "Address name",
        resizable: false,
        sortable: false,
        flex: 1,
        // renderCell: ({ row }) => (
        //     <TransactionAmount
        //         status={row.status}
        //         amount={row.change}
        //         currency={row.currency}
        //         text="text14"
        //     />
        // ),
    },
    {
        field: "blockchain",
        headerName: "Blockchain",
        resizable: false,
        sortable: false,
        flex: 1,
        // renderCell: ({ row }) => (
        //     <TransactionAmount
        //         status={row.status}
        //         amount={row.change}
        //         currency={row.currency}
        //         text="text14"
        //     />
        // ),
    },
    {
        field: "accountAddress",
        headerName: "Account address",
        resizable: false,
        sortable: false,
        flex: 1,
        // renderCell: ({ value, row }) => (
        //     <Box display="flex" flexDirection="column">
        //         <Typography type="text14">
        //             {value}, {row.currency}
        //         </Typography>
        //         {getBenicifiaryInfo(row.reasonType, row.source)}
        //     </Box>
        // ),
    },
];
