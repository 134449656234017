import BigNumber from "bignumber.js";
import { makeAutoObservable } from "mobx";
var TransferAccountFormStore = /** @class */ (function () {
    function TransferAccountFormStore(balances, accountList) {
        this.balances = balances;
        this.accountList = accountList;
        this.loading = false;
        this.from = undefined;
        this.to = undefined;
        this.fromAmount = undefined;
        this.errors = [];
        makeAutoObservable(this, {}, { autoBind: true });
    }
    TransferAccountFormStore.prototype.reset = function () {
        this.fromAmount = undefined;
    };
    Object.defineProperty(TransferAccountFormStore.prototype, "isExchange", {
        get: function () {
            return this.fromAcc && this.toAcc
                ? this.fromAcc.currency !== this.toAcc.currency
                : false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferAccountFormStore.prototype, "fromAcc", {
        get: function () {
            return this.from ? this.accountList.fiatById[this.from] : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferAccountFormStore.prototype, "toAcc", {
        get: function () {
            return this.to ? this.accountList.fiatById[this.to] : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferAccountFormStore.prototype, "fromBalance", {
        get: function () {
            return this.from ? this.balances.data[this.from] : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferAccountFormStore.prototype, "toBalance", {
        get: function () {
            return this.to ? this.balances.data[this.to] : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferAccountFormStore.prototype, "fromEnough", {
        get: function () {
            return this.fromAmount && this.fromBalance
                ? new BigNumber(this.fromAmount).lte(this.fromBalance.balance)
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferAccountFormStore.prototype, "valid", {
        get: function () {
            return (this.fromAmount
                && this.toAcc
                && this.fromEnough
                && new BigNumber(this.fromAmount).gt(0));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferAccountFormStore.prototype, "toAmount", {
        get: function () {
            return this.toAcc
                ? this.isExchange
                    ? this.fromAmount
                    : this.fromAmount
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    return TransferAccountFormStore;
}());
export { TransferAccountFormStore };
