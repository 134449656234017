import { observer } from "mobx-react-lite";
import * as React from "react";
import { SignupPageStore } from "@/pages/SignupPage/stores/SignupPageStore";
import { Typography } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { Button } from "@/shared/components/Button";
import { useStoreContext } from "@/shared/hooks/useStore";
import { OTPInput } from "@/shared/components/OTPInput";
export var EmailCodeForm = observer(function () {
    var page = useStoreContext(SignupPageStore);
    return (React.createElement(AuthForm, { onBack: page.resetEmail, onSubmit: page.submitEmailCode, title: "Confirm your email", desc: React.createElement(React.Fragment, null,
            "Enter a code that we've sent to",
            " ",
            React.createElement(Typography, { color: "deep-blue-500" }, page.email)), content: React.createElement(OTPInput, { length: 5, onComplete: function (pin) {
                page.emailCode = pin;
            } }), footer: React.createElement(Button, { type: "submit", width: "100%", disabled: !page.isEmailCodeValid || page.loading }, "Confirm") }));
});
