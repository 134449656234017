var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import { Box, Button, Modal, Typography } from "@/shared/components";
import { DATE_FORMAT, formatDate } from "@/shared/lib";
export var TransferModal = function (_a) {
    var _b, _c;
    var isOpen = _a.isOpen, onClose = _a.onClose, info = _a.info, transfer = _a.transfer, isLoading = _a.isLoading, amount = _a.amount, currency = _a.currency, to = _a.to;
    var _d = React.useState(false), isOpenSuccess = _d[0], setIsOpenSuccess = _d[1];
    var _e = React.useState(false), isOpenErorr = _e[0], setIsOpenError = _e[1];
    var _f = React.useState(null), result = _f[0], setResult = _f[1];
    var handleTransfer = function () { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, transfer()];
                case 1:
                    result = _a.sent();
                    if (result) {
                        setResult(result);
                        setIsOpenSuccess(true);
                    }
                    else {
                        setIsOpenError(true);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { open: isOpen, onClose: onClose },
            React.createElement(Box, null,
                React.createElement(Typography, { type: "text32Bold", textAlign: "center", variant: "body1", mt: "50px" },
                    "You will send ",
                    amount,
                    " ",
                    currency,
                    " to",
                    " ",
                    React.createElement(Typography, { color: "deep-blue-500", type: "text32Bold" }, to)),
                React.createElement(Box, { mt: "70px", display: "flex", flexDirection: "column", gap: "30px", mb: "30px" },
                    React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }),
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" }, info.map(function (_a) {
                        var label = _a.label, value = _a.value;
                        return (React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                            React.createElement(Typography, { color: "black-500" }, label),
                            React.createElement(Typography, null, value)));
                    })),
                    React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" })),
                React.createElement(Button, { width: "100%", onClick: handleTransfer, loading: isLoading, disabled: isLoading }, "Continue"))),
        React.createElement(Modal, { showCloseButton: false, open: isOpenSuccess, onClose: function () { return setIsOpenSuccess(false); }, bgColor: "general-green" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "50px", alignItems: "center" },
                React.createElement(Typography, { color: "general-white", sx: { opacity: 0.6 } }, formatDate(result === null || result === void 0 ? void 0 : result.createdAt, DATE_FORMAT.date_with_time)),
                React.createElement(Typography, { type: "text28Bold", textAlign: "center", color: "general-white" }, "You've successfully exchanged the crypto"),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" },
                    React.createElement(Typography, { color: "general-white" }, (_b = result === null || result === void 0 ? void 0 : result.id) === null || _b === void 0 ? void 0 :
                        _b.slice(0, 3),
                        "...", (_c = result === null || result === void 0 ? void 0 : result.id) === null || _c === void 0 ? void 0 :
                        _c.slice(-4)),
                    React.createElement(Box, { bgColor: "general-white-transparent-10", borderRadius: "16px", padding: "10px 20px" },
                        React.createElement(Typography, { color: "general-white", textTransform: "uppercase" },
                            "+ ", result === null || result === void 0 ? void 0 :
                            result.amount,
                            " ", result === null || result === void 0 ? void 0 :
                            result.currency)))),
            React.createElement(Button, { mt: "100px", width: "100%", variant: "White", onClick: function () {
                    setIsOpenSuccess(false);
                } }, "Done")),
        React.createElement(Modal, { showCloseButton: false, open: isOpenErorr, onClose: function () { return setIsOpenSuccess(false); }, bgColor: "black-1200" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "50px", alignItems: "center" },
                React.createElement(Typography, { color: "general-white", sx: { opacity: 0.6 } }, formatDate(new Date().toISOString(), DATE_FORMAT.date_with_time)),
                React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center" },
                    React.createElement(Typography, { type: "text28Bold", variant: "h3", mb: "10px", textAlign: "center", color: "general-white" }, "Something went wrong"),
                    React.createElement(Typography, { color: "black-500" }, "We couldn't process your request."),
                    React.createElement(Typography, { color: "black-500" }, "Please try again."))),
            React.createElement(Box, { display: "flex", flexDirection: "column", width: "100%", alignItems: "center", gap: "20px" },
                React.createElement(Button, { mt: "200px", width: "100%", variant: "White", onClick: handleTransfer }, "Try again"),
                React.createElement(Typography, { color: "general-white" }, "Contact support")))));
};
