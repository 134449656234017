import React from "react";
import { Box } from "../Box";
export var EmptyItem = function () {
    // const { tablet } = useMedia()
    return (React.createElement(Box, { padding: "27px 30px", gap: "15px", borderWidth: 1, borderStyle: "solid", borderColor: "black-50", borderRadius: "22px", display: "flex", alignItems: "center" },
        React.createElement(Box, { width: 45, height: 45, bgColor: "black-50", borderRadius: "100%" }),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "12px" },
            React.createElement(Box, { bgColor: "black-50", borderRadius: "25px", width: "100px", height: "20px" }),
            React.createElement(Box, { bgColor: "black-50", borderRadius: "25px", width: "200px", height: "20px" }))));
};
