import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RecipientItem } from "../RecipientItem";
import { useGetRecipients } from "@/api/wallet";
import { ROUTES, TransferType } from "@/routes";
import { Box, Button, Modal, Typography } from "@/shared/components";
import { useMedia } from "@/shared/hooks/useMedia";
import { generateQueryPath } from "@/shared/utils/path";
export var RecipientList = function () {
    var _a;
    var media = useMedia();
    var data = useGetRecipients().data;
    var _b = useState(""), detailsId = _b[0], setDeteilsId = _b[1];
    var handleShowDetails = function (id) {
        setDeteilsId(id);
    };
    var handleCloseDetailsModal = function () {
        setDeteilsId("");
    };
    var selectedItem = data === null || data === void 0 ? void 0 : data.find(function (item) { return item.id === detailsId; });
    if (!(data === null || data === void 0 ? void 0 : data.length)) {
        return (React.createElement(Box, { height: "320px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" },
            React.createElement(Typography, { type: "text14", color: "black-400" }, "You have not saved any recipients yet")));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "grid", gridTemplateColumns: media.phone ? "1fr" : "1fr 1fr", gap: "20px" }, data === null || data === void 0 ? void 0 : data.map(function (item) { return (React.createElement(RecipientItem, { key: item.id, id: item.id, name: item.profile.name, account: item.accountNumber, onClick: handleShowDetails })); })),
        React.createElement(Modal, { open: !!detailsId, onClose: handleCloseDetailsModal, disablePadding: true, isMobileFull: media.phone },
            React.createElement(Box, { padding: "100px 50px 30px", bgColor: "black-50", display: "flex", flexDirection: "column", gap: "34px", width: "100%", boxSizing: "border-box" },
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px", alignItems: "center" },
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "12px" },
                        React.createElement(Typography, { type: "text24", textAlign: "center" }, selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.accountNumber),
                        React.createElement(Typography, { textAlign: "center" }, selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.profile.name)),
                    React.createElement(Button, { width: "250px", component: Link, to: generateQueryPath(ROUTES.transfer, {
                            type: TransferType.Recipient,
                        }, {
                            to: detailsId,
                        }) }, "Transfer funds"))),
            React.createElement(Box, { width: "100%", padding: "0 30px 30px", boxSizing: "border-box", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" },
                React.createElement(Box, { width: "100%" },
                    React.createElement(Box, { display: "flex", flexDirection: "column", padding: "15px 0" },
                        React.createElement(Typography, { type: "text14", color: "black-400" }, "Country"),
                        React.createElement(Typography, null, (_a = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.institutionAddress) === null || _a === void 0 ? void 0 : _a.country)),
                    React.createElement(Box, { bgColor: "black-50", height: "1px", m: "0 -30px" }),
                    React.createElement(Box, { display: "flex", flexDirection: "column", padding: "15px 0" },
                        React.createElement(Typography, { type: "text14", color: "black-400" }, "Bank name"),
                        React.createElement(Typography, null, selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.institutionName)),
                    (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.iban) && (React.createElement(React.Fragment, null,
                        React.createElement(Box, { m: "0 -30px", bgColor: "black-50", height: "1px" }),
                        React.createElement(Box, { display: "flex", flexDirection: "column", padding: "15px 0" },
                            React.createElement(Typography, { type: "text14", color: "black-400" }, "IBAN"),
                            React.createElement(Typography, null, selectedItem.iban)))),
                    (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.swiftBic) && (React.createElement(React.Fragment, null,
                        React.createElement(Box, { m: "0 -30px", bgColor: "black-50", height: "1px" }),
                        React.createElement(Box, { display: "flex", flexDirection: "column", padding: "15px 0" },
                            React.createElement(Typography, { type: "text14", color: "black-400" }, "BIC"),
                            React.createElement(Typography, null, selectedItem.swiftBic)))))))));
};
