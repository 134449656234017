// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WZ5fnilqRhIiTsv{display:flex;flex-wrap:wrap;gap:10px}.o54xyLxRGMtJdXx{position:relative;display:flex;align-items:center;gap:4px}`, "",{"version":3,"sources":["webpack://./src/shared/components/PasswordHint/index.module.scss"],"names":[],"mappings":"AAAA,iBACI,YAAA,CACA,cAAA,CACA,QAAA,CAGJ,iBACI,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":[".hint {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n}\n\n.row {\n    position: relative;\n    display: flex;\n    align-items: center;\n    gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hint": `WZ5fnilqRhIiTsv`,
	"row": `o54xyLxRGMtJdXx`
};
export default ___CSS_LOADER_EXPORT___;
