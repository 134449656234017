import { styled } from "@mui/material/styles";
import * as React from "react";
import { Overlay } from "@/shared/components/Overlay";
export var Popup = function (_a) {
    var _b = _a.width, width = _b === void 0 ? "545px" : _b, children = _a.children, onClose = _a.onClose;
    return (React.createElement(Overlay, null,
        React.createElement(Bg, { onClick: onClose }),
        React.createElement(Container, { width: width }, children)));
};
var Bg = styled("div")(function () { return ({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: "-1",
    background: "rgba(0,0,0,0.2)",
    animation: "open 0.25s",
    "@keyframes open": {
        from: {
            opacity: 0,
        },
        to: {
            opacity: 1,
        },
    },
}); });
var Container = styled("div")(function (_a) {
    var width = _a.width, theme = _a.theme;
    return ({
        width: "100%",
        maxWidth: width,
        position: "relative",
        margin: "20px",
        padding: "16px",
        borderRadius: "12px",
        backgroundColor: theme.colors["general-white"],
        animation: "show 0.25s",
        "@keyframes show": {
            from: {
                opacity: 0,
                transform: "translateY(10px)",
            },
            to: {
                opacity: 1,
                transform: "none",
            },
        },
    });
});
