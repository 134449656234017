var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { Settings2FA } from "../TwoFA";
import { UserSettingsStore } from "../../stores/UserSettingsStore";
import { TwoFactorAuthPopup } from "../TwoFactorAuth";
import ConfirmPassword from "./confirm";
import { Box, Button, FormControlInput, Typography } from "@/shared/components";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
import { onChange } from "@/shared/utils/input";
import { useMedia } from "@/shared/hooks/useMedia";
import { useToggler } from "@/shared/hooks/useToggler";
import { AuthStore } from "@/shared/stores/AuthStore";
import { PasswordHint } from "@/shared/components/PasswordHint";
var UserSettings = observer(function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var desktop = useMedia().desktop;
    var tfaPopup = useToggler();
    var confirmPasswordPopup = useToggler();
    var auth = useStoreContext(AuthStore);
    var settingsStore = useStore(UserSettingsStore, auth);
    var handleUpdatePassword = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!((_b = (_a = auth.userInfo) === null || _a === void 0 ? void 0 : _a.primaryEmail) === null || _b === void 0 ? void 0 : _b.email))
                        return [2 /*return*/];
                    return [4 /*yield*/, settingsStore.submitPasswordCode(auth.userInfo.primaryEmail.email)];
                case 1:
                    _c.sent();
                    confirmPasswordPopup.on();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        tfaPopup.enabled && React.createElement(TwoFactorAuthPopup, { onClose: tfaPopup.off }),
        confirmPasswordPopup.enabled && (React.createElement(ConfirmPassword, { email: (_c = (_b = (_a = auth.userInfo) === null || _a === void 0 ? void 0 : _a.primaryEmail) === null || _b === void 0 ? void 0 : _b.email) !== null && _c !== void 0 ? _c : "", password: settingsStore.newPassword, loading: settingsStore.loading, onConfirm: settingsStore.submitPassword, onClose: confirmPasswordPopup.off })),
        React.createElement(Box, { mb: "20px", bgColor: "general-white", borderRadius: "22px", width: desktop ? "550px" : "auto", display: "flex", flexDirection: "column" },
            React.createElement(Typography, { type: "title26Neo" }, "Settings"),
            React.createElement(Box, { marginTop: "32px" },
                React.createElement(Typography, { type: "text14Bold" }, "Main information"),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "12px", marginTop: "12px" },
                    React.createElement(Box, { display: "flex", alignItems: "center", gap: "12px" },
                        React.createElement(FormControlInput, { type: "text", hiddenLabel: true, outLabel: "First name", placeholder: "First name", width: "100%", error: (_d = settingsStore.errorsByKey.firstName) === null || _d === void 0 ? void 0 : _d[0], variantError: "alert", disabled: settingsStore.loading, value: settingsStore.firstName, onChange: onChange(action(function (value) {
                                settingsStore.firstName = value;
                            })) }),
                        React.createElement(FormControlInput, { type: "text", hiddenLabel: true, outLabel: "Last name", placeholder: "Last name", width: "100%", error: (_e = settingsStore.errorsByKey.lastName) === null || _e === void 0 ? void 0 : _e[0], variantError: "alert", disabled: settingsStore.loading, value: settingsStore.lastName, onChange: onChange(action(function (value) {
                                settingsStore.lastName = value;
                            })) })),
                    React.createElement(FormControlInput, { type: "text", hiddenLabel: true, outLabel: "Email", placeholder: "Email", width: "100%", error: (_f = settingsStore.errorsByKey.email) === null || _f === void 0 ? void 0 : _f[0], variantError: "alert", disabled: settingsStore.loading, value: settingsStore.email, onChange: onChange(action(function (value) {
                            settingsStore.email = value;
                        })) }),
                    React.createElement(FormControlInput, { type: "text", hiddenLabel: true, outLabel: "Street address", placeholder: "Street address", width: "100%", error: (_g = settingsStore.errorsByKey.streetAddress) === null || _g === void 0 ? void 0 : _g[0], variantError: "alert", disabled: settingsStore.loading, value: settingsStore.streetAddress, onChange: onChange(action(function (value) {
                            settingsStore.streetAddress = value;
                        })) }),
                    React.createElement(Button, { size: "s", marginLeft: "auto", disabled: !settingsStore.isMainFormValid ||
                            settingsStore.loading, onClick: settingsStore.submitForm }, "Save changes"))),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "12px", marginTop: "32px" },
                React.createElement(Typography, { type: "text14Bold" }, "Your password"),
                React.createElement(Settings2FA, { onClick: tfaPopup.on }),
                React.createElement(Box, { display: "flex", alignItems: "start", gap: "12px" },
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "8px", width: "100%" },
                        React.createElement(FormControlInput, { type: "password", hiddenLabel: true, outLabel: "New password", placeholder: "New password", width: "100%", error: (_h = settingsStore.errorsByKey.newPassword) === null || _h === void 0 ? void 0 : _h[0], variantError: "alert", disabled: settingsStore.loading, value: settingsStore.newPassword, onChange: onChange(action(function (value) {
                                settingsStore.newPassword = value;
                            })) }),
                        React.createElement(PasswordHint, { digit: settingsStore.passwordValidation.digit, length: settingsStore.passwordValidation.length, special: settingsStore.passwordValidation.special, lowercase: settingsStore.passwordValidation.lowercase, uppercase: settingsStore.passwordValidation.uppercase })),
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "8px", width: "100%" },
                        React.createElement(FormControlInput, { type: "password", hiddenLabel: true, outLabel: "Confirm password", placeholder: "Confirm password", width: "100%", error: (_j = settingsStore.errorsByKey
                                .confirmPassword) === null || _j === void 0 ? void 0 : _j[0], variantError: "alert", disabled: settingsStore.loading, value: settingsStore.confirmPassword, onChange: onChange(action(function (value) {
                                settingsStore.confirmPassword = value;
                            })) }))),
                React.createElement(Button, { size: "s", marginLeft: "auto", loading: settingsStore.loading, disabled: !settingsStore.isPasswordValid ||
                        settingsStore.loading, onClick: handleUpdatePassword }, "Update password")))));
});
export default UserSettings;
