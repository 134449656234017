import AlertClose from "./alert-close.svg";
import Back from "./back.svg";
import Bank from "./bank.svg";
import Burger from "./burger.svg";
import Check from "./check.svg";
import Checked from "./checked.svg";
import Close from "./close.svg";
import ClosedEye from "./closed-eye.svg";
import CircleAuth from "./circle-auth.svg";
import Cross from "./cross.svg";
import Crypto from "./crypto.svg";
import Deposit from "./deposit.svg";
import Doc from "./doc.svg";
import Download from "./download.svg";
import DropDown from "./drop-down.svg";
import Exchange from "./exchange.svg";
import Eye from "./eye.svg";
import Failed from "./failed.svg";
import Help from "./help.svg";
import Info from "./info.svg";
import Lock from "./lock.svg";
import LogoSmall from "./logo-small.svg";
import Logo from "./logo.svg";
import MenuCompany from "./menu-company.svg";
import MenuDashboard from "./menu-dashboard.svg";
import MenuTransactions from "./menu-transactions.svg";
import Navigation from "./navigation.svg";
import PaymentTypeCard from "./payment-type-card.svg";
import PaymentTypeCrypto from "./payment-type-crypto.svg";
import PaymentTypeOther from "./payment-type-other.svg";
import Pending from "./pending.svg";
import Plus from "./plus.svg";
import Qr from "./qr.svg";
import Radio from "./radio.svg";
import Recipient from "./recipient.svg";
import Right from "./right.svg";
import Search from "./search.svg";
import Spinner from "./spinner.svg";
import Success from "./success.svg";
import Swap from "./swap.svg";
import Transfer from "./transfer.svg";
import UnChecked from "./unchecked.svg";
import More from "./more.svg";
import Account from "./account.svg";
import Trade from "./trade.svg";
import Transactions from "./transactions.svg";
import Wallet from "./wallet.svg";
import EmptyList from "./empty-list.svg";
import SwapArrow from "./swap-arrow.svg";
import Settings from "./settings.svg";
import Verification from "./verification.svg";
import Banks from "./banks.svg";
import Logout from "./logout.svg";
import CheckSmall from "./check-small.svg";
import Company from "./company.svg";
import Individual from "./individual.svg";
import Attach from "./attach.svg";
import Hourglass from "./hourglass.svg";
import N901 from "./n901.svg";
import USDT from "./usdt.svg";
import ETH from "./eth.svg";
import EVER from "./ever.svg";
import BSC from "./bnb.svg";
import TRON from "./tron.svg";
import Verified from "./verified.svg";
export var Icons = {
    N901: N901,
    USDT: USDT,
    ETH: ETH,
    EVER: EVER,
    TON: EVER,
    BSC: BSC,
    TRON: TRON,
    Account: Account,
    Eye: Eye,
    ClosedEye: ClosedEye,
    Back: Back,
    CircleAuth: CircleAuth,
    Logo: Logo,
    UnChecked: UnChecked,
    Checked: Checked,
    AlertClose: AlertClose,
    DropDown: DropDown,
    Close: Close,
    Cross: Cross,
    Doc: Doc,
    Pending: Pending,
    Failed: Failed,
    LogoSmall: LogoSmall,
    MenuCompany: MenuCompany,
    MenuDashboard: MenuDashboard,
    MenuTransactions: MenuTransactions,
    Help: Help,
    Burger: Burger,
    Lock: Lock,
    Navigation: Navigation,
    Deposit: Deposit,
    Transfer: Transfer,
    Exchange: Exchange,
    Info: Info,
    Plus: Plus,
    Spinner: Spinner,
    Right: Right,
    Radio: Radio,
    PaymentTypeCard: PaymentTypeCard,
    PaymentTypeCrypto: PaymentTypeCrypto,
    PaymentTypeOther: PaymentTypeOther,
    Download: Download,
    Success: Success,
    Search: Search,
    Trade: Trade,
    Transactions: Transactions,
    Wallet: Wallet,
    Qr: Qr,
    Swap: Swap,
    Crypto: Crypto,
    Recipient: Recipient,
    Bank: Bank,
    Check: Check,
    More: More,
    EmptyList: EmptyList,
    SwapArrow: SwapArrow,
    Settings: Settings,
    Verification: Verification,
    Banks: Banks,
    Logout: Logout,
    CheckSmall: CheckSmall,
    Company: Company,
    Individual: Individual,
    Attach: Attach,
    Hourglass: Hourglass,
    Verified: Verified,
};
