import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { PasswordResetPageStore } from "@/pages/PasswordResetPage/stores/PasswordResetPageStore";
import { Box, FormControlInput, Typography } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { Button } from "@/shared/components/Button";
import { PasswordHint } from "@/shared/components/PasswordHint";
import { useStoreContext } from "@/shared/hooks/useStore";
import { onChange } from "@/shared/utils/input";
import { ROUTES } from "@/routes";
export var NewPasswordForm = observer(function () {
    var _a;
    var page = useStoreContext(PasswordResetPageStore);
    var navigate = useNavigate();
    return (React.createElement(AuthForm, { onBack: function () { return navigate(generatePath(ROUTES.root)); }, onSubmit: page.submitCode, title: "Reset your password", desc: React.createElement(Box, { display: "flex", flexDirection: "column", gap: "6px", paddingX: "16px", paddingY: "12px", bgColor: "negative-bg", borderRadius: "8px" },
            React.createElement(Typography, { type: "text12", color: "danger-text-primary" }, "When you complete the password reset process your account is put on a 5 day security hold/review."),
            React.createElement(Typography, { type: "text12", color: "danger-text-secondary" }, "This gives you time to contact us in case your email was compromised and someone is trying to compromise your Tether account.")), content: React.createElement(React.Fragment, null,
            React.createElement(FormControlInput, { type: "password", outLabel: "New password", placeholder: "New password", hiddenLabel: true, value: page.password, onChange: onChange(action(function (value) {
                    page.password = value;
                })), disabled: page.loading, variantError: "alert", error: (_a = page.errors[0]) === null || _a === void 0 ? void 0 : _a.description }),
            React.createElement(PasswordHint, { digit: page.passwordValidation.digit, length: page.passwordValidation.length, lowercase: page.passwordValidation.lowercase, special: page.passwordValidation.special, uppercase: page.passwordValidation.uppercase }),
            React.createElement(FormControlInput, { type: "password", placeholder: "Confirm password", outLabel: "Confirm password", hiddenLabel: true, value: page.passwordRepeat, onChange: onChange(action(function (value) {
                    page.passwordRepeat = value;
                })), disabled: page.loading, variantError: "alert" }),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "8px", marginTop: "24px" },
                React.createElement(FormControlInput, { placeholder: "Check your 2FA app", outLabel: "Google Authenticator Code", hiddenLabel: true, value: page.authenticator, onChange: onChange(action(function (value) {
                        page.authenticator = value;
                    })), disabled: page.loading, variantError: "alert" }),
                React.createElement(Typography, { type: "text12Bold" },
                    React.createElement("a", { href: "/" }, "Lost 2FA device ?")))), footer: React.createElement(Button, { width: "100%", type: "submit", disabled: page.loading || !page.isPasswordValid }, "Send verification link") }));
});
