var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material";
import * as React from "react";
import { Box } from "@/shared/components";
export var AccountItem = function (_a) {
    var children = _a.children;
    return (React.createElement(Wrapper, null, children));
};
var Wrapper = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    width: 100%;\n    border-radius: 8px;\n    border: 1px solid ", ";\n"], ["\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    width: 100%;\n    border-radius: 8px;\n    border: 1px solid ", ";\n"])), function (props) { return props.theme.colors["input-border"]; });
var templateObject_1;
