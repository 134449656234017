import { useQuery } from "@tanstack/react-query";
import { currencyApi } from "./api";
export var useGetCurrency = function () {
    return useQuery({
        queryKey: ["getAllTokens"],
        queryFn: function () { return currencyApi.currency.getAllCurrencies(); },
        staleTime: 100000,
        gcTime: 100000,
        select: function (data) {
            return data.data;
        },
    });
};
