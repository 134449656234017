import * as React from "react";
import { BaseIcon } from "../BaseIcon";
import styles from "./index.module.scss";
import { Typography } from "@/shared/components/Typography";
export var PasswordHint = function (_a) {
    var uppercase = _a.uppercase, lowercase = _a.lowercase, special = _a.special, digit = _a.digit, length = _a.length;
    return (React.createElement("div", { className: styles.hint },
        React.createElement("div", { className: styles.row },
            !length ? (React.createElement(BaseIcon, { icon: "Cross", size: 12, borderRadius: "12px" })) : (React.createElement(BaseIcon, { icon: "Check", size: 12, borderRadius: "12px" })),
            React.createElement(Typography, { type: "text12", color: length ? "general-green" : "black-500" }, "8 Symbols")),
        React.createElement("div", { className: styles.row },
            !uppercase ? (React.createElement(BaseIcon, { icon: "Cross", size: 12, borderRadius: "12px" })) : (React.createElement(BaseIcon, { icon: "Check", size: 12, borderRadius: "12px" })),
            React.createElement(Typography, { type: "text12", color: uppercase ? "general-green" : "black-500" }, "Upper Case")),
        React.createElement("div", { className: styles.row },
            !lowercase ? (React.createElement(BaseIcon, { icon: "Cross", size: 12, borderRadius: "12px" })) : (React.createElement(BaseIcon, { icon: "Check", size: 12, borderRadius: "12px" })),
            React.createElement(Typography, { type: "text12", color: lowercase ? "general-green" : "black-500" }, "Lower Case")),
        React.createElement("div", { className: styles.row },
            !special ? (React.createElement(BaseIcon, { icon: "Cross", size: 12, borderRadius: "12px" })) : (React.createElement(BaseIcon, { icon: "Check", size: 12, borderRadius: "12px" })),
            React.createElement(Typography, { type: "text12", color: special ? "general-green" : "black-500" }, "Special")),
        React.createElement("div", { className: styles.row },
            !digit ? (React.createElement(BaseIcon, { icon: "Cross", size: 12, borderRadius: "12px" })) : (React.createElement(BaseIcon, { icon: "Check", size: 12, borderRadius: "12px" })),
            React.createElement(Typography, { type: "text12", color: digit ? "general-green" : "black-500" }, "Number"))));
};
