import React from "react";
import { useGetBalanceLogReceipt } from "@/api/wallet/useGetBalanceLogReceipt";
import { Box, Typography } from "@/shared/components";
import { DATE_FORMAT, formatDate } from "@/shared/lib";
import { sliceAddress } from "@/shared/utils/slice-address";
export var CommonInfo = function (_a) {
    var _b, _c, _d, _e;
    var transaction = _a.transaction;
    var data = useGetBalanceLogReceipt((transaction === null || transaction === void 0 ? void 0 : transaction.id) || "").data;
    console.log("🚀 ~ data>>>>>>>>:", data);
    if (!transaction)
        return null;
    function parseStatus(str) {
        var _a, _b;
        var source = JSON.parse(str);
        return (_b = (_a = source === null || source === void 0 ? void 0 : source.value) === null || _a === void 0 ? void 0 : _a.status) === null || _b === void 0 ? void 0 : _b.toLowerCase();
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", padding: "20px" },
            React.createElement(Typography, { color: "black-400", type: "text14", mb: "15px" }, formatDate(data === null || data === void 0 ? void 0 : data.createdAt, DATE_FORMAT.date_with_time)),
            React.createElement(Typography, { type: "text30", textAlign: "center" }, transaction === null || transaction === void 0 ? void 0 : transaction.operation)),
        React.createElement(Box, { bgColor: "general-white", width: "100%" },
            React.createElement(Box, { display: "flex", alignItems: "center", gap: "8px", padding: "15px", borderColor: "black-50", borders: ["bottom"], borderWidth: "1px", borderStyle: "solid" },
                React.createElement(Typography, { color: "black-400" }, "Hash ID:"),
                React.createElement(Typography, { color: "deep-blue-500" }, ((_b = data === null || data === void 0 ? void 0 : data.receipt) === null || _b === void 0 ? void 0 : _b.rail_reference)
                    ? sliceAddress((_c = data === null || data === void 0 ? void 0 : data.receipt) === null || _c === void 0 ? void 0 : _c.rail_reference)
                    : "Not found")),
            React.createElement(Box, { display: "flex", alignItems: "center", gap: "8px", padding: "15px", borderColor: "black-50", borders: ["bottom"], borderWidth: "1px", borderStyle: "solid" },
                React.createElement(Typography, { color: "black-400" }, "Transaction ID:"),
                React.createElement(Typography, null, sliceAddress(transaction.account))),
            React.createElement(Box, { display: "flex", alignItems: "center", gap: "8px", padding: "15px", borderColor: "black-50", borders: ["bottom"], borderWidth: "1px", borderStyle: "solid" },
                React.createElement(Typography, { color: "black-400" }, "Amount:"),
                React.createElement(Box, { display: "flex", alignItems: "center", gap: "2px" },
                    React.createElement(Typography, null, Number(transaction.change).toFixed(4)),
                    React.createElement(Typography, null, transaction.currency))),
            React.createElement(Box, { display: "flex", alignItems: "center", gap: "8px", padding: "15px", borderColor: "black-50", borders: ["bottom"], borderWidth: "1px", borderStyle: "solid" },
                React.createElement(Typography, { color: "black-400" }, "Status:"),
                React.createElement(Typography, null, (_e = parseStatus((_d = transaction.source) !== null && _d !== void 0 ? _d : "")) !== null && _e !== void 0 ? _e : "-")))));
};
