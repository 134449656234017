import { styled } from "@mui/material/styles";
export var StyledImg = styled("img")(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b[theme.breakpoints.down("md")] = {
            width: "280px",
            height: "280px",
        },
        _b);
});
