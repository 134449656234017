import { CounterpartyProfileType, CounterpartyRailType } from "../../../api/wallet/types";
import { countries } from "@/shared/constants/countries";
export var US_CONFIG = [
    {
        type: CounterpartyRailType.FEDWIRE,
        typeName: "Fedwire",
        recepient: [
            {
                label: "Recipient type",
                field: "profile.profileType",
                grid: "1/-1",
                type: "select",
                options: [
                    {
                        key: CounterpartyProfileType.CORPORATION,
                        label: "Corporation",
                    },
                    {
                        key: CounterpartyProfileType.INDIVIDUAL,
                        label: "Individual",
                    },
                ],
            },
            {
                label: "Name",
                field: "profile.name",
                grid: "1/-1",
                type: "input",
            },
            // {
            //     label: "Name",
            //     field: "profile.email",
            //     grid: "1/-1",
            //     type: "input",
            // },
            // {
            //     label: "Name",
            //     field: "profile.telephoneNumber",
            //     grid: "1/-1",
            //     type: "input",
            // },
            // {
            //     label: "Name",
            //     field: "profile.taxReferenceNumber",
            //     grid: "1/-1",
            //     type: "input",
            // },
            {
                label: "Country",
                field: "profile.address.country",
                grid: "span 1",
                type: "select",
                options: countries.map(function (item) { return ({
                    key: item.codeAlpha3,
                    label: "".concat(item.flag, " ").concat(item.name),
                }); }),
            },
            {
                label: "City",
                field: "profile.address.town",
                grid: "span 1",
                type: "input",
            },
            // {
            //     label: "Registered State/Province",
            //     field: "profile.address.town",
            //     grid: "span 1",
            //     type: "input",
            // },
            {
                label: "Zip Code/Postal Code",
                field: "profile.address.postCode",
                grid: "span 1",
                type: "input",
            },
            {
                label: "Address",
                field: "profile.address.street",
                grid: "1/-1",
                type: "input",
            },
        ],
        bank: [
            {
                label: "Account number",
                field: "accountNumber",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Routing number",
                field: "routingNumber",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Institution name",
                field: "institutionName",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "City",
                field: "institutionAddress.town",
                grid: "1/-1",
                type: "input",
            },
            // {
            //     label: "Registered State/Province",
            //     field: "state",
            //     grid: "span 1",
            //     type: "select",
            //     options: [] as any,
            // },
            {
                label: "Address",
                field: "institutionAddress.street",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Zip Code/Postal Code",
                field: "institutionAddress.postCode",
                grid: "span 1",
                type: "input",
            },
        ],
    },
    {
        type: CounterpartyRailType.ACH,
        typeName: "ACH",
        recepient: [
            {
                label: "Recipient type",
                field: "profile.profileType",
                grid: "1/-1",
                type: "select",
                options: [
                    {
                        key: CounterpartyProfileType.CORPORATION,
                        label: "Corporation",
                    },
                    {
                        key: CounterpartyProfileType.INDIVIDUAL,
                        label: "Individual",
                    },
                ],
            },
            {
                label: "Name",
                field: "profile.name",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Country",
                field: "profile.address.country",
                grid: "span 1",
                type: "select",
                options: countries.map(function (item) { return ({
                    key: item.codeAlpha3,
                    label: "".concat(item.flag, " ").concat(item.name),
                }); }),
            },
            {
                label: "City",
                field: "profile.address.town",
                grid: "span 1",
                type: "input",
            },
            // {
            //     label: "Registered State/Province",
            //     field: "state",
            //     grid: "span 1",
            //     type: "input",
            // },
            {
                label: "Address",
                field: "profile.address.street",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Zip Code/Postal Code",
                field: "profile.address.postCode",
                grid: "span 1",
                type: "input",
            },
        ],
        bank: [
            {
                label: "Account number",
                field: "accountNumber",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Routing number",
                field: "routingNumber",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Institution name",
                field: "institutionName",
                grid: "1/-1",
                type: "input",
            },
        ],
    },
    {
        type: CounterpartyRailType.SWIFT,
        typeName: "SWIFT",
        recepient: [
            {
                label: "Recipient type",
                field: "profile.profileType",
                grid: "1/-1",
                type: "select",
                options: [
                    {
                        key: CounterpartyProfileType.CORPORATION,
                        label: "Corporation",
                    },
                    {
                        key: CounterpartyProfileType.INDIVIDUAL,
                        label: "Individual",
                    },
                ],
            },
            {
                label: "Name",
                field: "profile.name",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Country",
                field: "profile.address.country",
                grid: "span 1",
                type: "select",
                options: countries.map(function (item) { return ({
                    key: item.codeAlpha3,
                    label: "".concat(item.flag, " ").concat(item.name),
                }); }),
            },
            {
                label: "City",
                field: "profile.address.town",
                grid: "span 1",
                type: "input",
            },
            // {
            //     label: "Registered State/Province",
            //     field: "state",
            //     grid: "span 1",
            //     type: "input",
            // },
            {
                label: "Address",
                field: "profile.address.street",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Zip Code/Postal Code",
                field: "profile.address.postCode",
                grid: "span 1",
                type: "input",
            },
        ],
        bank: [
            {
                label: "Account number",
                field: "accountNumber",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "IBAN",
                field: "iban",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "SWIFT (BIC) code",
                field: "swiftBic",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Institution name",
                field: "institutionName",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "City",
                field: "institutionAddress.town",
                grid: "1/-1",
                type: "input",
            },
            // {
            //     label: "Registered State/Province",
            //     field: "state",
            //     grid: "span 1",
            //     type: "select",
            //     options: [] as any,
            // },
            {
                label: "Address",
                field: "institutionAddress.street",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Zip Code/Postal Code",
                field: "institutionAddress.postCode",
                grid: "span 1",
                type: "input",
            },
        ],
    },
];
export var EU_CONFIG = [
    {
        type: CounterpartyRailType.SEPA_CT,
        typeName: "SEPA",
        recepient: [
            {
                label: "Recipient type",
                field: "profile.profileType",
                grid: "1/-1",
                type: "select",
                options: [
                    {
                        key: CounterpartyProfileType.CORPORATION,
                        label: "Corporation",
                    },
                    {
                        key: CounterpartyProfileType.INDIVIDUAL,
                        label: "Individual",
                    },
                ],
            },
            {
                label: "Name",
                field: "profile.name",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Country",
                field: "profile.address.country",
                grid: "span 1",
                type: "select",
                options: countries.map(function (item) { return ({
                    key: item.codeAlpha3,
                    label: "".concat(item.flag, " ").concat(item.name),
                }); }),
            },
            {
                label: "City",
                field: "profile.address.town",
                grid: "span 1",
                type: "input",
            },
            // {
            //     label: "Registered State/Province",
            //     field: "state",
            //     grid: "span 1",
            //     type: "input",
            // },
            {
                label: "Address",
                field: "profile.address.street",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Zip Code/Postal Code",
                field: "profile.address.postCode",
                grid: "span 1",
                type: "input",
            },
        ],
        bank: [
            {
                label: "Account number",
                field: "accountNumber",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "IBAN",
                field: "iban",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "SWIFT (BIC) code",
                field: "swiftBic",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Institution name",
                field: "institutionName",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "City",
                field: "institutionAddress.town",
                grid: "1/-1",
                type: "input",
            },
            // {
            //     label: "Registered State/Province",
            //     field: "state",
            //     grid: "span 1",
            //     type: "select",
            //     options: [] as any,
            // },
            {
                label: "Address",
                field: "institutionAddress.street",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Zip Code/Postal Code",
                field: "institutionAddress.postCode",
                grid: "span 1",
                type: "input",
            },
        ],
    },
    {
        type: CounterpartyRailType.SWIFT,
        typeName: "SWIFT",
        recepient: [
            {
                label: "Recipient type",
                field: "profile.profileType",
                grid: "1/-1",
                type: "select",
                options: [
                    {
                        key: CounterpartyProfileType.CORPORATION,
                        label: "Corporation",
                    },
                    {
                        key: CounterpartyProfileType.INDIVIDUAL,
                        label: "Individual",
                    },
                ],
            },
            {
                label: "Name",
                field: "profile.name",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Country",
                field: "profile.address.country",
                grid: "span 1",
                type: "select",
                options: countries.map(function (item) { return ({
                    key: item.codeAlpha3,
                    label: "".concat(item.flag, " ").concat(item.name),
                }); }),
            },
            {
                label: "City",
                field: "profile.address.town",
                grid: "span 1",
                type: "input",
            },
            // {
            //     label: "Registered State/Province",
            //     field: "state",
            //     grid: "span 1",
            //     type: "input",
            // },
            {
                label: "Address",
                field: "profile.address.street",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Zip Code/Postal Code",
                field: "profile.address.postCode",
                grid: "span 1",
                type: "input",
            },
        ],
        bank: [
            {
                label: "Account number",
                field: "accountNumber",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "IBAN",
                field: "iban",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "SWIFT (BIC) code",
                field: "swiftBic",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Institution name",
                field: "institutionName",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "City",
                field: "institutionAddress.town",
                grid: "1/-1",
                type: "input",
            },
            // {
            //     label: "Registered State/Province",
            //     field: "state",
            //     grid: "span 1",
            //     type: "select",
            //     options: [] as any,
            // },
            {
                label: "Address",
                field: "institutionAddress.street",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Zip Code/Postal Code",
                field: "institutionAddress.postCode",
                grid: "span 1",
                type: "input",
            },
        ],
    },
];
export var OTHER_CONFIG = [
    {
        type: CounterpartyRailType.SEPA_CT,
        typeName: "SEPA",
        recepient: [
            {
                label: "Recipient type",
                field: "profile.profileType",
                grid: "1/-1",
                type: "select",
                options: [
                    {
                        key: CounterpartyProfileType.CORPORATION,
                        label: "Corporation",
                    },
                    {
                        key: CounterpartyProfileType.INDIVIDUAL,
                        label: "Individual",
                    },
                ],
            },
            {
                label: "Name",
                field: "profile.name",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Country",
                field: "profile.address.country",
                grid: "span 1",
                type: "select",
                options: countries.map(function (item) { return ({
                    key: item.codeAlpha3,
                    label: "".concat(item.flag, " ").concat(item.name),
                }); }),
            },
            {
                label: "City",
                field: "profile.address.town",
                grid: "span 1",
                type: "input",
            },
            // {
            //     label: "Registered State/Province",
            //     field: "state",
            //     grid: "span 1",
            //     type: "input",
            // },
            {
                label: "Address",
                field: "profile.address.street",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Zip Code/Postal Code",
                field: "profile.address.postCode",
                grid: "span 1",
                type: "input",
            },
        ],
        bank: [
            {
                label: "Account number",
                field: "accountNumber",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "IBAN",
                field: "iban",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "SWIFT (BIC) code",
                field: "swiftBic",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Institution name",
                field: "institutionName",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "City",
                field: "institutionAddress.town",
                grid: "1/-1",
                type: "input",
            },
            // {
            //     label: "Registered State/Province",
            //     field: "state",
            //     grid: "span 1",
            //     type: "select",
            //     options: [] as any,
            // },
            {
                label: "Address",
                field: "institutionAddress.street",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Zip Code/Postal Code",
                field: "institutionAddress.postCode",
                grid: "span 1",
                type: "input",
            },
        ],
    },
    {
        type: CounterpartyRailType.SWIFT,
        typeName: "SWIFT",
        recepient: [
            {
                label: "Recipient type",
                field: "profile.profileType",
                grid: "1/-1",
                type: "select",
                options: [
                    {
                        key: CounterpartyProfileType.CORPORATION,
                        label: "Corporation",
                    },
                    {
                        key: CounterpartyProfileType.INDIVIDUAL,
                        label: "Individual",
                    },
                ],
            },
            {
                label: "Name",
                field: "profile.name",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Country",
                field: "profile.address.country",
                grid: "span 1",
                type: "select",
                options: countries.map(function (item) { return ({
                    key: item.codeAlpha3,
                    label: "".concat(item.flag, " ").concat(item.name),
                }); }),
            },
            {
                label: "City",
                field: "profile.address.town",
                grid: "span 1",
                type: "input",
            },
            // {
            //     label: "Registered State/Province",
            //     field: "state",
            //     grid: "span 1",
            //     type: "input",
            // },
            {
                label: "Address",
                field: "profile.address.street",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Zip Code/Postal Code",
                field: "profile.address.postCode",
                grid: "span 1",
                type: "input",
            },
        ],
        bank: [
            {
                label: "Account number",
                field: "accountNumber",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "IBAN",
                field: "iban",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "SWIFT (BIC) code",
                field: "swiftBic",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Institution name",
                field: "institutionName",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "City",
                field: "institutionAddress.town",
                grid: "1/-1",
                type: "input",
            },
            // {
            //     label: "Registered State/Province",
            //     field: "state",
            //     grid: "span 1",
            //     type: "select",
            //     options: [] as any,
            // },
            {
                label: "Address",
                field: "institutionAddress.street",
                grid: "1/-1",
                type: "input",
            },
            {
                label: "Zip Code/Postal Code",
                field: "institutionAddress.postCode",
                grid: "span 1",
                type: "input",
            },
        ],
    },
];
export var FORM_CONFIG = {
    USA: US_CONFIG,
    AUT: EU_CONFIG,
    BEL: EU_CONFIG,
    BGR: EU_CONFIG,
    HRV: EU_CONFIG,
    CYP: EU_CONFIG,
    CZE: EU_CONFIG,
    DNK: EU_CONFIG,
    EST: EU_CONFIG,
    FIN: EU_CONFIG,
    FRA: EU_CONFIG,
    DEU: EU_CONFIG,
    GRC: EU_CONFIG,
    HUN: EU_CONFIG,
    IRL: EU_CONFIG,
    ITA: EU_CONFIG,
    LVA: EU_CONFIG,
    LTU: EU_CONFIG,
    LUX: EU_CONFIG,
    MLT: EU_CONFIG,
    NLD: EU_CONFIG,
    POL: EU_CONFIG,
    PRT: EU_CONFIG,
    ROU: EU_CONFIG,
    SVK: EU_CONFIG,
    SVN: EU_CONFIG,
    ESP: EU_CONFIG,
    SWE: EU_CONFIG,
    CHE: EU_CONFIG,
    GBR: EU_CONFIG,
    SMR: EU_CONFIG,
    VAT: EU_CONFIG,
    AND: EU_CONFIG,
    MCO: EU_CONFIG,
    ISL: EU_CONFIG,
    NOR: EU_CONFIG,
    LIE: EU_CONFIG,
};
