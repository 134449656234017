import * as React from "react";
import { Exchange } from "@/pages/ExchangePage/components/Exchange";
import { Box, Typography } from "@/shared/components";
export var ExchangePage = function () {
    var component = React.useCallback(function () { return React.createElement(Exchange, null); }, []);
    return (React.createElement(Box, { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "24px", mt: "32px", mb: "32px" },
        React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
            React.createElement(Typography, { variant: "h2", type: "title26Neo" }, "Trade")),
        component()));
};
