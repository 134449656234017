import { styled } from "@mui/material";
import { Input } from "@mui/base";
export var StyledInput = styled(Input)(function (_a) {
    var theme = _a.theme;
    return ({
        padding: "0",
        fontSize: theme.text.text18.fontSize,
        fontWeight: theme.text.text18.fontWeight,
        lineHeight: theme.text.text18.lineHeight,
        color: theme.colors["black-1200"],
        height: "auto",
        input: {
            background: "transparent",
            border: "none",
            "&:focus": {
                outline: "none",
            },
        },
    });
});
