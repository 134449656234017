import * as React from "react";
import { observer } from "mobx-react-lite";
import { action } from "mobx";
import { Popup } from "@/shared/components/Popup";
import { PopupHead } from "@/shared/components/Popup/Head";
import { useStoreContext } from "@/shared/hooks/useStore";
import { NameFormStore } from "@/pages/BankAccountsPage/stores/NameFormStore";
import { Button, FormControlInput } from "@/shared/components";
import { PopupTitle } from "@/shared/components/Popup/Title";
import { PopupBody } from "@/shared/components/Popup/Body";
import { PopupContent } from "@/shared/components/Popup/Content";
import { preventDefault } from "@/shared/utils/prevent";
export var NameForm = observer(function () {
    var _a;
    var form = useStoreContext(NameFormStore);
    return (React.createElement(Popup, { width: "450px", onClose: form.reset },
        React.createElement(PopupHead, { onClose: form.reset }, "\u200b"),
        React.createElement("form", { onSubmit: preventDefault(form.submit) },
            React.createElement(PopupBody, null,
                React.createElement(PopupTitle, null, "Edit account name"),
                React.createElement(PopupContent, null,
                    React.createElement(FormControlInput, { value: form.name, onChange: action(function (e) {
                            form.name = e.currentTarget.value;
                        }), 
                        // disabled={form.loading}
                        disabled: true, label: "Name", error: (_a = form.errors.at(1)) === null || _a === void 0 ? void 0 : _a.description, errorPosition: "relative" })),
                React.createElement(Button, { type: "submit", disabled: form.loading || !form.valid }, "Save")))));
});
