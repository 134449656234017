import React from "react";
import VerificationFrame from "../Frame";
import { VerificationStatusComponent } from "./Status";
import { Box, Typography } from "@/shared/components";
import { useMedia } from "@/shared/hooks/useMedia";
import { VerificationStatus } from "@/api/registration/types";
var UserVerificationBlocks = function (_a) {
    var status = _a.status;
    var desktop = useMedia().desktop;
    // const navigate = useNavigate();
    return (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "start", gap: "24px", width: desktop ? "550px" : "100%" },
        React.createElement(Box, { display: "flex", alignItems: "center", gap: "12px" },
            React.createElement(Typography, { type: "title26Neo" }, "Verification"),
            status && React.createElement(VerificationStatusComponent, { status: status })),
        status === VerificationStatus.GREEN ? (React.createElement(VerificationFrame, { status: status })) : status === VerificationStatus.PENDING ? (React.createElement(VerificationFrame, { status: status, desc: "Your application is under review, which usually takes 15 minutes." })) : status === VerificationStatus.INIT ? (React.createElement(VerificationFrame, { status: status, desc: "Complete KYC to gain full access to the system and the ability to withdraw funds." })) : ("")));
};
export default UserVerificationBlocks;
