var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import { FormLayout } from "@/pages/KYBPage/components/FormLayout";
import { FormRow } from "@/pages/KYBPage/components/FormRow";
import { ROUTES } from "@/routes";
import { FormControlInput } from "@/shared/components";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { KYBPrimarySourceOfFunds, KYBValues1, KYBValues2, KYBValues3 } from "@/shared/constants/dict";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CompanyInfoStore } from "@/shared/stores/CompanyInfoStore";
import { RequiredFieldMessage } from "@/shared/utils/error";
export var Form4 = observer(function () {
    var _a;
    var navigate = useNavigate();
    var companyInfo = useStoreContext(CompanyInfoStore);
    var form = useForm();
    var submit = form.handleSubmit(function (formData) {
        companyInfo.update(formData).then(function (success) {
            if (success)
                navigate(generatePath(ROUTES.kybFormId, { id: 5 }));
        });
    });
    React.useEffect(function () {
        form.reset(companyInfo.companyInfo);
    }, [form, companyInfo.companyInfo]);
    return (React.createElement(FormLayout, { onSubmit: submit, onBack: function () { return navigate(generatePath(ROUTES.kybFormId, { id: 3 })); }, index: 4, error: (_a = companyInfo.errors[0]) === null || _a === void 0 ? void 0 : _a.description, title: "Expected Account Activity", desc: "Fill in the fields below with your investment information. Please, use Latin letters only.", loading: !companyInfo.ready || companyInfo.loading },
        React.createElement(Controller, { control: form.control, name: "meta.primarySourceOfFunds", rules: {
                required: {
                    value: true,
                    message: RequiredFieldMessage.description,
                },
            }, render: function (_a) {
                var field = _a.field, error = _a.fieldState.error;
                return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, value: field.value, options: KYBPrimarySourceOfFunds, label: "Primary source of funds", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }));
            } }),
        React.createElement(Controller, { control: form.control, name: "meta.primarySourceOfFundsDesc", render: function (_a) {
                var field = _a.field, error = _a.fieldState.error;
                return (React.createElement(FormControlInput, __assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Primary source of funds description", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
            } }),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.totalAssets", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, value: field.value, options: KYBValues1, label: "Total assets", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }));
                } }),
            React.createElement(Controller, { control: form.control, name: "meta.usdValueOfCrypto", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, value: field.value, options: KYBValues1, label: "USD value of crypto", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }));
                } })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.usdValueOfFiat", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, value: field.value, options: KYBValues1, label: "USD value of fiat", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }));
                } }),
            React.createElement(Controller, { control: form.control, name: "meta.monthlyCryptoInvestmentDeposit", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, value: field.value, options: KYBValues2, label: "Monthly crypto investment deposit", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }));
                } })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.monthlyInvestmentDeposit", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, value: field.value, options: KYBValues2, label: "Monthly investment deposit", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }));
                } }),
            React.createElement(Controller, { control: form.control, name: "meta.monthlyCryptoDeposits", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, value: field.value, options: KYBValues3, label: "Monthly crypto deposits", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }));
                } })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.monthlyDeposit", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, value: field.value, options: KYBValues3, label: "Monthly deposit", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }));
                } }),
            React.createElement(Controller, { control: form.control, name: "meta.monthlyCryptoInvestmentWithdrawal", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, value: field.value, options: KYBValues2, label: "Monthly crypto investment withdrawals", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }));
                } })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.monthlyInvestmentWithdrawal", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, value: field.value, options: KYBValues2, label: "Monthly investment withdrawals", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }));
                } }),
            React.createElement(Controller, { control: form.control, name: "meta.monthlyCryptoWithdrawals", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, value: field.value, options: KYBValues3, label: "Monthly crypto withdrawals", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }));
                } })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.monthlyWithdrawals", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, value: field.value, options: KYBValues3, label: "Monthly withdrawals", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }));
                } }))));
});
