import { useQuery } from "@tanstack/react-query";
import { walletApi } from "./api";
export var useGetRecipients = function () {
    return useQuery({
        queryKey: ["walletApi.counterparty.getCounterparties"],
        queryFn: function () { return walletApi.counterparty.getCounterparties(); },
        select: function (data) { return data.data; },
        retry: 3,
    });
};
