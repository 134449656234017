import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { PhonePageStore } from "@/pages/PhonePage/stores/PhonePageStore";
import { FormControlInput } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { Button } from "@/shared/components/Button";
import { useStoreContext } from "@/shared/hooks/useStore";
import { onChange } from "@/shared/utils/input";
export var PhoneForm = observer(function () {
    var _a;
    var page = useStoreContext(PhonePageStore);
    return (React.createElement(AuthForm, { onSubmit: page.submitPhone, title: "Enter your phone number", desc: "We will send you a SMS code. Verify your phone number to even better protect your account.", content: React.createElement(FormControlInput, { label: "Phone number", value: page.phone, onChange: onChange(action(function (value) {
                page.phone = value;
            })), variantError: "alert", error: (_a = page.errors[0]) === null || _a === void 0 ? void 0 : _a.description, disabled: page.loading }), footer: React.createElement(Button, { type: "submit", width: "100%", disabled: page.loading || !page.isPhoneValid }, "Continue") }));
});
