import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath } from "react-router-dom";
import styles from "./index.module.scss";
import { AuthPageStore } from "@/pages/AuthPage/stores/AuthPageStore";
import { SigninPageStore } from "@/pages/SigninPage/stores/SigninPageStore";
import { ROUTES } from "@/routes";
import { FormControlInput } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { Button } from "@/shared/components/Button";
import { Link } from "@/shared/components/Link";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { onChange } from "@/shared/utils/input";
export var SigninForm = observer(function () {
    var _a, _b, _c;
    var page = useStoreContext(SigninPageStore);
    var auth = useStoreContext(AuthPageStore);
    var media = useMedia();
    return (React.createElement(AuthForm, { onBack: media.tablet ? auth.toggleForm : undefined, onSubmit: page.submit, title: "Log in to your account", content: React.createElement(React.Fragment, null,
            React.createElement(FormControlInput, { type: "text", hiddenLabel: true, outLabel: "Email", placeholder: "Enter email", error: (_a = page.errorsByKey.username) === null || _a === void 0 ? void 0 : _a[0], variantError: "alert", disabled: page.loading, value: page.email, onChange: onChange(action(function (value) {
                    page.email = value;
                })) }),
            React.createElement(FormControlInput, { type: "password", outLabel: "Password", placeholder: "Enter password", error: (_b = page.errorsByKey.password) === null || _b === void 0 ? void 0 : _b[0], variantError: "alert", disabled: page.loading, value: page.password, onChange: onChange(action(function (value) {
                    page.password = value;
                })) }),
            React.createElement(Link, { type: "text12Bold", to: generatePath(ROUTES.passwordReset) }, "Forgot password?")), footer: React.createElement(React.Fragment, null,
            React.createElement(Button, { type: "submit", width: "100%", error: (_c = page.errorsByKey.unknown) === null || _c === void 0 ? void 0 : _c[0], 
                // disabled={!page.isEmailValid || !page.isPasswordValid || page.loading}
                disabled: !page.isPasswordValid || page.loading }, "Log in"),
            React.createElement("div", { className: styles.row },
                "Don't have an account ?",
                React.createElement(Link, { type: "text12Bold", to: generatePath(ROUTES.root) }, "Sign up"))) }));
});
