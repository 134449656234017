var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useDropzone } from "react-dropzone";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { Loader } from "@/shared/components/Loader";
import { Typography } from "@/shared/components/Typography";
import { humanFileSize } from "@/shared/utils/file-size";
import { stopPropagation } from "@/shared/utils/stop-propagation";
export var Upload = function (_a) {
    var disabled = _a.disabled, maxFileSize = _a.maxFileSize, minFileSize = _a.minFileSize, maxFiles = _a.maxFiles, multiple = _a.multiple, label = _a.label, fileName = _a.fileName, loading = _a.loading, error = _a.error, size = _a.size, success = _a.success, onFiles = _a.onFiles, onRemove = _a.onRemove, onDownload = _a.onDownload;
    var _b = React.useState([]), localErrors = _b[0], setLocalErrors = _b[1];
    var errors = error ? __spreadArray(__spreadArray([], localErrors, true), [error], false) : localErrors;
    var _c = useDropzone({
        disabled: disabled || loading,
        maxSize: maxFileSize,
        minSize: minFileSize,
        maxFiles: maxFiles,
        multiple: multiple,
        onDropAccepted: function (e) {
            setLocalErrors([]);
            onFiles === null || onFiles === void 0 ? void 0 : onFiles(e);
        },
        onDropRejected: function (e) {
            setLocalErrors(e.flatMap(function (item) { return item.errors.map(function (err) { return "".concat(item.file.name, " \u2014 ").concat(err.message); }); }));
        },
    }), getRootProps = _c.getRootProps, getInputProps = _c.getInputProps;
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px" },
        React.createElement(Box, { bgColor: errors.length
                ? "general-red-transparent-10"
                : success
                    ? "general-green-transparent-5"
                    : "deep-blue-500-transparent-2", borderRadius: "16px", borderColor: errors.length
                ? "general-red"
                : success
                    ? "general-green"
                    : "deep-blue-500", borderWidth: "1px", borderStyle: "dashed" },
            React.createElement(Inner, __assign({ onClick: onDownload }, (onDownload ? undefined : getRootProps()), { disabled: !!(disabled || loading) }),
                React.createElement("input", __assign({ type: "file" }, getInputProps(), { disabled: disabled })),
                React.createElement(Box, { padding: "28px 30px", display: "flex", alignItems: "center", justifyContent: "space-between" },
                    React.createElement(Typography, { type: "text16", color: errors.length
                            ? "general-red"
                            : success
                                ? "general-green"
                                : "black-500" }, fileName
                        ? (React.createElement(Box, { display: "flex", alignItems: "center", gap: "10px" },
                            React.createElement(BaseIcon, { icon: "Doc", size: 24, color: errors.length > 0
                                    ? "general-red"
                                    : success
                                        ? "general-green"
                                        : "deep-blue-500" }),
                            fileName))
                        : label),
                    loading
                        ? React.createElement(Loader, { size: 24, color: (errors === null || errors === void 0 ? void 0 : errors.length) > 0 ? "general-red" : "deep-blue-500" })
                        : (React.createElement(Box, { display: "flex", alignItems: "center", gap: "10px" },
                            fileName && onRemove && (React.createElement(BaseIcon, { icon: "Cross", color: "general-white", containerSize: 24, size: 10, bgColor: "black-300", borderRadius: "100%", onClick: stopPropagation(onRemove) })),
                            size
                                ? (React.createElement(Typography, { type: "text14", color: success ? "general-green" : "black-500", fontWeight: 500, borderRadius: "24px", height: "24px", padding: "0 10px", bgColor: "general-white" }, humanFileSize(size)))
                                : (React.createElement(Typography, { type: "text14", color: "deep-blue-500" },
                                    "Drag & drop files or",
                                    " ",
                                    React.createElement(Typography, { type: "text14", color: "deep-blue-500", textDecoration: "underline" }, "Browse")))))))),
        errors.length > 0 && (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" }, errors.map(function (item) { return (React.createElement(Typography, { type: "text14", fontWeight: 450, color: "general-red", key: item }, item)); })))));
};
var Inner = styled("div")(function (_a) {
    var disabled = _a.disabled;
    return ({
        cursor: disabled ? "not-allowed" : "pointer",
    });
});
