var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { WalletFormStore } from "../../store/CreateWalletStore";
import { Button } from "@/shared/components";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { Popup } from "@/shared/components/Popup";
import { PopupHead } from "@/shared/components/Popup/Head";
import { TokenSearch } from "@/shared/components/TokenSearch";
import { useProvider, useStore, useStoreContext, } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { EventsStore } from "@/shared/stores/EventsStore";
import { preventDefault } from "@/shared/utils/prevent";
import { PopupBody } from "@/shared/components/Popup/Body";
import { PopupTitle } from "@/shared/components/Popup/Title";
import { PopupContent } from "@/shared/components/Popup/Content";
// TODO: Show errors
export var CreateWalletPopup = observer(function (_a) {
    var _b, _c;
    var onClose = _a.onClose, onSuccess = _a.onSuccess;
    var events = useStoreContext(EventsStore);
    var form = useStore(WalletFormStore, events);
    var WalletFormProvider = useProvider(WalletFormStore);
    var currencyList = useStoreContext(CurrencyListStore);
    var list = currencyList.list.filter(function (item) { var _a; return item.shortName && ((_a = item.type) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "crypto"; });
    var networks = (_c = (_b = currencyList.list.find(function (item) { var _a; return ((_a = item.shortName) === null || _a === void 0 ? void 0 : _a.toString()) === form.tokenId; })) === null || _b === void 0 ? void 0 : _b.tokens) !== null && _c !== void 0 ? _c : [];
    var onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var id;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, form.submit()];
                case 1:
                    id = _a.sent();
                    if (id)
                        onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(id);
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(WalletFormProvider, { value: form },
        React.createElement(Popup, { width: "450px", onClose: onClose },
            React.createElement(PopupHead, { onClose: onClose }, "\u200b"),
            React.createElement("form", { onSubmit: preventDefault(onSubmit) },
                React.createElement(PopupBody, null,
                    React.createElement(PopupTitle, null, "Create crypto wallet"),
                    React.createElement(PopupContent, null,
                        React.createElement(TokenSearch, { label: "Coin", placeholder: "Select coin", disabled: form.loading || !!form.walletId, value: form.tokenId, onChange: action(function (key) {
                                form.tokenId = key;
                            }), tokens: list.map(function (item) { return ({
                                id: item.shortName,
                                name: item.description,
                                symbol: item.shortName,
                                iconUrl: item.iconUrl,
                            }); }) }),
                        React.createElement(FormControlSelect, { label: "Network", placeholder: "Select network", disabled: !form.tokenId ||
                                form.loading ||
                                !!form.walletId, value: form.networkId, onChange: function (_, o) {
                                runInAction(function () {
                                    form.networkId = o === null || o === void 0 ? void 0 : o.key;
                                });
                            }, options: networks.map(function (item) {
                                var _a, _b, _c, _d;
                                return ({
                                    key: (_b = (_a = item.blockchain) === null || _a === void 0 ? void 0 : _a.slug) === null || _b === void 0 ? void 0 : _b.toString(),
                                    label: (_d = (_c = item.blockchain) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : "Unknown",
                                });
                            }) })),
                    React.createElement(Button, { type: "submit", disabled: form.loading ||
                            !form.valid ||
                            !!form.walletId }, "Create"))))));
});
