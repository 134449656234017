import { observer } from "mobx-react-lite";
import * as React from "react";
import { Box, Typography } from "@/shared/components";
import { useStoreContext } from "@/shared/hooks/useStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { Transactions } from "@/widgets/Transactions";
export var TransactionPage = observer(function () {
    var accounts = useStoreContext(AccountListStore);
    var ids = accounts.list.map(function (account) { return account.account; });
    return (React.createElement(Box, { flexDirection: "column", gap: "24px", mt: "32px", mb: "32px", display: "flex" },
        React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
            React.createElement(Typography, { variant: "h2", type: "title26Neo" }, "Transactions activity")),
        React.createElement(Transactions, { accountIds: ids, tableProps: { checkboxSelection: false } })));
});
