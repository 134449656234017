var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import BigNumber from "bignumber.js";
import { makeAutoObservable, runInAction } from "mobx";
import { walletApi } from "@/api/wallet/api";
import { WithdrawType } from "@/api/wallet/types";
import { parseApiError } from "@/shared/utils/error";
import { Reactions } from "@/shared/utils/reactions";
var TransferCryptoFormStore = /** @class */ (function () {
    function TransferCryptoFormStore(balanceList, accountList, auth, curr) {
        this.balanceList = balanceList;
        this.accountList = accountList;
        this.auth = auth;
        this.curr = curr;
        this.loading = false;
        this.from = undefined;
        this.to = undefined;
        this.blockchain = undefined;
        this.currency = undefined;
        this.amount = undefined;
        this.errors = [];
        this.withdrawData = undefined;
        this.reactions = new Reactions();
        makeAutoObservable(this, {}, { autoBind: true });
        this.currency = curr;
    }
    TransferCryptoFormStore.prototype.submit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var errors, resp, e_1;
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        errors = [];
                        runInAction(function () {
                            _this.loading = true;
                        });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        if (!this.amount) {
                            throw new Error("Amount must be defined");
                        }
                        // if (!this.acc) {
                        //     throw new Error("Account must be defined");
                        // }
                        if (!this.currency) {
                            throw new Error("Currency must be defined");
                        }
                        if (!this.to) {
                            throw new Error("Address must be defined");
                        }
                        console.log(this.acc, "___thisacc");
                        return [4 /*yield*/, walletApi.withdraw.sendWithdrawRequest({
                                amount: this.amount,
                                currency: this.currency,
                                type: WithdrawType.CRYPTO,
                                to: this.to,
                                blockchain: this.blockchain,
                                fromAccount: this.acc ? (_a = this.acc) === null || _a === void 0 ? void 0 : _a.account : "",
                            }, {
                                autoConversion: true,
                            })];
                    case 2:
                        resp = _b.sent();
                        this.withdrawData = resp.data;
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        errors = parseApiError(e_1);
                        throw new Error("Withdraw error");
                    case 4:
                        runInAction(function () {
                            _this.loading = false;
                            _this.errors = errors;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    TransferCryptoFormStore.prototype.getFees = function (transferFeePercent) {
        return this.amount && transferFeePercent
            ? new BigNumber(this.amount)
                .times(transferFeePercent)
                .dividedBy(100)
                .toFixed()
            : "0";
    };
    TransferCryptoFormStore.prototype.reset = function () {
        this.amount = undefined;
    };
    TransferCryptoFormStore.prototype.paste = function () {
        return __awaiter(this, void 0, void 0, function () {
            var text;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, navigator.clipboard.readText()];
                    case 1:
                        text = _a.sent();
                        runInAction(function () {
                            _this.to = text;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(TransferCryptoFormStore.prototype, "acc", {
        get: function () {
            return this.accountList ? this.accountList.crypto[0] : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferCryptoFormStore.prototype, "balance", {
        get: function () {
            return this.from ? this.balanceList.data[this.from] : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferCryptoFormStore.prototype, "enough", {
        get: function () {
            return this.amount && this.balance
                ? new BigNumber(this.amount).lte(this.balance.balance)
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferCryptoFormStore.prototype, "valid", {
        get: function () {
            return (this.to &&
                this.amount &&
                this.enough &&
                new BigNumber(this.amount).gt(0));
        },
        enumerable: false,
        configurable: true
    });
    return TransferCryptoFormStore;
}());
export { TransferCryptoFormStore };
