import { makeAutoObservable, reaction } from "mobx";
import { Reactions } from "@/shared/utils/reactions";
var NameFormStore = /** @class */ (function () {
    function NameFormStore(accountList, accountId) {
        this.accountList = accountList;
        this.accountId = accountId;
        this.visible = false;
        this.loading = false;
        this.errors = [];
        this.name = undefined;
        this.reactions = new Reactions();
        makeAutoObservable(this, {}, { autoBind: true });
    }
    NameFormStore.prototype.init = function () {
        var _this = this;
        this.reactions.create(reaction(function () { return _this.account; }, function () {
            var _a, _b;
            _this.name = (_b = (_a = _this.account) === null || _a === void 0 ? void 0 : _a.shortName) !== null && _b !== void 0 ? _b : '';
        }, {
            fireImmediately: true
        }));
    };
    NameFormStore.prototype.submit = function () {
        // TODO: Api call
        this.reset();
    };
    NameFormStore.prototype.reset = function () {
        var _a;
        this.visible = false;
        this.loading = false;
        this.errors = [];
        this.name = (_a = this.account) === null || _a === void 0 ? void 0 : _a.shortName;
    };
    Object.defineProperty(NameFormStore.prototype, "account", {
        get: function () {
            return this.accountId ? this.accountList.fiatById[this.accountId] : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NameFormStore.prototype, "valid", {
        get: function () {
            return this.name ? this.name.length > 0 : undefined;
        },
        enumerable: false,
        configurable: true
    });
    return NameFormStore;
}());
export { NameFormStore };
