import React from "react";
import { CommonInfo } from "./CommonInfo";
import { Modal } from "@/shared/components";
export var TransactionInfo = function (_a) {
    var transaction = _a.transaction, onClose = _a.onClose;
    console.log("🚀 ~ transaction:", transaction);
    var children = null;
    if (transaction === null || transaction === void 0 ? void 0 : transaction.reasonType.match("UNKNOWN"))
        return null;
    children = React.createElement(CommonInfo, { transaction: transaction });
    // if (transaction?.reasonType.match("WITHDRAWAL")) {
    //     children = <WithdrawInfo transaction={transaction} />;
    // } else if (transaction?.reasonType.match("DEPOSIT")) {
    //     children = <DepositInfo transaction={transaction} />;
    // } else if (
    //     transaction?.reasonType === BalanceLogReasonType.DIRECT_EXCHANGE ||
    //     transaction?.reasonType === BalanceLogReasonType.DIRECT_EXCHANGE_FEE
    // ) {
    //     children = <ExchangeInfo transaction={transaction} />;
    // } else {
    //     children = <TransferInfo transaction={transaction} />;
    // }
    return (React.createElement(Modal, { open: !!transaction, bgColor: "black-50", onClose: onClose, disablePadding: true }, children));
};
