import { observer } from "mobx-react-lite";
import * as React from "react";
import styles from "./index.module.scss";
import { EmailCodeForm } from "@/pages/SignupPage/components/EmailCodeForm";
import { SignupForm } from "@/pages/SignupPage/components/SignupForm";
import { SignupPageStore, SignupStep, } from "@/pages/SignupPage/stores/SignupPageStore";
import { useProvider, useStore, useStoreContext, } from "@/shared/hooks/useStore";
import { AuthStore } from "@/shared/stores/AuthStore";
export var SignupPage = observer(function () {
    var auth = useStoreContext(AuthStore);
    var page = useStore(SignupPageStore, auth);
    var PageProvider = useProvider(SignupPageStore);
    return (React.createElement(PageProvider, { value: page },
        React.createElement("h1", { className: styles.formTitle },
            React.createElement("svg", { width: "200", height: "40", viewBox: "0 0 116 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("path", { d: "M111.393 13.3826C108.242 13.3826 107.23 11.2602 107.23 8.95812C107.23 5.88873 109.107 4.50098 111.393 4.50098C113.972 4.50098 115.05 6.06832 115.05 8.43567C115.05 8.71322 115.034 9.252 115.001 9.54587H109.825C109.874 10.95 110.462 11.4724 111.54 11.4724C112.421 11.4724 112.764 10.9826 112.928 10.2642L115.001 10.7704C114.691 12.354 113.417 13.3826 111.393 13.3826ZM109.858 7.97853H112.503C112.503 6.75404 112.062 6.28057 111.262 6.28057C110.56 6.28057 109.874 6.73771 109.858 7.97853Z", fill: "#2F8A97" }),
                React.createElement("path", { d: "M102.904 4.50099C103.835 4.50099 104.602 4.64793 105.157 5.02344C105.451 4.3867 105.925 3.73364 106.725 3.73364C106.937 3.73364 107.166 3.78262 107.41 3.88058V5.49691C106.904 5.44793 106.48 5.48058 106.006 5.62752C106.496 6.18262 106.708 6.85201 106.708 7.53772C106.708 9.44793 105.157 10.5908 102.97 10.5908C102.398 10.5908 102.023 10.5418 101.549 10.4112C101.402 10.5581 101.239 10.7214 101.239 10.9336C101.239 11.1785 101.386 11.3418 102.006 11.3745L104.423 11.5214C106.235 11.6357 107.28 12.2561 107.28 13.9704C107.28 16.0602 105.337 16.8602 102.839 16.8602C99.7206 16.8602 98.741 16.0602 98.741 14.8847C98.741 14.0847 99.3451 13.5296 100.276 13.35V13.2847C99.4431 13.0398 99.0349 12.55 99.0349 11.8479C99.0349 10.999 99.5574 10.5255 100.357 9.95405C99.59 9.51324 99.1165 8.72956 99.1165 7.65201C99.1165 5.83977 100.472 4.50099 102.904 4.50099ZM104.455 7.5214C104.455 6.63977 103.9 6.2316 103.002 6.2316C102.121 6.2316 101.451 6.60711 101.451 7.5214C101.451 8.37038 101.925 8.82752 102.97 8.82752C103.851 8.82752 104.455 8.35405 104.455 7.5214ZM104.88 14.4112C104.88 14.0847 104.717 13.7908 103.835 13.7418L101.468 13.5949C101.157 13.8234 100.961 14.101 100.961 14.4112C100.961 15.0806 101.892 15.2765 103.035 15.2765C104.129 15.2765 104.88 15.1132 104.88 14.4112Z", fill: "#2F8A97" }),
                React.createElement("path", { d: "M98.2185 7.37445V13.1867H95.7531V7.79894C95.7531 6.88465 95.394 6.49281 94.6266 6.49281C93.9572 6.49281 93.2878 6.86832 92.9613 7.1622V13.1867H90.496V4.68057H92.6838L92.8144 5.52955C93.4348 4.95812 94.3654 4.50098 95.5246 4.50098C97.4021 4.50098 98.2185 5.43159 98.2185 7.37445Z", fill: "#2F8A97" }),
                React.createElement("path", { d: "M88.9676 7.50506V11.0642C88.9676 11.5214 89.0492 11.7173 89.4737 11.7173C89.6206 11.7173 89.7676 11.6846 89.9798 11.6193L90.0451 13.0398C89.7186 13.203 89.0002 13.3663 88.4778 13.3663C87.4982 13.3663 86.9104 13.0887 86.7308 12.2887C86.1921 12.9908 85.3757 13.3826 84.2002 13.3826C82.0778 13.3826 81.6696 11.9622 81.6696 11.1132C81.6696 8.5173 84.3635 8.12547 86.5512 8.14179V7.83159C86.5512 6.86832 86.4206 6.28057 85.3431 6.28057C84.5267 6.28057 84.2165 6.62343 84.0533 7.45608L81.9635 7.04791C82.2247 5.44791 83.2369 4.50098 85.5553 4.50098C87.8247 4.50098 88.9676 5.38261 88.9676 7.50506ZM86.5676 11.0806V9.57853H86.1757C85.0002 9.61118 84.1512 9.83975 84.1512 10.7051C84.1512 11.1949 84.3635 11.6357 85.1472 11.6357C85.7349 11.6357 86.3063 11.3418 86.5676 11.0806Z", fill: "#2F8A97" }),
                React.createElement("path", { d: "M80.9021 7.37439V13.1866H78.4368V7.81521C78.4368 6.90092 78.0776 6.50909 77.3103 6.50909C76.6409 6.50909 75.9715 6.8846 75.645 7.17847V13.1866H73.1797V0.860107H75.6287V5.46419C76.2327 4.99072 77.0491 4.50092 78.2572 4.50092C80.0858 4.50092 80.9021 5.43154 80.9021 7.37439Z", fill: "#2F8A97" }),
                React.createElement("path", { d: "M68.6745 13.3826C65.8336 13.3826 64.5765 11.5867 64.5765 9.0724C64.5765 6.14996 66.1602 4.50098 68.9193 4.50098C70.9602 4.50098 72.2989 5.64383 72.4622 7.7173L70.2581 8.04383C70.1602 6.98261 69.8336 6.39485 68.8704 6.39485C67.9398 6.39485 67.1561 6.83567 67.1561 8.81118C67.1561 10.7867 67.7928 11.4561 68.8377 11.4561C69.7847 11.4561 70.2744 10.9826 70.4214 9.90506L72.4949 10.2969C72.201 12.4357 70.6663 13.3826 68.6745 13.3826Z", fill: "#2F8A97" }),
                React.createElement("path", { d: "M64.8894 4.68042L62.1139 8.81103L64.971 13.1865H62.3424L60.6118 10.558L58.9302 13.1865H56.5628L59.3384 8.92532L56.5139 4.68042H59.1751L60.8241 7.27634L62.522 4.68042H64.8894Z", fill: "#2F8A97" }),
                React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M51.2566 11.0642H56.5301V13.1866H48.7423V11.0642H51.2566ZM55.9423 8.30499H51.2566H48.7423V6.24784H51.2566H55.9423V8.30499ZM51.2566 3.47233H56.2362V1.36621H48.7423V3.47233H51.2566Z", fill: "#2F8A97" }),
                React.createElement("path", { d: "M51.2566 3.47233H48.7423V4.44025H51.2566V3.47233Z", fill: "#2F8A97" }),
                React.createElement("path", { d: "M51.2566 8.30499H48.7423V9.10497H51.2566V8.30499Z", fill: "#2F8A97" }),
                React.createElement("path", { d: "M43.307 13.3826C40.2213 13.3826 39.16 11.5214 39.16 8.97444C39.16 6.24791 40.5805 4.50098 43.5356 4.50098C46.4907 4.50098 47.6335 6.28057 47.6335 8.79485C47.6335 11.5704 46.36 13.3826 43.307 13.3826ZM43.4213 11.554C44.4988 11.554 45.0702 10.8683 45.0702 8.87649C45.0702 7.01526 44.5641 6.3622 43.4703 6.3622C42.36 6.3622 41.7396 7.06424 41.7396 8.90914C41.7396 10.803 42.2131 11.554 43.4213 11.554Z", fill: "#2F8A97" }),
                React.createElement("path", { d: "M38.9422 11.0314L39.285 12.7784C38.8606 13.0559 37.8646 13.3825 36.7871 13.3825C35.0075 13.3825 34.0769 12.5661 34.0769 10.6233V6.47634H32.8034V4.68042H34.0769V2.16613L36.5422 1.8396V4.68042H38.7953V6.47634H36.5422V10.2804C36.5422 11.0314 36.7544 11.4233 37.5381 11.4233C38.0279 11.4233 38.6157 11.2274 38.9422 11.0314Z", fill: "#2F8A97" }),
                React.createElement("path", { d: "M24.8029 16.6479V4.68057H26.8927L27.0396 5.54587C27.7743 4.81118 28.7539 4.50098 29.7009 4.50098C31.5131 4.50098 32.9009 5.61118 32.9009 8.74587C32.9009 12.1255 31.3172 13.3826 29.4396 13.3826C28.3947 13.3826 27.7254 13.0561 27.1866 12.5663V16.6479H24.8029ZM27.2356 7.11322V10.8357C27.4805 11.0642 28.1172 11.4071 28.7213 11.4071C29.8641 11.4071 30.3213 10.4602 30.3213 8.81118C30.3213 7.09689 29.8151 6.46016 28.8192 6.46016C28.2478 6.46016 27.6111 6.80302 27.2356 7.11322Z", fill: "#2F8A97" }),
                React.createElement("path", { d: "M19.0663 13.3661L15.8336 4.68042H18.4949L20.2745 10.607H20.3234L22.0377 4.68042H24.5683L21.1887 14.0192C20.4704 16.0274 19.7194 16.8274 17.9887 16.8274C17.3194 16.8274 16.4867 16.6478 16.1275 16.4682L16.3071 14.6886C16.7643 14.8355 17.2377 14.9498 17.5969 14.9498C18.1683 14.9498 18.5438 14.6886 18.8051 14.0355L19.0663 13.3661Z", fill: "#2F8A97" }),
                React.createElement("path", { d: "M16.4609 4.5173V6.9173C15.3507 6.81934 14.2569 7.11322 13.5385 7.68465V13.1867H11.0732V4.68057H13.1793L13.3589 6.01934C13.9303 5.18669 14.7303 4.50098 16.0364 4.50098C16.1671 4.50098 16.314 4.50098 16.4609 4.5173Z", fill: "#2F8A97" }),
                React.createElement("path", { d: "M5.51706 13.3824C1.59869 13.3824 0.0639992 11.064 0.0639992 7.40687C0.0639992 3.65177 2.05584 1.15381 5.76196 1.15381C8.40686 1.15381 10.2844 2.42728 10.415 5.39871L8.11298 5.59463C7.96604 3.91299 7.24767 3.22728 5.7293 3.22728C3.80277 3.22728 2.65992 4.46809 2.65992 7.27626C2.65992 10.1824 3.68849 11.3089 5.54971 11.3089C7.24767 11.3089 7.83543 10.4273 8.11298 9.18646L10.3824 9.5783C10.0069 12.4028 7.63951 13.3824 5.51706 13.3824Z", fill: "#2F8A97" }))),
        page.step === SignupStep.EmailCode ? (React.createElement(EmailCodeForm, null)) : page.step === SignupStep.Email ? (React.createElement(SignupForm, null)) : null));
});
