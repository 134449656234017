var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "@emotion/styled";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { VerificationStatus } from "@/api/registration/types";
var VerificationFrame = function (_a) {
    var status = _a.status, desc = _a.desc;
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "24px", mt: "6px", width: "100%" }, status === VerificationStatus.GREEN ? (React.createElement(BoxMedia, null,
        React.createElement(BaseIcon, { icon: "Verified", size: 96 }),
        React.createElement(Typography, { type: "text16Bold", textAlign: "center" }, "Your account is verified, you can now use all the features"))) : (React.createElement(React.Fragment, null,
        desc && React.createElement(Typography, { type: "text14" }, desc),
        React.createElement("iframe", { title: "verification_block_status", width: "548px%", height: "240px%" })))));
};
var BoxMedia = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 12px;\n    margin-top: 24px;\n"], ["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 12px;\n    margin-top: 24px;\n"])));
export default VerificationFrame;
var templateObject_1;
