var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { styled } from "@mui/material/styles";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { PaymentCryptoFormStore } from "@/pages/PaymentPage/stores/PaymentCryptoFormStore";
import { Box, Button, FormControlInput, Typography } from "@/shared/components";
import { Amount } from "@/shared/components/Amount";
import { useStoreContext } from "@/shared/hooks/useStore";
import { onChange } from "@/shared/utils/input";
export var TrxForm = observer(function () {
    var form = useStoreContext(PaymentCryptoFormStore);
    var submit = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            form.submit();
            return [2 /*return*/];
        });
    }); };
    return (React.createElement(Wrapper, null,
        React.createElement(Panel, null,
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "5px" },
                React.createElement(Typography, { type: "text18", fontWeight: 600 }, "Promo code"),
                React.createElement(Typography, { type: "text16", color: "black-500" }, "You can use a promo code and get a discount")),
            React.createElement(Box, { display: "flex", gap: "15px" },
                React.createElement(FormControlInput, { label: "Code", width: "100%" }),
                React.createElement(Button, { variant: "Gray", size: "l" }, "Apply"))),
        React.createElement(Panel, null,
            React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
                React.createElement(Typography, { type: "text18", fontWeight: 600 }, "Total"),
                React.createElement(Typography, { type: "text18", fontWeight: 600 }, form.amount && form.symbol
                    ? (React.createElement(Amount, { amount: form.amount, from: "USDT", to: form.symbol }))
                    : null)),
            React.createElement(FormControlInput, { label: "Transaction ID", value: form.trxHash, onChange: onChange(action(function (val) {
                    form.trxHash = val;
                })), disabled: !form.amount || !form.symbol || form.loading || form.success }),
            React.createElement(Button, { onClick: submit, disabled: !form.amount || !form.symbol || form.loading || !form.trxHash || form.success
                    || !form.verification }, "Payment was sent"),
            React.createElement(Typography, { type: "text12", color: "black-500", textAlign: "center" }, "Blockchain transaction processing time depends on the network load. This may take a few minutes."))));
});
var Wrapper = styled("div")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    max-width: 420px;\n    width: 100%;\n\n    ", " {\n        max-width: unset;\n    }\n"], ["\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    max-width: 420px;\n    width: 100%;\n\n    ", " {\n        max-width: unset;\n    }\n"])), function (props) { return props.theme.breakpoints.down("md"); });
var Panel = styled("form")(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border-radius: 22px;\n    padding: 30px;\n    display: flex;\n    flex-direction: column;\n    gap: 30px;\n    background: ", ";\n\n    ", " {\n        border: 1px solid ", ";\n        border-radius: 12px;\n        padding: 20px;\n        gap: 20px;\n    }\n"], ["\n    border-radius: 22px;\n    padding: 30px;\n    display: flex;\n    flex-direction: column;\n    gap: 30px;\n    background: ", ";\n\n    ", " {\n        border: 1px solid ", ";\n        border-radius: 12px;\n        padding: 20px;\n        gap: 20px;\n    }\n"])), function (props) { return props.theme.colors["general-white"]; }, function (props) { return props.theme.breakpoints.down("md"); }, function (props) { return props.theme.colors["black-200"]; });
var templateObject_1, templateObject_2;
